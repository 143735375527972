import { createUseStyles } from 'react-jss';

import styling from '../../../../services/styling';

export default createUseStyles(
  {
    container: {
      position: 'fixed',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      background: styling.colors.context.background,
      color: styling.colors.context.text,
    },
    button: {
      color: styling.colors.theme.secondaryLight,
      position: 'absolute',
      top: 28,
      right: styling.sizes.gutteringSm,
      zIndex: 10,
      height: 24,
      width: 24,
      cursor: 'pointer',
      [styling.breakpoints.up('md')]: {
        top: 31,
        right: styling.sizes.gutteringLg,
      },
    },
    content: {
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: 0,
      top: 110,
      overflowX: 'hidden',
      overflowY: 'auto',
      display: 'flex',
      flexDirection: 'column',
    },
    formWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      maxWidth: 822,
      margin: [0, 'auto'],
      position: 'relative',
      zIndex: 4,
      width: '100%',
    },
    subtitle: {
      margin: [0, 0, 30],
      [styling.breakpoints.up('md')]: {
        margin: [33, 0, 50],
      },
    },
    form: {
      textAlign: 'left',
      display: 'contents',
      '& > div:first-child': {
        width: '100%',
      },
    },
    disclaimer: {
      marginTop: 38,
      '& a': {
        color: 'inherit',
        textDecoration: 'underline',
        outline: 'none',
        '&:hover,&:focus,&:active': {
          textDecoration: 'none',
        },
      },
    },
    fadeOut: {
      opacity: 0,
      pointerEvents: 'none',
      transition: [['opacity', styling.speeds.skip, 'ease-in-out']],
    },
    loading: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      opacity: 0,
      pointerEvents: 'none',
      transition: [['opacity', styling.speeds.skip, 'ease-in-out']],
      zIndex: 2,
    },
    fadeIn: {
      opacity: 1,
      transition: [
        ['opacity', styling.speeds.skip, 'ease-in-out', styling.speeds.skip],
      ],
    },
  },
  { name: 'sendPostersModal' },
);
