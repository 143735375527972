import PropTypes from 'prop-types';

import styling from '../../../services/styling';
import IconSvg from '../../IconSvg';

const ClosedCaptions = ({ color, height, width, ...rest }) => (
  <IconSvg
    {...rest}
    color={color}
    height={height}
    viewBox="0 0 32 32"
    width={width}
  >
    <g fill="currentColor">
      <path
        d="M11.8,10.4c1.5,0,2.8,1.2,2.8,2.8c0,0.4-0.3,0.7-0.7,0.7s-0.7-0.3-0.7-0.7c0-0.8-0.6-1.4-1.4-1.4c-0.8,0-1.4,0.6-1.4,1.4
	l0,0v5.6c0,0.8,0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4c0-0.4,0.3-0.7,0.7-0.7s0.7,0.3,0.7,0.7c0,1.5-1.2,2.8-2.8,2.8S9,20.3,9,18.8
	l0,0v-5.6C9,11.7,10.3,10.4,11.8,10.4z"
      />
      <path
        d="M20.2,10.4c1.5,0,2.8,1.2,2.8,2.8c0,0.4-0.3,0.7-0.7,0.7c-0.4,0-0.7-0.3-0.7-0.7c0-0.8-0.6-1.4-1.4-1.4
	c-0.8,0-1.4,0.6-1.4,1.4l0,0v5.6c0,0.8,0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4c0-0.4,0.3-0.7,0.7-0.7c0.4,0,0.7,0.3,0.7,0.7
	c0,1.5-1.2,2.8-2.8,2.8s-2.8-1.2-2.8-2.8l0,0v-5.6C17.4,11.7,18.6,10.4,20.2,10.4z"
      />
    </g>
    <g fill="currentColor">
      <path
        d="M26.4,3.5H5.6C3.6,3.5,2.1,5,2.1,6.9v18.1c0,1.9,1.6,3.5,3.5,3.5h20.9c1.9,0,3.5-1.6,3.5-3.5V6.9C29.9,5,28.4,3.5,26.4,3.5z
	 M28.5,25.1c0,1.2-0.9,2.1-2.1,2.1H5.6c-1.2,0-2.1-0.9-2.1-2.1V6.9c0-1.2,0.9-2.1,2.1-2.1h20.9c1.2,0,2.1,0.9,2.1,2.1V25.1z"
      />
    </g>
  </IconSvg>
);

ClosedCaptions.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};

ClosedCaptions.defaultProps = {
  color: styling.colors.theme.secondaryDark,
  height: 32,
  width: 32,
};

export default ClosedCaptions;
