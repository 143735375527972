import { useContext } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import MarkupWithMerging from '../../../../../../../components/MarkupWithMerging';
import Typography from '../../../../../../../components/Typography';
import { MediaQueryContext } from '../../../../../../../contexts/mediaQueryContext';
import useQuestionSubtitle from './hooks/useQuestionSubtitle';
import useStyles from './questionSubtitle.styles';

const QuestionSubtitle = ({ className, subTitle, markupMergeValues }) => {
  const styles = useStyles();
  const { matcherNames } = useQuestionSubtitle(markupMergeValues);
  const { isDesktop } = useContext(MediaQueryContext);

  if (!subTitle) return null;

  return (
    <Typography
      className={clsx(className, styles.wrapper)}
      component="div"
      variant={`questionSubtitle${isDesktop ? '' : 'Mobile'}`}
      withShadow
    >
      <MarkupWithMerging
        content={subTitle}
        matcherNames={matcherNames}
        {...markupMergeValues}
      />
    </Typography>
  );
};

QuestionSubtitle.propTypes = {
  className: PropTypes.string,
  subTitle: PropTypes.string.isRequired,
  markupMergeValues: PropTypes.shape({
    missionTitle: PropTypes.string,
    skepticismIncrease: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    attemptScore: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
};

QuestionSubtitle.defaultProps = {
  className: undefined,
  markupMergeValues: {},
};

export default QuestionSubtitle;
