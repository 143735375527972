import PropTypes from 'prop-types';

import Fade from '../../../../components/Fade';
import { PlayIcon } from '../../../../components/Icons';
import MediaPlayer from '../../../../components/MediaPlayer';
import Typography from '../../../../components/Typography';
import useHomeVideoInner from './hooks/useHomeVideoInner';
import useStyles from './homeVideoInner.styles';

const HomeVideoInner = ({ cta, loopVideo, playing, onReady }) => {
  const styles = useStyles();
  const {
    loopPlayerSources,
    initialLoopPlayerState,
    hasPlayed,
    onVideoStateChange,
  } = useHomeVideoInner(loopVideo, playing, onReady);

  return (
    <>
      {loopVideo && (
        <Fade className={styles.videoLoopInner} durationIn={0} in={!hasPlayed}>
          <MediaPlayer
            initialState={initialLoopPlayerState}
            loop
            onStateChange={onVideoStateChange}
            sources={loopPlayerSources}
            withOverlay={false}
          />
          <div className={styles.videoCta}>
            <PlayIcon />
            <Typography variant="videoCta" withShadow>
              {cta}
            </Typography>
          </div>
        </Fade>
      )}
      <Fade
        className={styles.videoCta}
        durationIn={500}
        durationOut={500}
        in={!playing && hasPlayed}
      >
        <PlayIcon />
        <Typography variant="videoCta" withShadow>
          {cta}
        </Typography>
      </Fade>
    </>
  );
};

HomeVideoInner.propTypes = {
  cta: PropTypes.string.isRequired,
  loopVideo: PropTypes.object,
  playing: PropTypes.bool.isRequired,
  onReady: PropTypes.func.isRequired,
};

HomeVideoInner.defaultProps = {
  loopVideo: undefined,
};

export default HomeVideoInner;
