import filterFalseyValues from '../helpers/filterFalseyValues';
import routes from './routes';

export const navTrayPrimary = () =>
  filterFalseyValues([
    routes.home,
    routes.mission,
    routes.about,
    routes.share,
    routes.stats,
    routes.faqs,
  ]);

export const navTraySecondary = () =>
  filterFalseyValues([routes.media, routes.contact, routes.legals]);

export const footerMenu = () =>
  filterFalseyValues([
    routes.home,
    routes.about,
    routes.stats,
    routes.share,
    routes.faqs,
  ]);
