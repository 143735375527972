import { memo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import Fade from '../../../components/Fade';
import Image from '../../../components/Image';
import SOTNav from '../../../components/LayoutBrochure/SOTNav';
import Markup from '../../../components/Markup';
import Typography from '../../../components/Typography';
import styling from '../../../services/styling';
import metaShareExample from '../../../static/images/meta_share_example.webp';
import useMetaHeader from './hooks/useMetaHeader';
import MetaCarousel from './MetaCarousel';
import useStyles from './metaHeader.styles';
import SendPostersModal from './SendPostersModal';

const MetaHeader = ({ content, formContent }) => {
  const styles = useStyles();
  const { title, body, postersCta, disclaimer, shareCta } = content;
  const { openSendPosters, onCloseSendPosters, onOpenSendPosters, onCopyLink } =
    useMetaHeader();

  return (
    <>
      <div className={styles.container}>
        <SOTNav
          color={
            openSendPosters ? undefined : styling.colors.theme.metaSecondary
          }
          isResponsive={false}
        />
        <div className={clsx(styles.contentWrapper, styles.header)}>
          <Typography className={styles.headingTitle} variant="metaTitle">
            {title}
          </Typography>
          <Typography
            className={styles.markup}
            component="div"
            variant="metaBody"
          >
            <Markup content={body} />
          </Typography>
        </div>
        <MetaCarousel />
        <div className={clsx(styles.contentWrapper, styles.footer)}>
          <Typography
            component="button"
            className={clsx(styles.metaButton, styles.postersCta)}
            onClick={onOpenSendPosters}
            type="button"
            variant="metaButton"
          >
            {postersCta}
          </Typography>
          <Typography
            className={clsx(styles.markup, styles.disclaimer)}
            component="div"
            variant="metaBodySmall"
          >
            <Markup content={disclaimer} />
          </Typography>
          <div className={styles.divider} />
          <Typography
            className={styles.markup}
            component="div"
            variant="metaBody"
          >
            <Markup content={shareCta} />
          </Typography>
          <Image className={styles.image} src={metaShareExample} />
          <Typography
            component="button"
            className={clsx(styles.metaButton, styles.shareCta)}
            onClick={onCopyLink}
            type="button"
            variant="metaButton"
          >
            Copy link to clipboard
          </Typography>
        </div>
      </div>
      <Fade in={openSendPosters}>
        <SendPostersModal onClose={onCloseSendPosters} content={formContent} />
      </Fade>
    </>
  );
};

MetaHeader.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    postersCta: PropTypes.string.isRequired,
    disclaimer: PropTypes.string.isRequired,
    shareCta: PropTypes.string.isRequired,
  }).isRequired,
  formContent: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    disclaimer: PropTypes.string.isRequired,
  }).isRequired,
};

export default memo(MetaHeader);
