import { createUseStyles } from 'react-jss';

import { variantMapping } from '../../../components/Typography/constants';
import styling from '../../../services/styling';

export default createUseStyles(
  {
    title: {
      textAlign: 'center',
      margin: [0, 'auto', 40],
      maxWidth: styling.sizes.landingSectionMaxWidth,
    },
    subtitle: {
      margin: [0, 'auto', 190],
      maxWidth: styling.sizes.landingSectionMaxWidth,
      '& a': {
        ...variantMapping.link.styles,
        fontSize: 'inherit',
        fontWeight: 'inherit',
        lineHeight: 'inherit',
        textDecoration: 'underline',
      },
    },
  },
  { name: 'landingWhen' },
);
