import { useCallback, useEffect, useRef, useState } from 'react';

import { getFallbackImageSrc, loadImage } from '../logic';

const useImage = (src) => {
  const image = useRef();

  const [isLoaded, setIsLoaded] = useState(false);
  const [isErrored, setIsErrored] = useState(false);
  const [renderableSrc, setRenderableSrc] = useState(getFallbackImageSrc());

  const setImageNodeReference = useCallback((node) => {
    image.current = node;
  }, []);

  useEffect(() => {
    setIsLoaded(false);
    return loadImage({
      imageNodeRef: image.current,
      src,
      setIsLoaded,
      setIsErrored,
      setRenderableSrc,
      setImageNodeReference,
    });
  }, [src]);

  return {
    isLoaded,
    isErrored,
    renderableSrc,
  };
};

export default useImage;
