import PropTypes from 'prop-types';

import styling from '../../../services/styling';
import IconSvg from '../../IconSvg';

const ArrowHeadUp = ({ color, height, width, ...rest }) => (
  <IconSvg
    {...rest}
    color={color}
    height={height}
    viewBox="0 0 13 12"
    width={width}
  >
    <polygon
      fill="currentColor"
      points="6.73372155 11.478245 3.60108748 5.99963573 0.467731764 0.521754968 6.73372155 3.27271623 12.9997113 0.521754968 9.86707727 5.99963573"
      transform="translate(6.733722, 6.000000) scale(1, -1) translate(-6.733722, -6.000000) "
    />
  </IconSvg>
);

ArrowHeadUp.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};

ArrowHeadUp.defaultProps = {
  color: styling.colors.theme.pureWhite,
  height: 12,
  width: 13,
};

export default ArrowHeadUp;
