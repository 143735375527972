import { useCallback, useEffect, useState } from 'react';

import log from '../services/log';

const useMediaQuery = (query) => {
  const sanitizedQuery = query.replace(/^@media( ?)/m, '');
  const mediaQuery = window?.matchMedia(sanitizedQuery);
  const [match, setMatch] = useState(!!mediaQuery?.matches);

  const handleMediaQueryChange = useCallback(
    (mediaQueryEvent) => {
      log.debug('[MEDIA QUERY]', mediaQueryEvent, !!mediaQueryEvent.matches);
      setMatch(!!mediaQueryEvent.matches);
    },
    [setMatch],
  );

  useEffect(() => {
    if (mediaQuery) {
      mediaQuery?.addEventListener?.('change', handleMediaQueryChange);
      return () =>
        mediaQuery?.removeEventListener?.('change', handleMediaQueryChange);
    }

    return () => {};
  }, [mediaQuery, handleMediaQueryChange]);

  return match;
};

export default useMediaQuery;
