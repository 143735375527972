export const CONTROLS = {
  SKIP: 'skip',
  PLAY_PAUSE: 'play_pause',
  VOLUME: 'volume',
  SHOW_CC: 'show_cc',
  REPLAY: 'replay',
};

export const CONTROLS_ALL = {
  [CONTROLS.PLAY_PAUSE]: true,
  [CONTROLS.VOLUME]: true,
  [CONTROLS.SHOW_CC]: true,
  [CONTROLS.SKIP]: true,
};

export const CONTROLS_STANDARD = {
  [CONTROLS.PLAY_PAUSE]: true,
  [CONTROLS.VOLUME]: true,
  [CONTROLS.SHOW_CC]: true,
  [CONTROLS.REPLAY]: true,
};

export const CONTROLS_MINIMAL = {
  [CONTROLS.VOLUME]: true,
};
