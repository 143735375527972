import PropTypes from 'prop-types';

import styling from '../../../services/styling';
import IconSvg from '../../IconSvg';

const SOTFull = ({ color, height, width, ...rest }) => (
  <IconSvg
    {...rest}
    color={color}
    height={height}
    viewBox="0 0 251 46"
    width={width}
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g fill="currentColor" transform="translate(-0.000091, 0.499817)">
        <path d="M0,31.0643862 L1.5566003,28.6646024 C2.62274005,29.529513 4.35159082,30.0695101 5.65253515,30.0695101 C6.37870687,30.0695101 7.01875335,29.8535113 7.01875335,29.3135142 C7.01875335,28.7085344 6.48387032,28.5135863 5.24729326,28.3406041 C3.30630244,28.0596226 0.554827829,27.6056589 0.554827829,24.6008954 C0.554827829,22.0070787 2.64540458,20.6891197 5.22734847,20.6891197 C7.72316712,20.6891197 9.17369739,21.2721335 10.5598604,22.2880603 L9.00326008,24.6439121 C7.76396328,23.9089669 6.5482376,23.4769692 5.63259036,23.4769692 C4.92727002,23.4769692 4.45766084,23.6938833 4.45766084,24.2128296 C4.45766084,24.7528267 5.05691116,24.9477749 6.20826956,25.0978757 C8.14926038,25.3578066 10.9233995,25.7898042 10.9233995,28.5996197 C10.9233995,31.669366 8.31969769,32.7292247 5.67338653,32.7292247 C3.00713057,32.7292247 1.25742843,31.9942795 0,31.0643862" />
        <path d="M11.2298241,26.719423 C11.2298241,21.89972 14.7274152,20.6888451 17.2468049,20.6888451 C20.6174747,20.6888451 21.8105358,22.4177511 22.4732468,24.3635712 L18.9321398,25.4874974 C18.5686006,24.0816744 17.9929214,23.7576761 17.203289,23.7576761 C15.9431409,23.7576761 15.4526803,24.9255343 15.4526803,26.741389 C15.4526803,28.4272783 15.7518522,29.7672033 17.1588665,29.7672033 C17.8433355,29.7672033 18.6329679,29.4221543 19.0173584,27.9083319 L22.5149496,29.098156 C21.8540517,30.8261468 20.4035214,32.7289501 17.097219,32.7289501 C14.4073919,32.7289501 11.2298241,31.4750585 11.2298241,26.719423" />
        <path d="M23.350727,16.4955388 L27.576303,16.4955388 L27.576303,22.6990988 C28.0667635,21.9000861 29.2616378,20.6892112 31.373066,20.6892112 C33.1671906,20.6892112 34.658517,21.5532066 34.658517,24.0390238 L34.658517,32.4913514 L30.4356608,32.4913514 L30.4356608,25.4448468 C30.4356608,24.6440036 30.1781917,24.1909552 29.4755911,24.1909552 C28.7503259,24.1909552 28.1311308,24.5579702 27.576303,25.0119338 L27.576303,32.4913514 L23.350727,32.4913514 L23.350727,16.4955388 Z" />
        <path d="M43.3579817,26.741572 C43.3579817,25.0547675 42.9318884,23.7578592 41.4813581,23.7578592 C40.0924754,23.7578592 39.6029214,24.9257173 39.6029214,26.741572 C39.6029214,28.4274613 40.0072568,29.7673863 41.4813581,29.7673863 C42.8675211,29.7673863 43.3579817,28.5784774 43.3579817,26.741572 M35.3791586,26.719606 C35.3791586,21.899903 38.707219,20.6890282 41.4813581,20.6890282 C44.2328327,20.6890282 47.5835577,21.899903 47.5835577,26.719606 C47.5835577,31.4752416 44.2328327,32.7291332 41.4813581,32.7291332 C38.7271638,32.7291332 35.3791586,31.4752416 35.3791586,26.719606" />
        <path d="M56.1006181,26.741572 C56.1006181,25.0547675 55.6745249,23.7578592 54.2239946,23.7578592 C52.8351119,23.7578592 52.3446513,24.9257173 52.3446513,26.741572 C52.3446513,28.4274613 52.7498932,29.7673863 54.2239946,29.7673863 C55.6101576,29.7673863 56.1006181,28.5784774 56.1006181,26.741572 M48.1217951,26.719606 C48.1217951,21.899903 51.4498555,20.6890282 54.2239946,20.6890282 C56.9754692,20.6890282 60.3261941,21.899903 60.3261941,26.719606 C60.3261941,31.4752416 56.9754692,32.7291332 54.2239946,32.7291332 C51.4698003,32.7291332 48.1217951,31.4752416 48.1217951,26.719606" />
        <polygon points="61.2931539 32.4915345 65.5160101 32.4915345 65.5160101 16.4957218 61.2931539 16.4957218" />
        <path d="M77.2200669,26.741572 C77.2200669,25.0547675 76.7939737,23.7578592 75.3434434,23.7578592 C73.9545607,23.7578592 73.4650067,24.9257173 73.4650067,26.741572 C73.4650067,28.4274613 73.8702486,29.7673863 75.3434434,29.7673863 C76.7296064,29.7673863 77.2200669,28.5784774 77.2200669,26.741572 M69.2412439,26.719606 C69.2412439,21.899903 72.5693042,20.6890282 75.3434434,20.6890282 C78.094918,20.6890282 81.4456429,21.899903 81.4456429,26.719606 C81.4456429,31.4752416 78.094918,32.7291332 75.3434434,32.7291332 C72.589249,32.7291332 69.2412439,31.4752416 69.2412439,26.719606" />
        <path d="M82.0026466,20.9045694 L82.0026466,20.4725717 C82.0026466,18.5487176 82.3643725,16.3868987 86.2481673,16.3868987 C87.0795025,16.3868987 88.6152514,16.5168641 88.8301112,16.5818468 L88.8301112,19.5005769 C88.6388226,19.434679 88.0839947,19.3706115 87.719549,19.3706115 C86.9743391,19.3706115 86.2255028,19.4136282 86.2255028,20.6455538 L86.2255028,20.9045694 L88.7004701,20.9045694 L88.7004701,23.9743157 L86.2255028,23.9743157 L86.2255028,32.4907107 L82.0026466,32.4907107 L82.0026466,23.9743157 L82.0026466,20.9045694 Z" />
        <path d="M93.2087182,20.9589352 L93.2087182,17.0691255 L97.4342942,17.0691255 L97.4342942,20.9589352 L100.356206,20.9589352 L100.356206,24.0286815 L97.4342942,24.0286815 L97.4342942,28.1143545 C97.4342942,29.3453649 97.733466,29.6492276 98.9265272,29.6492276 C99.2900663,29.6492276 99.8448942,29.5833297 100.036183,29.5192622 L100.036183,32.5020598 C99.8240428,32.5670425 98.2882939,32.6970079 97.4551455,32.6970079 C93.5722573,32.6970079 93.2087182,31.3131509 93.2087182,29.0643833 L93.2087182,24.0286815 L93.2087182,20.9589352 Z" />
        <path d="M164.828288,20.9589352 L164.828288,17.0691255 L169.053864,17.0691255 L169.053864,20.9589352 L171.975776,20.9589352 L171.975776,24.0286815 L169.053864,24.0286815 L169.053864,28.1143545 C169.053864,29.3453649 169.353036,29.6492276 170.546097,29.6492276 C170.909636,29.6492276 171.464464,29.5833297 171.655752,29.5192622 L171.655752,32.5020598 C171.443612,32.5670425 169.907863,32.6970079 169.073809,32.6970079 C165.191827,32.6970079 164.828288,31.3131509 164.828288,29.0643833 L164.828288,24.0286815 L164.828288,20.9589352 Z" />
        <path d="M101.36233,16.5497215 L105.587906,16.5497215 L105.587906,22.7532815 C106.078367,21.9542689 107.273241,20.743394 109.384669,20.743394 C111.177887,20.743394 112.67012,21.6073894 112.67012,24.0932066 L112.67012,32.5455342 L108.447264,32.5455342 L108.447264,25.4990295 C108.447264,24.6981864 108.189795,24.2451379 107.487194,24.2451379 C106.761929,24.2451379 106.142734,24.6121529 105.587906,25.0661166 L105.587906,32.5455342 L101.36233,32.5455342 L101.36233,16.5497215 Z" />
        <path d="M121.516904,26.7958463 C121.516904,25.1090418 121.090811,23.8121335 119.640281,23.8121335 C118.251398,23.8121335 117.760938,24.9799916 117.760938,26.7958463 C117.760938,28.4817356 118.166179,29.8216606 119.640281,29.8216606 C121.026444,29.8216606 121.516904,28.6327517 121.516904,26.7958463 M113.538081,26.7738803 C113.538081,21.9541773 116.866142,20.7433025 119.640281,20.7433025 C122.391755,20.7433025 125.74248,21.9541773 125.74248,26.7738803 C125.74248,31.5295159 122.391755,32.7834075 119.640281,32.7834075 C116.886086,32.7834075 113.538081,31.5295159 113.538081,26.7738803" />
        <path d="M126.571186,29.4331373 L126.571186,20.9588437 L130.796762,20.9588437 L130.796762,28.136229 C130.796762,28.9352416 131.052418,29.3892053 131.756832,29.3892053 C132.482097,29.3892053 133.098573,29.0212751 133.654307,28.5673114 L133.654307,20.9588437 L137.878976,20.9588437 L137.878976,32.544985 L133.654307,32.544985 L133.654307,30.7721471 C133.163846,31.572075 131.968066,32.7829498 129.856637,32.7829498 C128.065233,32.7829498 126.571186,31.9189545 126.571186,29.4331373" />
        <path d="M145.903763,25.0450659 C145.903763,24.1151725 145.413303,23.3582613 144.41153,23.3582613 C143.407945,23.3582613 142.917484,24.1151725 142.917484,25.0450659 C142.917484,25.9731287 143.407945,26.7309551 144.41153,26.7309551 C145.413303,26.7309551 145.903763,25.9731287 145.903763,25.0450659 M147.397809,33.9504419 C147.397809,33.3884788 147.248223,33.1715647 144.388866,33.0644805 L142.767898,32.9994978 C142.491391,32.9994978 142.235735,32.9784471 141.978266,32.9345151 C141.893047,33.1715647 141.851344,33.3665128 141.851344,33.5614609 C141.851344,34.468473 142.640977,34.8153525 144.731553,34.8153525 C146.267302,34.8153525 147.397809,34.5554217 147.397809,33.9504419 M138.03101,34.3613888 C138.03101,33.2594286 138.970229,32.5885509 139.825135,32.1556379 C139.396322,31.8316397 139.140666,31.377676 139.140666,30.8166282 C139.140666,29.7357187 139.974721,28.8277914 140.912126,28.1999303 C139.610275,27.4448496 138.970229,26.2339747 138.970229,24.8711685 C138.970229,22.6022654 140.761633,20.743394 144.347163,20.743394 C146.182084,20.743394 147.547395,21.2403744 148.399582,22.0183363 C149.212785,21.2833911 150.51373,20.8083767 151.473799,20.7644447 L151.473799,23.9431057 C151.174628,23.9431057 150.490159,23.9431057 149.594456,24.1362233 C149.618027,24.3311714 149.638879,24.5901869 149.638879,24.8711685 C149.638879,27.1409868 147.932692,29.0007735 144.347163,29.0007735 C143.727968,29.0007735 143.152289,28.9348755 142.640977,28.8488421 C142.533093,29.0218242 142.470539,29.1517896 142.470539,29.281755 C142.470539,29.6917867 142.938335,29.7128375 143.578382,29.7357187 L144.987209,29.7787354 C149.404074,29.9077856 151.045893,30.8806956 151.045893,33.2145814 C151.045893,35.7223646 147.973489,36.8682568 144.006288,36.8682568 C139.545908,36.8682568 138.03101,35.9173127 138.03101,34.3613888" />
        <path d="M152.415466,16.5497215 L156.641042,16.5497215 L156.641042,22.7532815 C157.131502,21.9542689 158.326376,20.743394 160.437805,20.743394 C162.231929,20.743394 163.723256,21.6073894 163.723256,24.0932066 L163.723256,32.5455342 L159.500399,32.5455342 L159.500399,25.4990295 C159.500399,24.6981864 159.24293,24.2451379 158.54033,24.2451379 C157.815065,24.2451379 157.195869,24.6121529 156.641042,25.0661166 L156.641042,32.5455342 L152.415466,32.5455342 L152.415466,16.5497215 Z" />
        <path d="M224.233395,26.7926429 C224.233395,25.1058384 223.807302,23.8080149 222.355865,23.8080149 C220.966982,23.8080149 220.476522,24.975873 220.476522,26.7926429 C220.476522,28.4785322 220.881764,29.8193725 222.355865,29.8193725 C223.742935,29.8193725 224.233395,28.6304636 224.233395,26.7926429 M216.252759,26.770677 C216.252759,21.9491435 219.580819,20.7382686 222.355865,20.7382686 C225.108246,20.7382686 228.459878,21.9491435 228.459878,26.770677 C228.459878,31.5272278 225.108246,32.7820346 222.355865,32.7820346 C219.601671,32.7820346 216.252759,31.5272278 216.252759,26.770677" />
        <path d="M229.223129,20.9539013 L233.449611,20.9539013 L233.449611,22.7487053 L233.470463,22.7487053 C233.960923,21.9487774 234.944564,20.7379025 237.036047,20.7379025 L237.07775,20.7379025 L237.07775,24.5224585 C236.907313,24.4995773 236.310782,24.4355098 236.010704,24.4355098 C234.815829,24.4355098 234.004439,25.0414049 233.449611,25.4953685 L233.449611,32.5437037 L229.223129,32.5437037 L229.223129,20.9539013 Z" />
        <path d="M244.451974,25.0412218 C244.451974,24.1113285 243.961513,23.353502 242.959741,23.353502 C241.955249,23.353502 241.464788,24.1113285 241.464788,25.0412218 C241.464788,25.9701999 241.955249,26.7271111 242.959741,26.7271111 C243.961513,26.7271111 244.451974,25.9701999 244.451974,25.0412218 M245.946927,33.9502588 C245.946927,33.3873805 245.797341,33.1704664 242.93617,33.0633822 L241.315202,32.9974843 C241.038695,32.9974843 240.783039,32.9764335 240.524663,32.9334168 C240.439445,33.1704664 240.397742,33.3654145 240.397742,33.5603626 C240.397742,34.46829 241.188281,34.8142542 243.279764,34.8142542 C244.815513,34.8142542 245.946927,34.5543234 245.946927,33.9502588 M236.576501,34.3602905 C236.576501,33.2583303 237.516626,32.5874526 238.371532,32.1545396 C237.942719,31.8296261 237.687063,31.3756625 237.687063,30.8146146 C237.687063,29.7327899 238.521118,28.8248626 239.458524,28.1970015 C238.156673,27.4410056 237.516626,26.2301307 237.516626,24.8673244 C237.516626,22.5975061 239.308938,20.7377194 242.894467,20.7377194 C244.730294,20.7377194 246.096513,21.2346998 246.948699,22.013577 C247.761903,21.2786318 249.062847,20.8036174 250.023823,20.7587702 L250.023823,23.9383464 C249.724652,23.9383464 249.040183,23.9383464 248.14448,24.1323792 C248.167145,24.3273273 248.187996,24.5863429 248.187996,24.8673244 C248.187996,27.138058 246.480903,28.9978447 242.894467,28.9978447 C242.275272,28.9978447 241.699593,28.932862 241.188281,28.8459133 C241.080398,29.0188954 241.017844,29.1497761 241.017844,29.2788262 C241.017844,29.6897732 241.48564,29.7108239 242.125686,29.7327899 L243.53542,29.7758066 C247.953191,29.905772 249.59501,30.8786821 249.59501,33.2134831 C249.59501,35.7221816 246.522606,36.8680737 242.553592,36.8680737 C238.092305,36.8680737 236.576501,35.9171297 236.576501,34.3602905" />
        <g transform="translate(175.490139, 6.971820)">
          <path d="M9.16907382,20.5024088 C9.16907382,20.9783385 8.78830963,21.365489 8.31779387,21.365489 C7.84727811,21.365489 7.46651392,20.9783385 7.46651392,20.5024088 C7.46651392,20.0255639 7.84727811,19.6393287 8.31779387,19.6393287 C8.78830963,19.6393287 9.16907382,20.0255639 9.16907382,20.5024088" />
          <path d="M17.082623,3.52901839 C17.082623,4.87351966 16.0065109,5.96358162 14.6792757,5.96358162 C13.3520405,5.96358162 12.2759284,4.87351966 12.2759284,3.52901839 C12.2759284,2.18360186 13.3520405,1.09353991 14.6792757,1.09353991 C16.0065109,1.09353991 17.082623,2.18360186 17.082623,3.52901839" />
          <path d="M30.8248562,5.96367314 C30.8248562,7.02353187 29.976296,7.88386625 28.9282878,7.88386625 C27.8811863,7.88386625 27.0326261,7.02353187 27.0326261,5.96367314 C27.0326261,4.90289916 27.8811863,4.04256479 28.9282878,4.04256479 C29.976296,4.04256479 30.8248562,4.90289916 30.8248562,5.96367314" />
          <path d="M3.79223008,15.7352411 C3.79223008,16.7969304 2.94276329,17.6563495 1.89566175,17.6563495 C0.848560209,17.6563495 0,16.7969304 0,15.7352411 C0,14.6744672 0.848560209,13.8141328 1.89566175,13.8141328 C2.94276329,13.8141328 3.79223008,14.6744672 3.79223008,15.7352411" />
          <path d="M31.8880042,10.5782688 C31.8880042,11.2894175 31.3186711,11.8651093 30.6169771,11.8651093 C29.915283,11.8651093 29.3468565,11.2894175 29.3468565,10.5782688 C29.3468565,9.86620478 29.915283,9.29051295 30.6169771,9.29051295 C31.3186711,9.29051295 31.8880042,9.86620478 31.8880042,10.5782688" />
          <path d="M35.1111731,21.3653974 C35.1111731,22.0756309 34.5427466,22.6531532 33.840146,22.6531532 C33.138452,22.6531532 32.5700254,22.0756309 32.5700254,21.3653974 C32.5700254,20.6533334 33.138452,20.0776416 33.840146,20.0776416 C34.5427466,20.0776416 35.1111731,20.6533334 35.1111731,21.3653974" />
          <path d="M33.4863979,7.63418623 C33.4863979,8.34533496 32.9179714,8.9210268 32.2162773,8.9210268 C31.5145833,8.9210268 30.9461568,8.34533496 30.9461568,7.63418623 C30.9461568,6.9230375 31.5145833,6.34643042 32.2162773,6.34643042 C32.9179714,6.34643042 33.4863979,6.9230375 33.4863979,7.63418623" />
          <path d="M22.7714215,33.4457734 C22.7714215,34.1560069 22.2020883,34.732614 21.5003943,34.732614 C20.7977937,34.732614 20.2293671,34.1560069 20.2293671,33.4457734 C20.2293671,32.7346247 20.7977937,32.1580176 21.5003943,32.1580176 C22.2020883,32.1580176 22.7714215,32.7346247 22.7714215,33.4457734" />
          <path d="M10.2440074,14.266266 C10.2440074,15.1540578 9.53415416,15.8725285 8.65930309,15.8725285 C7.78354544,15.8725285 7.07369219,15.1540578 7.07369219,14.266266 C7.07369219,13.3793894 7.78354544,12.6600034 8.65930309,12.6600034 C9.53415416,12.6600034 10.2440074,13.3793894 10.2440074,14.266266" />
          <path d="M6.39529731,9.38817006 C6.39529731,10.500198 5.50594094,11.4008033 4.40897742,11.4008033 C3.31110732,11.4008033 2.42084437,10.500198 2.42084437,9.38817006 C2.42084437,8.27614211 3.31110732,7.37462152 4.40897742,7.37462152 C5.50594094,7.37462152 6.39529731,8.27614211 6.39529731,9.38817006" />
          <path d="M25.0398695,2.18698829 C25.0398695,3.29901623 24.1505131,4.20053682 23.052643,4.20053682 C21.9547729,4.20053682 21.0654165,3.29901623 21.0654165,2.18698829 C21.0654165,1.07496034 21.9547729,0.174354998 23.052643,0.174354998 C24.1505131,0.174354998 25.0398695,1.07496034 25.0398695,2.18698829" />
          <path d="M38.1212954,14.9696351 C38.1212954,16.081663 37.231939,16.9822684 36.1349755,16.9822684 C35.038012,16.9822684 34.1477491,16.081663 34.1477491,14.9696351 C34.1477491,13.8576071 35.038012,12.9570018 36.1349755,12.9570018 C37.231939,12.9570018 38.1212954,13.8576071 38.1212954,14.9696351" />
          <path d="M10.3291354,8.66191971 C10.3291354,9.37398369 9.75980228,9.94967552 9.05810826,9.94967552 C8.35641424,9.94967552 7.78708111,9.37398369 7.78708111,8.66191971 C7.78708111,7.95168622 8.35641424,7.37507914 9.05810826,7.37507914 C9.75980228,7.37507914 10.3291354,7.95168622 10.3291354,8.66191971" />
          <path d="M33.8405993,12.9792423 C33.8405993,13.6894758 33.2721727,14.2660829 32.5695721,14.2660829 C31.8687847,14.2660829 31.298545,13.6894758 31.298545,12.9792423 C31.298545,12.2680936 31.8687847,11.6914865 32.5695721,11.6914865 C33.2721727,11.6914865 33.8405993,12.2680936 33.8405993,12.9792423" />
          <path d="M3.78080715,25.3222034 C3.78080715,26.0333521 3.2123806,26.6099592 2.51068658,26.6099592 C1.80808598,26.6099592 1.23965943,26.0333521 1.23965943,25.3222034 C1.23965943,24.6101394 1.80808598,24.0344475 2.51068658,24.0344475 C3.2123806,24.0344475 3.78080715,24.6101394 3.78080715,25.3222034" />
          <path d="M33.4863979,29.9605955 C33.4863979,30.6708289 32.9179714,31.247436 32.2162773,31.247436 C31.5145833,31.247436 30.9461568,30.6708289 30.9461568,29.9605955 C30.9461568,29.2485315 31.5145833,28.6728397 32.2162773,28.6728397 C32.9179714,28.6728397 33.4863979,29.2485315 33.4863979,29.9605955" />
          <path d="M31.8255408,16.812032 C31.8255408,17.8307045 31.0114306,18.6562594 30.0069384,18.6562594 C29.0015396,18.6562594 28.1874295,17.8307045 28.1874295,16.812032 C28.1874295,15.79519 29.0015396,14.9696351 30.0069384,14.9696351 C31.0114306,14.9696351 31.8255408,15.79519 31.8255408,16.812032" />
          <path d="M21.1486407,36.4109067 C21.1486407,37.4295792 20.3345306,38.2542188 19.3291318,38.2542188 C18.323733,38.2542188 17.5105295,37.4295792 17.5105295,36.4109067 C17.5105295,35.3940647 18.323733,34.5685098 19.3291318,34.5685098 C20.3345306,34.5685098 21.1486407,35.3940647 21.1486407,36.4109067" />
          <path d="M28.6772555,10.5782688 C28.6772555,11.596026 27.8622388,12.4206657 26.8586531,12.4206657 C25.8532543,12.4206657 25.0400508,11.596026 25.0400508,10.5782688 C25.0400508,9.56051151 25.8532543,8.73495661 26.8586531,8.73495661 C27.8622388,8.73495661 28.6772555,9.56051151 28.6772555,10.5782688" />
          <path d="M18.4770359,33.0914804 C18.4770359,34.1092377 17.6629258,34.9338773 16.6584336,34.9338773 C15.6530348,34.9338773 14.8389247,34.1092377 14.8389247,33.0914804 C14.8389247,32.0728079 15.6530348,31.247253 16.6584336,31.247253 C17.6629258,31.247253 18.4770359,32.0728079 18.4770359,33.0914804" />
          <path d="M10.0298729,4.57395855 C10.0298729,5.34185275 9.41611726,5.96330704 8.65912178,5.96330704 C7.90121971,5.96330704 7.28746409,5.34185275 7.28746409,4.57395855 C7.28746409,3.80606436 7.90121971,3.18461006 8.65912178,3.18461006 C9.41611726,3.18461006 10.0298729,3.80606436 10.0298729,4.57395855" />
          <path d="M4.92799528,21.9096047 C4.92799528,22.6774989 4.31423966,23.2989532 3.55724418,23.2989532 C2.79934211,23.2989532 2.18558649,22.6774989 2.18558649,21.9096047 C2.18558649,21.1426257 2.79934211,20.5211714 3.55724418,20.5211714 C4.31423966,20.5211714 4.92799528,21.1426257 4.92799528,21.9096047" />
          <path d="M25.0398695,36.4109067 C25.0398695,37.1788009 24.4261139,37.8011704 23.6682118,37.8011704 C22.9112163,37.8011704 22.2965541,37.1788009 22.2965541,36.4109067 C22.2965541,35.6430125 22.9112163,35.0224734 23.6682118,35.0224734 C24.4261139,35.0224734 25.0398695,35.6430125 25.0398695,36.4109067" />
          <path d="M14.1816532,31.3493033 C14.1816532,32.1162822 13.5678975,32.7386518 12.810902,32.7386518 C12.053,32.7386518 11.4383378,32.1162822 11.4383378,31.3493033 C11.4383378,30.5814091 12.053,29.96087 12.810902,29.96087 C13.5678975,29.96087 14.1816532,30.5814091 14.1816532,31.3493033" />
          <path d="M34.9041099,17.656258 C34.9041099,18.25117 34.4281547,18.7335064 33.8406899,18.7335064 C33.2532252,18.7335064 32.7772699,18.25117 32.7772699,17.656258 C32.7772699,17.0613459 33.2532252,16.5799248 33.8406899,16.5799248 C34.4281547,16.5799248 34.9041099,17.0613459 34.9041099,17.656258" />
          <path d="M4.61966694,18.7332319 C4.61966694,19.3281439 4.14461828,19.8104803 3.55715352,19.8104803 C2.96968876,19.8104803 2.49373351,19.3281439 2.49373351,18.7332319 C2.49373351,18.1392351 2.96968876,17.6559834 3.55715352,17.6559834 C4.14461828,17.6559834 4.61966694,18.1392351 4.61966694,18.7332319" />
          <path d="M36.9635003,23.6321955 C36.9635003,24.0962269 36.5918019,24.4733096 36.1348849,24.4733096 C35.6761547,24.4733096 35.3044563,24.0962269 35.3044563,23.6321955 C35.3044563,23.168164 35.6761547,22.7919966 36.1348849,22.7919966 C36.5918019,22.7919966 36.9635003,23.168164 36.9635003,23.6321955" />
          <path d="M6.52013357,15.1168072 C6.52013357,15.5808386 6.14843519,15.9579213 5.68970499,15.9579213 C5.23188138,15.9579213 4.86108957,15.5808386 4.86108957,15.1168072 C4.86108957,14.653691 5.23188138,14.2775235 5.68970499,14.2775235 C6.14843519,14.2775235 6.52013357,14.653691 6.52013357,15.1168072" />
          <path d="M27.3089521,29.9605955 C27.3089521,30.4246269 26.9372537,30.8007944 26.4794301,30.8007944 C26.0216065,30.8007944 25.6499081,30.4246269 25.6499081,29.9605955 C25.6499081,29.4956488 26.0216065,29.1194813 26.4794301,29.1194813 C26.9372537,29.1194813 27.3089521,29.4956488 27.3089521,29.9605955" />
          <path d="M32.7324848,19.8107549 C32.7324848,20.2738711 32.3598798,20.6509538 31.9029628,20.6509538 C31.4442326,20.6509538 31.0743474,20.2738711 31.0743474,19.8107549 C31.0743474,19.3467235 31.4442326,18.9696408 31.9029628,18.9696408 C32.3598798,18.9696408 32.7324848,19.3467235 32.7324848,19.8107549" />
          <path d="M29.5072307,13.8189836 C29.5072307,14.283015 29.1355324,14.6600978 28.6768022,14.6600978 C28.2198851,14.6600978 27.8481867,14.283015 27.8481867,13.8189836 C27.8481867,13.3558674 28.2198851,12.9797 28.6768022,12.9797 C29.1355324,12.9797 29.5072307,13.3558674 29.5072307,13.8189836" />
          <path d="M5.99259384,24.4731266 C5.99259384,24.9362427 5.62089546,25.3124102 5.16307185,25.3124102 C4.70524823,25.3124102 4.33354985,24.9362427 4.33354985,24.4731266 C4.33354985,24.0081799 4.70524823,23.6320124 5.16307185,23.6320124 C5.62089546,23.6320124 5.99259384,24.0081799 5.99259384,24.4731266" />
          <path d="M6.5343669,30.7600658 C6.5343669,31.3549778 6.05931824,31.8373142 5.47185348,31.8373142 C4.88438872,31.8373142 4.40843347,31.3549778 4.40843347,30.7600658 C4.40843347,30.1651537 4.88438872,29.6837325 5.47185348,29.6837325 C6.05931824,29.6837325 6.5343669,30.1651537 6.5343669,30.7600658" />
          <path d="M14.6793664,9.38817006 C14.6793664,9.99223462 14.1961585,10.4809778 13.6005345,10.4809778 C13.0040039,10.4809778 12.5217026,9.99223462 12.5217026,9.38817006 C12.5217026,8.78502074 13.0040039,8.29536235 13.6005345,8.29536235 C14.1961585,8.29536235 14.6793664,8.78502074 14.6793664,9.38817006" />
          <path d="M13.889462,28.0542226 C13.889462,28.6582872 13.4062541,29.1479456 12.8106301,29.1479456 C12.2140995,29.1479456 11.7317982,28.6582872 11.7317982,28.0542226 C11.7317982,27.4501581 12.2140995,26.9614149 12.8106301,26.9614149 C13.4062541,26.9614149 13.889462,27.4501581 13.889462,28.0542226" />
          <path d="M8.86627563,17.656258 C8.86627563,18.2612378 8.38306773,18.7499809 7.78744374,18.7499809 C7.19091317,18.7499809 6.70861185,18.2612378 6.70861185,17.656258 C6.70861185,17.0521934 7.19091317,16.5634503 7.78744374,16.5634503 C8.38306773,16.5634503 8.86627563,17.0521934 8.86627563,17.656258" />
          <path d="M6.24208505,27.5089171 C6.24208505,28.1129816 5.75978374,28.6017248 5.16325316,28.6017248 C4.56762917,28.6017248 4.08351469,28.1129816 4.08351469,27.5089171 C4.08351469,26.9039373 4.56762917,26.4151941 5.16325316,26.4151941 C5.75978374,26.4151941 6.24208505,26.9039373 6.24208505,27.5089171" />
          <path d="M22.1440671,29.9605955 C22.1440671,30.56466 21.6617658,31.0534032 21.0652352,31.0534032 C20.4687046,31.0534032 19.9864033,30.56466 19.9864033,29.9605955 C19.9864033,29.3565309 20.4687046,28.8677878 21.0652352,28.8677878 C21.6617658,28.8677878 22.1440671,29.3565309 22.1440671,29.9605955" />
          <path d="M18.125645,7.63418623 C18.125645,8.32794523 17.5699106,8.88990832 16.8854416,8.88990832 C16.2009726,8.88990832 15.6461448,8.32794523 15.6461448,7.63418623 C15.6461448,6.94042723 16.2009726,6.37754889 16.8854416,6.37754889 C17.5699106,6.37754889 18.125645,6.94042723 18.125645,7.63418623" />
          <path d="M19.66774,1.09372296 C19.66774,1.69778752 19.1854386,2.18744591 18.5889081,2.18744591 C17.9932841,2.18744591 17.5100762,1.69778752 17.5100762,1.09372296 C17.5100762,0.490573644 17.9932841,0 18.5889081,0 C19.1854386,0 19.66774,0.490573644 19.66774,1.09372296" />
          <path d="M11.7998824,11.0379985 C11.7998824,11.6420631 11.3166746,12.1308062 10.720144,12.1308062 C10.12452,12.1308062 9.64131209,11.6420631 9.64131209,11.0379985 C9.64131209,10.4348492 10.12452,9.9451908 10.720144,9.9451908 C11.3166746,9.9451908 11.7998824,10.4348492 11.7998824,11.0379985" />
          <path d="M28.9287411,27.6917839 C28.9287411,28.2949332 28.4464398,28.7845916 27.8499092,28.7845916 C27.2542853,28.7845916 26.7701708,28.2949332 26.7701708,27.6917839 C26.7701708,27.0877193 27.2542853,26.5980609 27.8499092,26.5980609 C28.4464398,26.5980609 28.9287411,27.0877193 28.9287411,27.6917839" />
          <path d="M17.5101668,29.6840071 C17.5101668,30.1599368 17.1294026,30.5470872 16.6588869,30.5470872 C16.1883711,30.5470872 15.8067003,30.1599368 15.8067003,29.6840071 C15.8067003,29.206247 16.1883711,28.8200117 16.6588869,28.8200117 C17.1294026,28.8200117 17.5101668,29.206247 17.5101668,29.6840071" />
          <path d="M5.2599854,12.9565442 C5.2599854,13.4324738 4.8792212,13.818709 4.40870545,13.818709 C3.93818969,13.818709 3.55651891,13.4324738 3.55651891,12.9565442 C3.55651891,12.4796993 3.93818969,12.093464 4.40870545,12.093464 C4.8792212,12.093464 5.2599854,12.4796993 5.2599854,12.9565442" />
          <path d="M30.3227007,22.2278369 C30.3227007,22.7046818 29.94103,23.0900018 29.4714208,23.0900018 C29.000905,23.0900018 28.6192342,22.7046818 28.6192342,22.2278369 C28.6192342,21.7500767 29.000905,21.365672 29.4714208,21.365672 C29.94103,21.365672 30.3227007,21.7500767 30.3227007,22.2278369" />
          <path d="M16.0374253,36.4109067 C16.0374253,36.8877516 15.6566611,37.2739868 15.1861454,37.2739868 C14.7156296,37.2739868 14.3339588,36.8877516 14.3339588,36.4109067 C14.3339588,35.934977 14.7156296,35.5487418 15.1861454,35.5487418 C15.6566611,35.5487418 16.0374253,35.934977 16.0374253,36.4109067" />
          <path d="M35.7552992,11.0379985 C35.7552992,11.5148434 35.3736285,11.9010786 34.9040193,11.9010786 C34.4335035,11.9010786 34.0518327,11.5148434 34.0518327,11.0379985 C34.0518327,10.5620689 34.4335035,10.1758336 34.9040193,10.1758336 C35.3736285,10.1758336 35.7552992,10.5620689 35.7552992,11.0379985" />
          <path d="M8.76890879,11.0379985 C8.76890879,11.5148434 8.38723801,11.9010786 7.91672225,11.9010786 C7.44711308,11.9010786 7.0654423,11.5148434 7.0654423,11.0379985 C7.0654423,10.5620689 7.44711308,10.1758336 7.91672225,10.1758336 C8.38723801,10.1758336 8.76890879,10.5620689 8.76890879,11.0379985" />
          <path d="M6.84405511,20.2062341 C6.84405511,20.6821638 6.46238434,21.0693143 5.99277516,21.0693143 C5.5222594,21.0693143 5.14058863,20.6821638 5.14058863,20.2062341 C5.14058863,19.7293892 5.5222594,19.343154 5.99277516,19.343154 C6.46238434,19.343154 6.84405511,19.7293892 6.84405511,20.2062341" />
          <path d="M11.7676081,6.37764042 C11.7676081,6.71994367 11.4938206,6.99634897 11.1574789,6.99634897 C10.819324,6.99634897 10.546443,6.71994367 10.546443,6.37764042 C10.546443,6.03625242 10.819324,5.75893187 11.1574789,5.75893187 C11.4938206,5.75893187 11.7676081,6.03625242 11.7676081,6.37764042" />
          <path d="M6.0817108,17.656258 C6.0817108,17.9985612 5.80792321,18.2758818 5.4715815,18.2758818 C5.13433321,18.2758818 4.86145221,17.9985612 4.86145221,17.656258 C4.86145221,17.3157852 5.13433321,17.0375494 5.4715815,17.0375494 C5.80792321,17.0375494 6.0817108,17.3157852 6.0817108,17.656258" />
          <path d="M35.0618551,27.6917839 C35.0618551,28.0331719 34.7889741,28.3104924 34.4508192,28.3104924 C34.1144775,28.3104924 33.8406899,28.0331719 33.8406899,27.6917839 C33.8406899,27.3494806 34.1144775,27.0730753 34.4508192,27.0730753 C34.7889741,27.0730753 35.0618551,27.3494806 35.0618551,27.6917839" />
          <path d="M26.2603094,27.853783 C26.2603094,28.195171 25.9874284,28.4715763 25.6492735,28.4715763 C25.3129318,28.4715763 25.0400508,28.195171 25.0400508,27.853783 C25.0400508,27.5114798 25.3129318,27.2350745 25.6492735,27.2350745 C25.9874284,27.2350745 26.2603094,27.5114798 26.2603094,27.853783" />
          <path d="M13.8619925,6.99589134 C13.8619925,7.4050078 13.5347167,7.73632806 13.1321945,7.73632806 C12.7278592,7.73632806 12.4014899,7.4050078 12.4014899,6.99589134 C12.4014899,6.58860539 12.7278592,6.25636988 13.1321945,6.25636988 C13.5347167,6.25636988 13.8619925,6.58860539 13.8619925,6.99589134" />
          <path d="M26.8588344,7.63418623 C26.8588344,8.05062468 26.5252125,8.38926693 26.1136245,8.38926693 C25.70113,8.38926693 25.367508,8.05062468 25.367508,7.63418623 C25.367508,7.21774778 25.70113,6.87910553 26.1136245,6.87910553 C26.5252125,6.87910553 26.8588344,7.21774778 26.8588344,7.63418623" />
          <path d="M7.14105119,12.4208487 C7.14105119,12.8382024 6.80652264,13.1768447 6.39493468,13.1768447 C5.98334671,13.1768447 5.65063133,12.8382024 5.65063133,12.4208487 C5.65063133,12.003495 5.98334671,11.665768 6.39493468,11.665768 C6.80652264,11.665768 7.14105119,12.003495 7.14105119,12.4208487" />
          <path d="M33.3880338,15.7352411 C33.3880338,16.0775444 33.1151528,16.3539497 32.7779045,16.3539497 C32.4406562,16.3539497 32.1677752,16.0775444 32.1677752,15.7352411 C32.1677752,15.3947684 32.4406562,15.1165326 32.7779045,15.1165326 C33.1151528,15.1165326 33.3880338,15.3947684 33.3880338,15.7352411" />
          <path d="M11.889906,4.57395855 C11.889906,4.82656737 11.6877383,5.03066797 11.4384284,5.03066797 C11.1900251,5.03066797 10.9878575,4.82656737 10.9878575,4.57395855 C10.9878575,4.32134974 11.1900251,4.11633389 11.4384284,4.11633389 C11.6877383,4.11633389 11.889906,4.32134974 11.889906,4.57395855" />
          <path d="M1.69041172,22.6527871 C1.69041172,22.9044807 1.48824406,23.1094966 1.23984075,23.1094966 C0.99053086,23.1094966 0.788363203,22.9044807 0.788363203,22.6527871 C0.788363203,22.4001783 0.99053086,22.1951625 1.23984075,22.1951625 C1.48824406,22.1951625 1.69041172,22.4001783 1.69041172,22.6527871" />
          <path d="M11.6213766,3.09464105 C11.6213766,3.34724987 11.4192089,3.55135047 11.1708056,3.55135047 C10.9205891,3.55135047 10.7193281,3.34724987 10.7193281,3.09464105 C10.7193281,2.84203224 10.9205891,2.63701638 11.1708056,2.63701638 C11.4192089,2.63701638 11.6213766,2.84203224 11.6213766,3.09464105" />
          <path d="M37.037024,21.3653974 C37.037024,21.617091 36.8339498,21.8211916 36.5855465,21.8211916 C36.3371432,21.8211916 36.1349755,21.617091 36.1349755,21.3653974 C36.1349755,21.1127886 36.3371432,20.9077728 36.5855465,20.9077728 C36.8339498,20.9077728 37.037024,21.1127886 37.037024,21.3653974" />
          <path d="M7.13370788,24.4731266 C7.13370788,24.6387867 7.00134699,24.7733283 6.83634917,24.7733283 C6.67225794,24.7733283 6.53989705,24.6387867 6.53989705,24.4731266 C6.53989705,24.3065512 6.67225794,24.1710943 6.83634917,24.1710943 C7.00134699,24.1710943 7.13370788,24.3065512 7.13370788,24.4731266" />
          <path d="M25.5648708,31.2170498 C25.5648708,31.3836251 25.4306967,31.5172515 25.2675121,31.5172515 C25.1034208,31.5172515 24.9701534,31.3836251 24.9701534,31.2170498 C24.9701534,31.0504744 25.1034208,30.9159327 25.2675121,30.9159327 C25.4306967,30.9159327 25.5648708,31.0504744 25.5648708,31.2170498" />
          <path d="M29.6436712,12.1308977 C29.6436712,12.2974731 29.5104038,12.43293 29.3463125,12.43293 C29.1822213,12.43293 29.0489538,12.2974731 29.0489538,12.1308977 C29.0489538,11.9652376 29.1822213,11.830696 29.3463125,11.830696 C29.5104038,11.830696 29.6436712,11.9652376 29.6436712,12.1308977" />
          <path d="M7.13370788,7.8836832 C7.13370788,8.05025858 7.00134699,8.18571548 6.83634917,8.18571548 C6.67225794,8.18571548 6.53989705,8.05025858 6.53989705,7.8836832 C6.53989705,7.71802307 6.67225794,7.58348142 6.83634917,7.58348142 C7.00134699,7.58348142 7.13370788,7.71802307 7.13370788,7.8836832" />
          <path d="M31.9646103,5.64571552 C31.9646103,5.8122909 31.8313429,5.94683256 31.6672516,5.94683256 C31.504067,5.94683256 31.3698929,5.8122909 31.3698929,5.64571552 C31.3698929,5.48005539 31.504067,5.34551374 31.6672516,5.34551374 C31.8313429,5.34551374 31.9646103,5.48005539 31.9646103,5.64571552" />
          <path d="M9.35555762,6.58155797 C9.35555762,6.74813335 9.22229015,6.882675 9.05819892,6.882675 C8.89410768,6.882675 8.76084021,6.74813335 8.76084021,6.58155797 C8.76084021,6.41498259 8.89410768,6.28044094 9.05819892,6.28044094 C9.22229015,6.28044094 9.35555762,6.41498259 9.35555762,6.58155797" />
          <path d="M4.63118053,16.5634503 C4.63118053,16.7300256 4.49791306,16.8645673 4.33382182,16.8645673 C4.16973058,16.8645673 4.03646311,16.7300256 4.03646311,16.5634503 C4.03646311,16.3968749 4.16973058,16.2632485 4.33382182,16.2632485 C4.49791306,16.2632485 4.63118053,16.3968749 4.63118053,16.5634503" />
          <path d="M23.9019285,4.82025215 C23.9019285,4.98682753 23.768661,5.12136918 23.6036632,5.12136918 C23.4404785,5.12136918 23.3063045,4.98682753 23.3063045,4.82025215 C23.3063045,4.65367677 23.4404785,4.51913512 23.6036632,4.51913512 C23.768661,4.51913512 23.9019285,4.65367677 23.9019285,4.82025215" />
          <path d="M34.4446545,9.19129992 C34.4446545,9.35879055 34.3122936,9.49241696 34.1472958,9.49241696 C33.9832045,9.49241696 33.8508436,9.35879055 33.8508436,9.19129992 C33.8508436,9.02563979 33.9832045,8.89018289 34.1472958,8.89018289 C34.3122936,8.89018289 34.4446545,9.02563979 34.4446545,9.19129992" />
          <path d="M31.3698023,32.1579261 C31.3698023,32.3245014 31.2374414,32.4599584 31.0742567,32.4599584 C30.9083523,32.4599584 30.7759914,32.3245014 30.7759914,32.1579261 C30.7759914,31.9922659 30.9083523,31.856809 31.0742567,31.856809 C31.2374414,31.856809 31.3698023,31.9922659 31.3698023,32.1579261" />
          <path d="M2.80750134,27.2347084 C2.80750134,27.4012837 2.67423387,27.5358254 2.51014264,27.5358254 C2.3460514,27.5358254 2.21278393,27.4012837 2.21278393,27.2347084 C2.21278393,27.068133 2.3460514,26.9335913 2.51014264,26.9335913 C2.67423387,26.9335913 2.80750134,27.068133 2.80750134,27.2347084" />
          <path d="M15.136374,7.37480457 C15.136374,7.5404647 15.0031066,7.6759216 14.8390153,7.6759216 C14.6749241,7.6759216 14.5416566,7.5404647 14.5416566,7.37480457 C14.5416566,7.20914444 14.6749241,7.07368754 14.8390153,7.07368754 C15.0031066,7.07368754 15.136374,7.20914444 15.136374,7.37480457" />
          <path d="M28.2993017,3.18488464 C28.2993017,3.4365782 28.0980406,3.64159406 27.8478241,3.64159406 C27.5994208,3.64159406 27.3972531,3.4365782 27.3972531,3.18488464 C27.3972531,2.93227582 27.5994208,2.72817522 27.8478241,2.72817522 C28.0980406,2.72817522 28.2993017,2.93227582 28.2993017,3.18488464" />
          <path d="M12.8523328,36.0606408 C12.8523328,36.3123343 12.6501652,36.5173502 12.4017619,36.5173502 C12.1533585,36.5173502 11.9511909,36.3123343 11.9511909,36.0606408 C11.9511909,35.8089472 12.1533585,35.6039313 12.4017619,35.6039313 C12.6501652,35.6039313 12.8523328,35.8089472 12.8523328,36.0606408" />
          <path d="M30.4567841,30.7600658 C30.4567841,31.0126746 30.255523,31.2167752 30.0062132,31.2167752 C29.7559967,31.2167752 29.5556422,31.0126746 29.5556422,30.7600658 C29.5556422,30.5074569 29.7559967,30.3033563 30.0062132,30.3033563 C30.255523,30.3033563 30.4567841,30.5074569 30.4567841,30.7600658" />
          <path d="M33.2284755,10.1755591 C33.2284755,10.4281679 33.028121,10.6322685 32.7779045,10.6322685 C32.5295012,10.6322685 32.326427,10.4281679 32.326427,10.1755591 C32.326427,9.92386549 32.5295012,9.71884964 32.7779045,9.71884964 C33.028121,9.71884964 33.2284755,9.92386549 33.2284755,10.1755591" />
          <path d="M8.36810914,22.9524398 C8.36810914,23.2041333 8.16594148,23.4091492 7.91663159,23.4091492 C7.66822828,23.4091492 7.46606063,23.2041333 7.46606063,22.9524398 C7.46606063,22.699831 7.66822828,22.4957304 7.91663159,22.4957304 C8.16594148,22.4957304 8.36810914,22.699831 8.36810914,22.9524398" />
          <path d="M29.6406795,8.73477356 C29.6406795,8.98738237 29.4385119,9.19148297 29.189202,9.19148297 C28.9398921,9.19148297 28.738631,8.98738237 28.738631,8.73477356 C28.738631,8.48216474 28.9398921,8.27806414 29.189202,8.27806414 C29.4385119,8.27806414 29.6406795,8.48216474 29.6406795,8.73477356" />
          <path d="M6.69265602,29.0585257 C6.69265602,29.3111345 6.49048836,29.5152351 6.24208505,29.5152351 C5.99368174,29.5152351 5.7906075,29.3111345 5.7906075,29.0585257 C5.7906075,28.8068322 5.99368174,28.6018163 6.24208505,28.6018163 C6.49048836,28.6018163 6.69265602,28.8068322 6.69265602,29.0585257" />
          <path d="M19.1693922,6.37764042 C19.1693922,6.63024923 18.9672245,6.83526509 18.7188212,6.83526509 C18.4695113,6.83526509 18.2673436,6.63024923 18.2673436,6.37764042 C18.2673436,6.12594685 18.4695113,5.920931 18.7188212,5.920931 C18.9672245,5.920931 19.1693922,6.12594685 19.1693922,6.37764042" />
          <path d="M4.0079058,28.6019994 C4.0079058,28.8536929 3.80573814,29.0587088 3.55733483,29.0587088 C3.30802494,29.0587088 3.10585729,28.8536929 3.10585729,28.6019994 C3.10585729,28.3493905 3.30802494,28.1452899 3.55733483,28.1452899 C3.80573814,28.1452899 4.0079058,28.3493905 4.0079058,28.6019994" />
          <path d="M35.5124261,25.1659703 C35.5124261,25.4176639 35.3102584,25.6217645 35.0618551,25.6217645 C34.8125452,25.6217645 34.6112841,25.4176639 34.6112841,25.1659703 C34.6112841,24.9142767 34.8125452,24.7092609 35.0618551,24.7092609 C35.3102584,24.7092609 35.5124261,24.9142767 35.5124261,25.1659703" />
          <path d="M30.3728347,28.3104009 C30.3728347,28.5620945 30.1715736,28.7671103 29.9213571,28.7671103 C29.6729538,28.7671103 29.4707862,28.5620945 29.4707862,28.3104009 C29.4707862,28.0577921 29.6729538,27.8536915 29.9213571,27.8536915 C30.1715736,27.8536915 30.3728347,28.0577921 30.3728347,28.3104009" />
          <path d="M7.30632098,26.6096846 C7.30632098,26.8613782 7.10415332,27.066394 6.85575001,27.066394 C6.60734671,27.066394 6.40517905,26.8613782 6.40517905,26.6096846 C6.40517905,26.357991 6.60734671,26.1520599 6.85575001,26.1520599 C7.10415332,26.1520599 7.30632098,26.357991 7.30632098,26.6096846" />
          <path d="M7.73848834,30.7600658 C7.73848834,31.0126746 7.53632069,31.2167752 7.28791738,31.2167752 C7.03860749,31.2167752 6.83643983,31.0126746 6.83643983,30.7600658 C6.83643983,30.5074569 7.03860749,30.3033563 7.28791738,30.3033563 C7.53632069,30.3033563 7.73848834,30.5074569 7.73848834,30.7600658" />
          <path d="M24.8256443,33.4457734 C24.8256443,33.7880767 24.5518567,34.064482 24.2146084,34.064482 C23.8773601,34.064482 23.6044791,33.7880767 23.6044791,33.4457734 C23.6044791,33.1043854 23.8773601,32.8270649 24.2146084,32.8270649 C24.5518567,32.8270649 24.8256443,33.1043854 24.8256443,33.4457734" />
          <path d="M25.6495455,5.96367314 C25.6495455,6.3041459 25.3766645,6.58146645 25.0394162,6.58146645 C24.7021679,6.58146645 24.4292869,6.3041459 24.4292869,5.96367314 C24.4292869,5.62228514 24.7021679,5.34496459 25.0394162,5.34496459 C25.3766645,5.34496459 25.6495455,5.62228514 25.6495455,5.96367314" />
          <path d="M19.9393518,31.8369481 C19.9393518,32.1792514 19.6655642,32.4556567 19.3292225,32.4556567 C18.9919742,32.4556567 18.7190932,32.1792514 18.7190932,31.8369481 C18.7190932,31.4955601 18.9919742,31.2191548 19.3292225,31.2191548 C19.6655642,31.2191548 19.9393518,31.4955601 19.9393518,31.8369481" />
          <path d="M29.2291822,29.6840071 C29.2291822,30.0244799 28.9563012,30.3018004 28.6190529,30.3018004 C28.2818046,30.3018004 28.0089236,30.0244799 28.0089236,29.6840071 C28.0089236,29.3426191 28.2818046,29.0643833 28.6190529,29.0643833 C28.9563012,29.0643833 29.2291822,29.3426191 29.2291822,29.6840071" />
          <path d="M8.39784501,29.4033001 C8.39784501,29.7446881 8.12405742,30.0210934 7.78680913,30.0210934 C7.45046743,30.0210934 7.17667984,29.7446881 7.17667984,29.4033001 C7.17667984,29.0619121 7.45046743,28.7845916 7.78680913,28.7845916 C8.12405742,28.7845916 8.39784501,29.0619121 8.39784501,29.4033001" />
          <path d="M24.5200357,29.0585257 C24.5200357,29.3999137 24.2462481,29.6772343 23.9089998,29.6772343 C23.5717515,29.6772343 23.2988705,29.3999137 23.2988705,29.0585257 C23.2988705,28.7162225 23.5717515,28.4398172 23.9089998,28.4398172 C24.2462481,28.4398172 24.5200357,28.7162225 24.5200357,29.0585257" />
          <path d="M6.90443344,22.6527871 C6.90443344,22.9374297 6.6768815,23.1680725 6.39493468,23.1680725 C6.11480102,23.1680725 5.88724908,22.9374297 5.88724908,22.6527871 C5.88724908,22.3672294 6.11480102,22.1365865 6.39493468,22.1365865 C6.6768815,22.1365865 6.90443344,22.3672294 6.90443344,22.6527871" />
          <path d="M30.9396294,13.8189836 C30.9396294,14.1036262 30.7120775,14.3351842 30.4310372,14.3351842 C30.149997,14.3351842 29.9215385,14.1036262 29.9215385,13.8189836 C29.9215385,13.5343411 30.149997,13.3036982 30.4310372,13.3036982 C30.7120775,13.3036982 30.9396294,13.5343411 30.9396294,13.8189836" />
          <path d="M20.7389566,4.82025215 C20.7389566,5.10489469 20.5114046,5.33645277 20.2294578,5.33645277 C19.9493241,5.33645277 19.7208656,5.10489469 19.7208656,4.82025215 C19.7208656,4.53560961 19.9493241,4.30496677 20.2294578,4.30496677 C20.5114046,4.30496677 20.7389566,4.53560961 20.7389566,4.82025215" />
          <path d="M15.3481515,30.7600658 C15.3481515,31.0437931 15.1205995,31.2762664 14.8386527,31.2762664 C14.5585191,31.2762664 14.3309671,31.0437931 14.3309671,30.7600658 C14.3309671,30.474508 14.5585191,30.2438651 14.8386527,30.2438651 C15.1205995,30.2438651 15.3481515,30.474508 15.3481515,30.7600658" />
          <path d="M3.00305095,12.4208487 C3.00305095,12.7054913 2.77459244,12.9361341 2.4935522,12.9361341 C2.21341854,12.9361341 1.98496002,12.7054913 1.98496002,12.4208487 C1.98496002,12.1362062 2.21341854,11.9055634 2.4935522,11.9055634 C2.77459244,11.9055634 3.00305095,12.1362062 3.00305095,12.4208487" />
          <path d="M12.1880806,8.38899236 C12.1880806,8.6736349 11.9605287,8.90519298 11.6794884,8.90519298 C11.3984482,8.90519298 11.1708963,8.6736349 11.1708963,8.38899236 C11.1708963,8.10434981 11.3984482,7.87370698 11.6794884,7.87370698 C11.9605287,7.87370698 12.1880806,8.10434981 12.1880806,8.38899236" />
          <path d="M20.5970766,3.09464105 C20.5970766,3.43602905 20.323289,3.7133496 19.9860407,3.7133496 C19.649699,3.7133496 19.3759114,3.43602905 19.3759114,3.09464105 C19.3759114,2.75325305 19.649699,2.4759325 19.9860407,2.4759325 C20.323289,2.4759325 20.5970766,2.75325305 20.5970766,3.09464105" />
          <path d="M19.1994907,30.1312895 C19.1994907,30.4735927 18.9257031,30.749998 18.5884548,30.749998 C18.2521131,30.749998 17.9783255,30.4735927 17.9783255,30.1312895 C17.9783255,29.7899015 18.2521131,29.5125809 18.5884548,29.5125809 C18.9257031,29.5125809 19.1994907,29.7899015 19.1994907,30.1312895" />
          <path d="M7.14485883,6.37764042 C7.14485883,6.71994367 6.87107124,6.99634897 6.53382295,6.99634897 C6.19748125,6.99634897 5.92369366,6.71994367 5.92369366,6.37764042 C5.92369366,6.03625242 6.19748125,5.75893187 6.53382295,5.75893187 C6.87107124,5.75893187 7.14485883,6.03625242 7.14485883,6.37764042" />
          <path d="M13.4206687,34.0645735 C13.4206687,34.4059615 13.1477877,34.683282 12.8105394,34.683282 C12.4732911,34.683282 12.1995035,34.4059615 12.1995035,34.0645735 C12.1995035,33.7222702 12.4732911,33.4458649 12.8105394,33.4458649 C13.1477877,33.4458649 13.4206687,33.7222702 13.4206687,34.0645735" />
          <path d="M19.3293131,4.57395855 C19.3293131,5.05080346 18.9476423,5.43703868 18.4771266,5.43703868 C18.0075174,5.43703868 17.6258466,5.05080346 17.6258466,4.57395855 C17.6258466,4.09711365 18.0075174,3.71179368 18.4771266,3.71179368 C18.9476423,3.71179368 19.3293131,4.09711365 19.3293131,4.57395855" />
          <path d="M26.7431547,3.95753813 C26.7431547,4.43346778 26.3614839,4.82061825 25.8909681,4.82061825 C25.4204524,4.82061825 25.0396882,4.43346778 25.0396882,3.95753813 C25.0396882,3.48069322 25.4204524,3.094458 25.8909681,3.094458 C26.3614839,3.094458 26.7431547,3.48069322 26.7431547,3.95753813" />
          <path d="M2.09130202,20.2062341 C2.09130202,20.6821638 1.70963124,21.0693143 1.24002207,21.0693143 C0.769506309,21.0693143 0.387835532,20.6821638 0.387835532,20.2062341 C0.387835532,19.7293892 0.769506309,19.343154 1.24002207,19.343154 C1.70963124,19.343154 2.09130202,19.7293892 2.09130202,20.2062341" />
          <path d="M24.5200357,31.0531286 C24.5200357,31.5308887 24.1383649,31.9162087 23.6687557,31.9162087 C23.19824,31.9162087 22.8165692,31.5308887 22.8165692,31.0531286 C22.8165692,30.5771989 23.19824,30.1909637 23.6687557,30.1909637 C24.1383649,30.1909637 24.5200357,30.5771989 24.5200357,31.0531286" />
          <path d="M37.888304,19.1130603 C37.888304,19.7171249 37.4050961,20.2067833 36.8094721,20.2067833 C36.2138481,20.2067833 35.7306402,19.7171249 35.7306402,19.1130603 C35.7306402,18.5089958 36.2138481,18.0193374 36.8094721,18.0193374 C37.4050961,18.0193374 37.888304,18.5089958 37.888304,19.1130603" />
          <path d="M29.3784962,25.1659703 C29.3784962,25.7700349 28.8952883,26.2596933 28.2987577,26.2596933 C27.7022271,26.2596933 27.2199258,25.7700349 27.2199258,25.1659703 C27.2199258,24.5609905 27.7022271,24.0731626 28.2987577,24.0731626 C28.8952883,24.0731626 29.3784962,24.5609905 29.3784962,25.1659703" />
          <path d="M11.1091581,29.4033001 C11.1091581,30.0073647 10.6259502,30.4970231 10.0303262,30.4970231 C9.43470218,30.4970231 8.9505877,30.0073647 8.9505877,29.4033001 C8.9505877,28.7992356 9.43470218,28.3104924 10.0303262,28.3104924 C10.6259502,28.3104924 11.1091581,28.7992356 11.1091581,29.4033001" />
          <path d="M24.2148804,7.63418623 C24.2148804,8.95855202 23.1559933,10.0321395 21.8487029,10.0321395 C20.5414125,10.0321395 19.483432,8.95855202 19.483432,7.63418623 C19.483432,6.30982044 20.5414125,5.23714822 21.8487029,5.23714822 C23.1559933,5.23714822 24.2148804,6.30982044 24.2148804,7.63418623" />
          <path d="M30.429224,33.4457734 C30.429224,34.8891216 29.2733327,36.0606408 27.8481867,36.0606408 C26.4230408,36.0606408 25.2671494,34.8891216 25.2671494,33.4457734 C25.2671494,32.00151 26.4230408,30.8318213 27.8481867,30.8318213 C29.2733327,30.8318213 30.429224,32.00151 30.429224,33.4457734" />
          <path d="M11.7207379,25.622039 C11.7207379,26.7862362 10.7896788,27.7298583 9.64104011,27.7298583 C8.49330803,27.7298583 7.56134233,26.7862362 7.56134233,25.622039 C7.56134233,24.4596724 8.49330803,23.5160503 9.64104011,23.5160503 C10.7896788,23.5160503 11.7207379,24.4596724 11.7207379,25.622039" />
          <path d="M34.1643395,25.3222034 C34.1643395,26.6300947 33.117238,27.6917839 31.8253594,27.6917839 C30.5343875,27.6917839 29.487286,26.6300947 29.487286,25.3222034 C29.487286,24.0124816 30.5343875,22.9526228 31.8253594,22.9526228 C33.117238,22.9526228 34.1643395,24.0124816 34.1643395,25.3222034" />
          <path d="M11.6794884,33.4457734 C11.6794884,34.7536647 10.6323869,35.8153539 9.34050837,35.8153539 C8.04862985,35.8153539 7.00152831,34.7536647 7.00152831,33.4457734 C7.00152831,32.1369669 8.04862985,31.0761929 9.34050837,31.0761929 C10.6323869,31.0761929 11.6794884,32.1369669 11.6794884,33.4457734" />
        </g>
        <path d="M3.57900213,3.79700339 L2.7703315,1.36335541 L2.75491962,1.36335541 L1.93083711,3.79700339 L3.57900213,3.79700339 Z M2.297096,0.0902435845 L3.28255001,0.0902435845 L5.38219257,6.11807571 L4.35684899,6.11807571 L3.87726742,4.69028674 L1.62622575,4.69028674 L1.13757837,6.11807571 L0.201079759,6.11807571 L2.297096,0.0902435845 Z" />
        <polygon points="7.95588657 0.112392618 8.97488408 0.112392618 11.7780338 4.39667476 11.7934457 4.39667476 11.7934457 0.112392618 12.7217851 0.112392618 12.7217851 6.11825876 11.8088576 6.11825876 8.90145099 1.74428218 8.8860391 1.74428218 8.8860391 6.11825876 7.95588657 6.11825876" />
        <polygon points="19.5468927 6.11862486 20.5568244 6.11862486 20.5568244 0.112758718 19.5468927 0.112758718" />
        <polygon points="23.6692997 0.112392618 24.6873906 0.112392618 27.491447 4.39667476 27.5068588 4.39667476 27.5068588 0.112392618 28.4351982 0.112392618 28.4351982 6.11825876 27.5222707 6.11825876 24.6148641 1.74428218 24.5994522 1.74428218 24.5994522 6.11825876 23.6692997 6.11825876" />
        <polygon points="31.5482175 6.11862486 32.5554295 6.11862486 32.5554295 0.112758718 31.5482175 0.112758718" />
        <polygon points="37.0384746 1.05482486 35.2135262 1.05482486 35.2135262 0.112118044 39.8670086 0.112118044 39.8670086 1.05482486 38.0393405 1.05482486 38.0393405 6.11798418 37.0384746 6.11798418" />
        <polygon points="42.5205724 6.11862486 43.5305041 6.11862486 43.5305041 0.112758718 42.5205724 0.112758718" />
        <path d="M49.5504767,3.79700339 L48.7418061,1.36335541 L48.7263942,1.36335541 L47.9023117,3.79700339 L49.5504767,3.79700339 Z M48.2694772,0.0902435845 L49.2540246,0.0902435845 L51.3536672,6.11807571 L50.3283236,6.11807571 L49.8469289,4.69028674 L47.5977003,4.69028674 L47.109053,6.11807571 L46.1725544,6.11807571 L48.2694772,0.0902435845 Z" />
        <polygon points="54.5769268 1.05482486 52.7519784 1.05482486 52.7519784 0.112118044 57.4054608 0.112118044 57.4054608 1.05482486 55.5777926 1.05482486 55.5777926 6.11798418 54.5769268 6.11798418" />
        <polygon points="60.05839 6.11862486 61.0683217 6.11862486 61.0683217 0.112758718 60.05839 0.112758718" />
        <polygon points="63.7107346 0.112392618 64.7270123 0.112392618 66.2555086 4.79572347 66.2736402 4.79572347 67.8266142 0.112392618 68.7721786 0.112392618 66.7133322 6.14297049 65.7677678 6.14297049" />
        <polygon points="71.3487737 0.112392618 75.1754539 0.112392618 75.1754539 1.05509943 72.3496396 1.05509943 72.3496396 2.5478711 74.4238979 2.5478711 74.4238979 3.4475612 72.3496396 3.4475612 72.3496396 5.19385693 75.3368253 5.19385693 75.3368253 6.11825876 71.3487737 6.11825876" />
        <path d="M85.7895278,3.11642399 C85.7895278,1.70419426 85.124097,0.933554322 84.1304838,0.933554322 C83.1223653,0.933554322 82.4578411,1.70419426 82.4578411,3.11642399 C82.4578411,4.52682321 83.1223653,5.31485289 84.1304838,5.31485289 C85.124097,5.31485289 85.7895278,4.52682321 85.7895278,3.11642399 M81.4479094,3.11642399 C81.4479094,1.0946382 82.6255586,0 84.1304838,0 C85.6281563,0 86.7967398,1.0946382 86.7967398,3.11642399 C86.7967398,5.13546402 85.6281563,6.23284797 84.1304838,6.23284797 C82.6255586,6.23284797 81.4479094,5.13546402 81.4479094,3.11642399" />
        <polygon points="89.5588214 0.112392618 93.2903105 0.112392618 93.2903105 1.05509943 90.5596873 1.05509943 90.5596873 2.66227727 92.5369413 2.66227727 92.5369413 3.56196736 90.5596873 3.56196736 90.5596873 6.11825876 89.5588214 6.11825876" />
      </g>
    </g>
  </IconSvg>
);

SOTFull.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};

SOTFull.defaultProps = {
  color: styling.colors.theme.pureWhite,
  height: 46,
  width: 251,
};

export default SOTFull;
