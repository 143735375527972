import PropTypes from 'prop-types';
import clsx from 'clsx';
import { generatePath } from 'react-router';

import routes from '../../../services/constants/routes';
import useCarouselSelection from './hooks/useCarouselSelection';
import useStyles from './carousel.styles';
import { calculateOffset, getNavigationBarPath } from './logic';
import MissionTile from './MissionTile';
import NavigationBar from './NavigationBar';

const Carousel = ({
  canSelect,
  missions,
  initialIndex,
  withBackground,
  includeMissionHrefPrefix,
}) => {
  const styles = useStyles();

  const { next, prev, selection, bindDrag, dragApi, slug } =
    useCarouselSelection(missions, initialIndex);

  return (
    <>
      <div className={clsx('full-bleed', styles.wrapper)}>
        {withBackground && <div className={styles.background} />}
        <div className={styles.carouselWrapper} {...bindDrag()}>
          <div className={styles.center}>
            {missions.map((mission, i) => (
              <MissionTile
                key={i}
                asset={mission.asset}
                blurb={mission.about}
                iconAnimation={mission.iconAnimation}
                numMissions={missions.length}
                offset={calculateOffset(i, selection, missions.length)}
                dragApi={dragApi}
                title={mission.title}
              />
            ))}
          </div>
        </div>
      </div>
      <NavigationBar
        canSelect={canSelect}
        handleNext={next}
        handlePrev={prev}
        href={getNavigationBarPath(
          generatePath,
          routes.mission.childRoutes.missionSelected.fullPath,
          slug,
        )}
        includeHrefPrefix={includeMissionHrefPrefix}
      />
    </>
  );
};

Carousel.propTypes = {
  canSelect: PropTypes.bool,
  missions: PropTypes.arrayOf(PropTypes.object).isRequired,
  initialIndex: PropTypes.number.isRequired,
  withBackground: PropTypes.bool,
  includeMissionHrefPrefix: PropTypes.bool,
};

Carousel.defaultProps = {
  canSelect: true,
  withBackground: false,
  includeMissionHrefPrefix: false,
};

export default Carousel;
