import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import useInternalLink from './hooks/useInternalLink';

const InternalLink = forwardRef(({ children, to, ...rest }, ref) => {
  const appendedTo = useInternalLink(to);

  return (
    <Link {...rest} to={appendedTo} ref={ref}>
      {children}
    </Link>
  );
});

InternalLink.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
};

export default InternalLink;
