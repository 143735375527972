import LoadWhenInView from '../../../LoadWhenInView';
import MediaPlayer from '../../../MediaPlayer';
import useFooterVideo from './hooks/useFooterVideo';
import useStyles from './footerVideo.styles';

const FooterVideo = () => {
  const styles = useStyles();
  const { isDesktop, initialPlayerState, playerSources } = useFooterVideo();

  return (
    <div className={styles.wrapper}>
      <LoadWhenInView>
        <MediaPlayer
          key={isDesktop}
          className={styles.video}
          initialState={initialPlayerState}
          sources={playerSources}
          loop
          withOverlay={false}
        />
      </LoadWhenInView>
    </div>
  );
};

export default FooterVideo;
