import PropTypes from 'prop-types';
import clsx from 'clsx';

import styling from '../../../../services/styling';
import { CheckIcon } from '../../../Icons';
import useStyles from './checkbox.styles';

const Checkbox = ({ checked, disabled, isErrored, isFocused, onClick }) => {
  const styles = useStyles();

  return (
    <div
      className={clsx(
        styles.wrapper,
        isFocused && styles.focused,
        disabled && styles.disabled,
        isErrored && styles.errored,
      )}
      onClick={!disabled ? onClick : undefined}
    >
      {checked && (
        <CheckIcon
          className={styles.check}
          color={styling.colors.theme.primaryLight}
        />
      )}
    </div>
  );
};

Checkbox.propTypes = {
  checked: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isErrored: PropTypes.bool,
  isFocused: PropTypes.bool,
};

Checkbox.defaultProps = {
  checked: false,
  disabled: false,
  isErrored: false,
  isFocused: false,
};

export default Checkbox;
