import { createContext, useMemo } from 'react';
import PropTypes from 'prop-types';

import usePanelManagement from '../hooks/usePanelManagement';

export const PanelContext = createContext({
  closePanel: () => {},
  deregisterPanel: () => {},
  openedPanel: undefined,
  openPanel: () => {},
  panels: [],
  registerPanel: () => {},
});

export const PanelProvider = ({ children }) => {
  const value = usePanelManagement();

  return (
    <PanelContext.Provider value={value}>{children}</PanelContext.Provider>
  );
};

PanelProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
