import { createRef } from 'react';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';

const defaultStyle = (duration) => ({
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
});

const transitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

const Fade = ({
  className,
  durationIn,
  durationOut,
  in: inProp,
  children,
  ...rest
}) => {
  const transitionChildRef = createRef();
  return (
    <Transition
      in={inProp}
      nodeRef={transitionChildRef}
      timeout={{
        enter: durationIn,
        exit: durationOut,
      }}
      unmountOnExit
      {...rest}
    >
      {(state) => (
        <div
          ref={transitionChildRef}
          className={className}
          style={{
            ...defaultStyle(inProp ? durationIn : durationOut),
            ...transitionStyles[state],
          }}
        >
          {children}
        </div>
      )}
    </Transition>
  );
};

Fade.propTypes = {
  className: PropTypes.string,
  durationIn: PropTypes.number,
  durationOut: PropTypes.number,
  children: PropTypes.node.isRequired,
  in: PropTypes.bool.isRequired,
};

Fade.defaultProps = {
  className: undefined,
  durationIn: 1000,
  durationOut: 1000,
};

export default Fade;
