import { generateGAPageEvent } from '../../hooks/useAnalyticsManagement/logic';
import { htmlDecode } from '../../services/helpers/string';

export const onSetPageTitle = (setPageTitle, title, mergeProps) => {
  if (typeof title === 'string') {
    setPageTitle(`${htmlDecode(title)} - The Conspiracy Test`);
  }
  if (typeof title === 'function') {
    setPageTitle(`${htmlDecode(title(mergeProps))} - The Conspiracy Test`);
  }
};

export const handleTrackPageTitleEvent = (trackEvent, pageTitle, path) => {
  trackEvent(generateGAPageEvent(path, pageTitle));
};
