import PropTypes from 'prop-types';
import clsx from 'clsx';

import useStyles from './textSwish.styles';

const TextSwish = ({ isCentered }) => {
  const styles = useStyles();
  return <div className={clsx(styles.swish, isCentered && styles.centered)} />;
};

TextSwish.propTypes = {
  isCentered: PropTypes.bool,
};

TextSwish.defaultProps = {
  isCentered: false,
};

export default TextSwish;
