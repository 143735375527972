/* eslint-disable react/no-unknown-property */
/* eslint no-undefined: "off" */
import { memo, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass';
import { extend, useFrame, useThree } from '@react-three/fiber';

import customGlow from './shaders/customGlow';

extend({ EffectComposer, RenderPass, ShaderPass });

function Outerglow({ children }) {
  const { gl, camera, size } = useThree();
  const [scene, setScene] = useState();
  const composer = useRef();

  useEffect(() => {
    if (composer.current) {
      composer.current.setSize(size.width, size.height);
    }
  }, [size]);

  useFrame(() => scene && composer.current.render(), 1);

  return (
    <>
      <scene ref={setScene}>{children}</scene>
      <effectComposer ref={composer} args={[gl]}>
        <renderPass attachArray="passes" camera={camera} scene={scene} />
        <shaderPass args={[customGlow]} attachArray="passes" />
      </effectComposer>
    </>
  );
}

Outerglow.propTypes = {
  children: PropTypes.node.isRequired,
};

export default memo(Outerglow);
