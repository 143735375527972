const routes = {
  home: {
    path: '/',
    title: 'Home',
    priority: 1,
  },
  about: {
    path: '/about',
    title: 'About',
    priority: 0.8,
  },
  mission: {
    path: '/mission',
    title: 'Mission',
    priority: 0.9,
    childRoutes: {
      missionIndex: {
        index: true,
        title: 'Mission',
      },
      userShareShort: {
        path: 'share/:attemptUuid',
        fullPath: '/mission/share/:attemptUuid',
        title: 'Share your results',
      },
      userShare: {
        path: 'share/:attemptUuid/:missionSlug/:name',
        fullPath: '/mission/share/:attemptUuid/:missionSlug/:name',
        title: ({ name, mission }) => `${name}'s ${mission.title} results`,
      },
      missionSelected: {
        path: ':missionSlug',
        fullPath: '/mission/:missionSlug',
        title: ({ mission }) => `${mission.title} - Mission`,
      },
    },
  },
  stats: {
    path: '/stats',
    title: 'Stats',
    priority: 0.8,
  },
  share: {
    path: '/share',
    title: 'Share',
    priority: 0.5,
  },
  faqs: {
    path: '/faqs',
    title: 'FAQs',
    priority: 0.5,
  },
  media: {
    path: '/media',
    title: 'Media',
    priority: 0.4,
  },
  contact: {
    path: '/contact',
    title: 'Contact',
    priority: 0.4,
  },
  legals: {
    path: '/legals',
    title: 'Legals',
    priority: 0.4,
  },
  legalsTab: {
    path: '/legals/:tabSlug',
    title: 'Legals',
    priority: 0,
  },
  fourOhFour: {
    path: '*',
    title: '404',
    priority: 0,
  },
};

export default routes;
