import { useCallback, useEffect, useRef, useState } from 'react';
import { v4 } from 'uuid';

import useGlobalContent from '../../../../hooks/useGlobalContent';
import {
  handleOnExecuteCaptcha,
  handleOnExpiredCallback,
  handleOnMount,
  handleOnVerifyCallback,
} from '../logic';

const useCaptcha = (registerExecuteCaptcha, onVerify) => {
  const {
    content: { publicKeys },
  } = useGlobalContent();
  const elementID = useRef(`cap-g-${v4()}`);
  const elementRef = useRef();
  const recaptchaRef = useRef();
  const widgetRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);

  const setRecaptchaRef = useCallback((ref) => {
    recaptchaRef.current = ref;
  }, []);

  const setWidgetRef = useCallback((ref) => {
    widgetRef.current = ref;
  }, []);

  const onExpiredCallback = useCallback(
    () => handleOnExpiredCallback(recaptchaRef?.current, widgetRef?.current),
    [],
  );

  const onVerifyCallback = useCallback(
    (response) =>
      handleOnVerifyCallback(
        response,
        onVerify,
        recaptchaRef?.current,
        widgetRef?.current,
      ),
    [],
  );

  const onExecuteCaptcha = useCallback(
    () => handleOnExecuteCaptcha(recaptchaRef?.current, widgetRef?.current),
    [recaptchaRef?.current, widgetRef?.current],
  );

  useEffect(() => {
    registerExecuteCaptcha(onExecuteCaptcha);
  }, [registerExecuteCaptcha, onExecuteCaptcha]);

  useEffect(() => {
    const onMount = async () => {
      await handleOnMount({
        scriptUrl: 'https://www.google.com/recaptcha/api.js',
        elementID: elementID.current,
        setRecaptchaRef,
        sitekey: publicKeys?.recaptcha,
        setWidgetRef,
        onExpiredCallback,
        onVerifyCallback,
        setIsLoading,
      });
    };
    if (publicKeys?.recaptcha) {
      onMount();
    }
  }, [publicKeys?.recaptcha]);

  return {
    elementID: elementID.current,
    elementRef,
  };
};

export default useCaptcha;
