import PropTypes from 'prop-types';

import useLottie from './hooks/useLottie';

const Lottie = ({ className, animationData, width, height }) => {
  const { elementRef, style } = useLottie(animationData, width, height);

  return <div className={className} style={style} ref={elementRef} />;
};

Lottie.propTypes = {
  className: PropTypes.string,
  animationData: PropTypes.any.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};

Lottie.defaultProps = {
  className: undefined,
};

export default Lottie;
