export const handleOnMount = (initializeFn, implementationId, setEnabled) => {
  if (initializeFn && implementationId) {
    initializeFn(implementationId);
    setEnabled(true);
  }
};

export const handleTrackEvent = (isEnabled, trackEventFn, eventDetails) => {
  if (!isEnabled) return;
  trackEventFn(eventDetails);
};

export const generateGAPageEvent = (path, title) => ({
  hitType: 'pageview',
  page: path,
  title,
});
