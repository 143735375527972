import { createUseStyles } from 'react-jss';

import { getVariants } from './logic';

export default createUseStyles(
  {
    ...getVariants(),
    withHoverShadow: {},
    withShadow: {},
  },
  { name: 'typography' },
);
