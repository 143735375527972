import { createUseStyles } from 'react-jss';

import styling from '../../services/styling';
import { sizeMapping } from './constants';

export default createUseStyles(
  {
    '@keyframes colorPulsePrimary': {
      '0%,100%': {
        color: styling.colors.theme.primary,
      },
      '50%': {
        color: styling.colors.context.text,
      },
    },
    '@keyframes colorPulseSecondary': {
      '0%,100%': {
        color: styling.colors.theme.secondary,
      },
      '50%': {
        color: styling.colors.context.text,
      },
    },
    button: {
      position: 'relative',
      fontFamily: styling.fonts.barlowCondensed.fontFamily,
      backgroundColor: styling.colors.theme.pureBlackFaded,
      cursor: 'pointer',
      color: styling.colors.context.text,
      borderStyle: 'solid',
      transform: 'skewX(-45deg)',
      textTransform: 'uppercase',
      textAlign: 'center',
      transition: `color ${styling.speeds.skip} ease-in-out`,
      '& > div': {
        position: 'relative',
        zIndex: 2,
        transform: 'skewX(45deg)',
      },
      // '&:hover': {
      // animationIterationCount: 'infinite',
      // animationDuration: styling.speeds.crawl,
      // animationFillMode: 'forwards',
      // animationTimingFunction: 'ease-in-out',
      // },
    },
    // See https://github.com/weareflip/theconspiracytest-web/pull/3
    // for reason as to why we need to use this workaround
    // TLDR; functional styles with media queries dont work good
    'size-md': {
      ...sizeMapping.md.styles,
    },
    'size-mdFixed': {
      ...sizeMapping.mdFixed.styles,
    },
    'size-lg': {
      ...sizeMapping.lg.styles,
    },
    'variant-primary': {
      borderColor: styling.colors.theme.primaryLight,
      boxShadow: `${styling.shadows.utils.boxShadowToString(
        styling.shadows.theme.primarySmFaded,
      )}, ${styling.shadows.utils.boxShadowToString(
        styling.shadows.theme.primarySmInsetFaded,
      )}`,
      textShadow: styling.shadows.theme.primaryXs,
      '&:hover': {
        color: styling.colors.theme.primary,
      },
      '&$size-lg': {
        textShadow: styling.shadows.theme.primaryLg,
      },
    },
    'variant-secondary': {
      borderColor: styling.colors.theme.secondary,
      boxShadow: `${styling.shadows.utils.boxShadowToString(
        styling.shadows.theme.secondarySmFaded,
      )}, ${styling.shadows.utils.boxShadowToString(
        styling.shadows.theme.secondarySmInsetFaded,
      )}`,
      textShadow: styling.shadows.theme.secondaryXs,
      // animationName: '$colorPulseSecondary',
      '&:hover': {
        color: styling.colors.theme.secondary,
      },
      '&$size-lg': {
        textShadow: styling.shadows.theme.secondaryLg,
      },
    },
    disabled: {
      cursor: 'not-allowed',
      opacity: 0.33,
      '&:hover': {
        color: 'inherit',
      },
    },
  },
  { name: 'slantedButton' },
);
