import { useCallback, useEffect, useState } from 'react';

import {
  getCloseAlert,
  getDeregisterAlert,
  getRegisterAlert,
  handleAlertsChange,
} from './logic';

const useAlertManagement = () => {
  const [topAlert, setTopAlert] = useState();
  const [alerts, setAlerts] = useState([]);

  const closeAlert = useCallback(getCloseAlert(setTopAlert), [setTopAlert]);

  const registerAlert = useCallback(getRegisterAlert(setAlerts), [
    alerts,
    setAlerts,
  ]);

  const deregisterAlert = useCallback(getDeregisterAlert(setAlerts), [
    setAlerts,
  ]);

  useEffect(
    () => handleAlertsChange(alerts, setTopAlert),
    [alerts, setTopAlert],
  );

  return {
    registerAlert,
    closeAlert,
    deregisterAlert,
    topAlert,
    alerts,
  };
};

export default useAlertManagement;
