import { createUseStyles } from 'react-jss';

import styling from '../../../services/styling';

export default createUseStyles(
  {
    wrapper: {
      display: 'flex',
      gap: 25,
      justifyContent: 'center',
      flexDirection: 'column',
      maxWidth: 320,
      margin: [47, 'auto', 0],
      [styling.breakpoints.up('md')]: {
        flexDirection: 'row',
      },
    },
    button: {
      width: '100%',
    },
  },
  { name: 'alertButtons' },
);
