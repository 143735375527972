import {
  hasMaxLength,
  isRequired,
  isValidEmail,
} from '../../../../services/helpers/validators';
import SubmitButton from './SubmitButton';

export const dataSchema = {
  fields: {
    email: {
      type: 'email',
      validation: [isRequired, isValidEmail, hasMaxLength(128)],
    },
  },
  form: {
    hasCaptcha: true,
    validationStrategy: 'onBlur',
  },
};

export const layoutSchema = {
  fields: [
    {
      key: 'email',
      label: 'Email address',
    },
  ],
  form: {
    submitButton: SubmitButton,
  },
};
