// taken from https://stackoverflow.com/a/201378/648350
// referencing http://emailregex.com/
const validEmailRegex =
  // eslint-disable-next-line no-control-regex
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

export const isRequired = (value, field = {}) => {
  if (value === '' || value === null || value === undefined) {
    return 'This is required';
  }
  if (field?.type === 'checkbox' && !value) {
    return 'This is required';
  }
  return undefined;
};

export const hasMinLength =
  (minLength) =>
  (value, field = {}) => {
    const isValid = value?.length >= minLength;

    if (isValid) return undefined;
    return `This cannot have fewer than ${minLength} characters`;
  };

export const hasMaxLength =
  (maxLength) =>
  (value, field = {}) => {
    const isValid = value?.length <= maxLength;

    if (isValid) return undefined;
    return `This cannot have more than ${maxLength} characters`;
  };

export const isValidEmail = (value, field = {}) => {
  const isValid = validEmailRegex.test(value);

  if (isValid) return undefined;
  return 'This email is not valid';
};

export const minNumber =
  (minValue, allowEqual = true) =>
  (value) => {
    if (value > minValue) return undefined;
    if (allowEqual && value === minValue) return undefined;

    if (allowEqual) {
      return `Must be ${minValue} or more`;
    }
    return `Must be more than ${minValue}`;
  };

export const maxNumber =
  (maxValue, allowEqual = true) =>
  (value) => {
    if (value < maxValue) return undefined;
    if (allowEqual && value === maxValue) return undefined;

    if (allowEqual) {
      return `Must be ${maxValue} or less`;
    }
    return `Must be less than ${maxValue}`;
  };
