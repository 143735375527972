import { createContext, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';

import useAttemptManagement from '../hooks/useAttemptManagement';
import { PersistentStorageContext } from './persistentStorageContext';

export const AttemptContext = createContext({
  getAttemptData: () => ({}),
  beginAttempt: () => undefined,
  setAttemptAnswer: () => undefined,
  clearAttempt: () => undefined,
});

export const AttemptProvider = ({ children }) => {
  const { retrieveValue, storeValue } = useContext(PersistentStorageContext);
  const value = useAttemptManagement(retrieveValue, storeValue);

  return (
    <AttemptContext.Provider value={value}>{children}</AttemptContext.Provider>
  );
};

AttemptProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
