import {
  hasMaxLength,
  isRequired,
  isValidEmail,
} from '../../../../services/helpers/validators';

export const dataSchema = {
  fields: {
    name: {
      type: 'text',
      validation: [isRequired, hasMaxLength(64)],
    },
    email: {
      type: 'email',
      validation: [isRequired, isValidEmail, hasMaxLength(128)],
    },
  },
  form: {
    hasCaptcha: true,
    validationStrategy: 'onBlur',
  },
};

export const layoutSchema = {
  fields: [
    {
      key: 'name',
      label: 'Name',
    },
    {
      key: 'email',
      label: 'Email address',
    },
  ],
};
