import { createUseStyles } from 'react-jss';

export default createUseStyles(
  {
    '@global': {
      '.grecaptcha-badge': {
        position: [['fixed'], '!important'],
        top: [[-100], '!important'],
        right: [['101%'], '!important'],
      },
    },
  },
  { name: 'captcha' },
);
