import { createUseStyles } from 'react-jss';

import styling from '../../../../services/styling';

export default createUseStyles(
  {
    wrapper: {
      width: '100%',
      position: 'relative',
      minWidth: 128,
      maxWidth: 208,
      margin: [0, 'auto'],
    },
    graph: {
      width: '100%',
      height: 0,
      paddingBottom: '100%',
    },
    svg: {
      height: '100%',
      width: '100%',
      position: 'absolute',
      left: 0,
      top: 0,
    },
    line: {
      transform: 'rotate(-90deg)',
      transformOrigin: 'center center',
    },
    primaryLine: {
      filter: styling.shadows.utils.svgFilter({
        x: 0,
        y: 0,
        blur: 30,
        color: 'var(--statWheelPrimaryLineBlurColor)',
      }),
    },
    secondaryLine: {
      filter: styling.shadows.utils.svgFilter({
        x: 0,
        y: 0,
        blur: 30,
        color: styling.colors.theme.primaryLight,
      }),
    },
    children: {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
    },
  },
  { name: 'statWheel' },
);
