import PropTypes from 'prop-types';
import clsx from 'clsx';

import styling from '../../services/styling';
import ClippedImage from '../ClippedImage';
import CaretRight from '../Icons/CaretRight';
import Download from '../Icons/Download';
import Typography from '../Typography';
import DownloadButton from './DownloadButton';
import useStyles from './linkTiles.styles';

const LinkTile = ({
  shortImage,
  title,
  body,
  href,
  imgSrc,
  download,
  size,
}) => {
  const styles = useStyles();

  let titleVariant = 'tileTitle';
  if (size === 'lg' && !download) {
    titleVariant = 'tileDownloadTitle';
  }

  return (
    <div className={clsx(styles.wrapper, styles[`size-${size}`])}>
      <a
        href={href || download?.src}
        rel="noreferrer"
        target="_blank"
        download={download ? true : undefined}
      >
        <div className={styles.imageWrapper}>
          <ClippedImage
            className={clsx(styles.image, shortImage && styles.shortImage)}
            src={imgSrc}
            withRadialOverlay
          />
        </div>
        {title && (
          <Typography
            className={styles.title}
            variant={titleVariant}
            withShadow={titleVariant !== 'tileTitle'}
          >
            {title}
            {href && (
              <CaretRight
                className={styles.caret}
                color={styling.colors.theme.primaryLight}
              />
            )}
          </Typography>
        )}
        {size === 'lg' && body && (
          <Typography className={styles.body} variant="body">
            {body}
          </Typography>
        )}
        {download && (
          <DownloadButton
            className={styles.download}
            size={download?.size}
            title={download?.title}
            type={download?.type}
          />
        )}
      </a>
    </div>
  );
};

LinkTile.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  href: PropTypes.string,
  imgSrc: PropTypes.string.isRequired,
  shortImage: PropTypes.bool,
  download: PropTypes.shape({
    src: PropTypes.string,
    type: PropTypes.string,
    size: PropTypes.string,
    title: PropTypes.string,
  }),
  size: PropTypes.oneOf(['md', 'lg']),
};

LinkTile.defaultProps = {
  title: undefined,
  shortImage: false,
  href: undefined,
  body: undefined,
  download: undefined,
  size: 'md',
};

export default LinkTile;
