import { createUseStyles } from 'react-jss';

import styling from '../../../../../services/styling';

export default createUseStyles(
  {
    videoLoopInner: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 2,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden',
      pointerEvents: 'none',
    },
    videoCta: {
      position: 'absolute',
      left: '50%',
      top: '40%',
      transform: 'translate(-50%, -50%)',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 14,
      pointerEvents: 'none',
      zIndex: 5,
      '&:before': {
        content: '""',
        position: 'absolute',
        height: 400,
        width: 400,
        left: '50%',
        top: '50%',
        zIndex: -1,
        transform: 'translate(-50%, -50%)',
        borderRadius: '9999rem',
        opacity: 0.5,
        background: `radial-gradient(circle, ${styling.colors.theme.pureBlack} 0%, transparent 60%)`,
      },
      '& > svg': {
        filter: styling.shadows.utils.svgFilter(
          styling.shadows.theme.secondarySm,
        ),
      },
    },
  },
  { name: 'whyVideoInner' },
);
