import filterFalseyValues from '../../services/helpers/filterFalseyValues';
import log from '../../services/log';
import { formStates } from './constants';

export const generateFieldsFromDataAndLayout = (
  dataFieldsSchema,
  layoutFieldsSchema,
) => {
  const fields = layoutFieldsSchema.map((field) => {
    const mappedDataFieldSchema = dataFieldsSchema[field.key];
    if (!mappedDataFieldSchema) return null;

    return {
      ...field,
      ...mappedDataFieldSchema,
    };
  });
  return filterFalseyValues(fields);
};

export const getSubmitButton = (customSubmitButton, defaultSubmitButton) =>
  customSubmitButton || defaultSubmitButton;

export const handleClearFormData = (
  setFormData,
  initialValues,
  setFormState,
  bumpFormResetRef,
) => {
  setFormData(initialValues);
  setFormState(formStates.CLEAN);
  bumpFormResetRef();
};

export const handleFieldChange = (fieldKey, setFormData) => (newFieldValue) =>
  setFormData((currentValue) => ({
    ...currentValue,
    [fieldKey]: newFieldValue,
  }));

export const getWidthsByFieldKey = (layoutFields, fieldKey) => {
  const fieldLayout = layoutFields.find((field) => field.key === fieldKey);

  return fieldLayout?.widths;
};

export const validateFields = (fields, formData, setErrors) => {
  const errors = fields.reduce((acc, field) => {
    const validationResults = field.validation
      ?.map((validationFn) => validationFn(formData?.[field.key], field))
      .filter((validationResult) => !!validationResult);
    if (validationResults.length) {
      return {
        ...acc,
        [field.key]: validationResults,
      };
    }
    return acc;
  }, {});

  setErrors(errors);
};

export const handleFormErrorsChange = (formErrors, setFormState) => {
  if (Object.keys(formErrors).length) {
    setFormState(formStates.ERROR);
    return;
  }
  setFormState(formStates.CLEAN);
};

export const handleOnCaptchaVerified = (
  captchaResponse,
  onSubmit,
  onSubmitArgsRef,
) => {
  const [submittedFormData, callback] = onSubmitArgsRef;
  onSubmit(
    { 'g-recaptcha-response': captchaResponse, ...submittedFormData },
    callback,
  );
};

export const generateHandleOnSubmit = ({
  submittedFormData,
  callback,
  onSubmit,
  hasCaptcha,
  executeCaptchaRef,
  setOnSubmitArgsRef,
}) => {
  if (!hasCaptcha) {
    onSubmit(submittedFormData, callback);
    return;
  }
  if (!executeCaptchaRef) {
    // TODO: error toastr
    log.error('[FORM] captcha execute not registered');
    callback();
    return;
  }
  setOnSubmitArgsRef([submittedFormData, callback]);
  try {
    executeCaptchaRef();
  } catch (e) {
    callback();
  }
};
