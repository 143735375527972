import PropTypes from 'prop-types';

import useInput from './hooks/useInput';
import useStyles from './input.styles';
import InputLabel from './InputLabel';

const Input = ({ errors, label, value, onChange, type, ...rest }) => {
  const styles = useStyles();
  const {
    InputComponent,
    inputProps,
    touchedErrors,
    isFocused,
    handleOnFocus,
    handleOnBlur,
  } = useInput(type, errors);

  return (
    <div className={styles.wrapper}>
      <InputLabel
        isErrored={!!touchedErrors}
        label={label}
        htmlFor={rest.name}
      />
      <InputComponent
        {...rest}
        errors={touchedErrors}
        isFocused={isFocused}
        onBlur={handleOnBlur}
        onChange={onChange}
        onFocus={handleOnFocus}
        value={value}
        {...inputProps}
      />
    </div>
  );
};

Input.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string),
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  onChange: PropTypes.func.isRequired,
  type: PropTypes.oneOf([
    'email',
    'number',
    'text',
    'textarea',
    'checkbox',
    'select',
    'typeahead',
  ]).isRequired,
};

Input.defaultProps = {
  errors: undefined,
  label: undefined,
  value: undefined,
};

export default Input;
