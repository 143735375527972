import { createUseStyles } from 'react-jss';

export default createUseStyles(
  {
    wrapper: {
      '--formGridItemSpan': 'span 12',
      display: 'grid',
      gridTemplateColumns: 'repeat(12, 1fr)',
      gap: '0 30px',
      gridAutoRows: 'min-content',
    },
    item: {
      gridColumn: 'var(--formGridItemSpan)',
    },
  },
  { name: 'formGrid' },
);
