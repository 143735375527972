import { useCallback, useEffect, useRef, useState } from 'react';

import log from '../../../services/log';
import { PLAYER_STATE_EVENTS } from '../constants';
import {
  generateConfigFromTracks,
  handleAutoPlay,
  handleToggleMuted,
  handleTogglePlaying,
  handleToggleShowCC,
} from '../logic';

const useMediaPlayerControls = ({
  initialState,
  tracks,
  additionalConfig,
  onStateChange,
  onRegisterPlayer,
  onSkip,
  onComplete,
}) => {
  const playerRef = useRef();
  const [isReady, setIsReady] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [muted, setMuted] = useState(initialState.muted ?? false);
  const [showCC, setShowCC] = useState(muted);
  const [config, setConfig] = useState(
    generateConfigFromTracks(tracks, additionalConfig),
  );

  const fireStateChangeEvent = useCallback(
    (event, value) => {
      onStateChange(event, value);
    },
    [onStateChange],
  );

  useEffect(() => {
    fireStateChangeEvent(PLAYER_STATE_EVENTS.PLAYING, playing);
  }, [playing, fireStateChangeEvent]);

  useEffect(() => {
    if (muted) {
      setShowCC(true);
    }
    fireStateChangeEvent(PLAYER_STATE_EVENTS.MUTED, muted);
  }, [muted, fireStateChangeEvent]);

  const handleSkip = useCallback(() => {
    onSkip();
    fireStateChangeEvent(PLAYER_STATE_EVENTS.SKIPPED, true);
  }, [onSkip, fireStateChangeEvent]);

  const handleComplete = useCallback(() => {
    onComplete();
    fireStateChangeEvent(PLAYER_STATE_EVENTS.COMPLETED, true);
  }, [onComplete, fireStateChangeEvent]);

  const togglePlaying = useCallback(() => {
    handleTogglePlaying(setPlaying);
  }, [setPlaying]);

  const toggleMuted = useCallback(() => {
    handleToggleMuted(setMuted);
  }, [setMuted]);

  const toggleShowCC = useCallback(() => {
    handleToggleShowCC(setShowCC);
  }, [setShowCC]);

  const stopPlaying = useCallback(() => {
    setPlaying(false);
  }, [setPlaying]);

  const onReady = useCallback(() => {
    fireStateChangeEvent(PLAYER_STATE_EVENTS.READY, true);
    setIsReady(true);
    handleAutoPlay(
      initialState.playing,
      initialState.muted,
      playerRef?.current,
      setPlaying,
      setMuted,
    );
  }, [setPlaying, setIsReady, fireStateChangeEvent]);

  const onPlay = useCallback(() => {
    setPlaying(true);
  }, [setPlaying]);

  const onRestart = useCallback(async () => {
    if (playerRef.current) {
      playerRef.current.seekTo(0, 'seconds');
      const videoInstance = playerRef.current.getInternalPlayer();
      try {
        await videoInstance.play();
        setPlaying(true);
      } catch (e) {
        log.info('replay failed to autoplay');
      }
    }
  }, [setPlaying]);

  useEffect(() => {
    onRegisterPlayer?.({
      onPlay,
      onPause: stopPlaying,
      onRestart,
    });
  }, [onPlay, stopPlaying, onRestart]);

  return {
    fireStateChangeEvent,
    config,
    muted,
    onReady,
    onPlay,
    handleSkip,
    handleComplete,
    playerRef,
    playing,
    showCC,
    isReady,
    stopPlaying,
    toggleShowCC,
    toggleMuted,
    togglePlaying,
    onRestart,
  };
};

export default useMediaPlayerControls;
