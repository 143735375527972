import { createUseStyles } from 'react-jss';

import styling from '../../../services/styling';
import texture from '../../../static/images/meta_texture.webp';

export default createUseStyles(
  {
    container: {
      position: 'relative',
      minHeight: 300,
      backgroundColor: styling.colors.theme.metaPrimary,
      color: styling.colors.theme.metaSecondary,
      textAlign: 'center',
      '&:before': {
        pointerEvents: 'none',
        content: '""',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        background: `url(${texture}) top right repeat, url(${texture}) bottom left repeat, white`,
        backgroundAttachment: 'fixed',
        mixBlendMode: 'overlay',
      },
    },
    contentWrapper: {
      maxWidth: 880,
      margin: [0, 'auto'],
      paddingInline: styling.sizes.gutteringSm,
    },
    header: {
      paddingTop: 140,
      paddingBottom: 62,
    },
    headingTitle: {
      paddingBottom: 32,
    },
    metaButton: {
      display: 'inline-block',
      margin: [0, 'auto'],
      padding: [20, 30],
      cursor: 'pointer',
      color: styling.colors.theme.metaSecondary,
      backgroundColor: styling.colors.theme.metaSecondaryDeepDark,
      border: [2, 'solid', styling.colors.theme.metaSecondary],
      outline: 'none',
      boxShadow: [
        styling.shadows.utils.boxShadowToString({
          x: 0,
          y: 0,
          blur: 0,
          spread: 10,
          inset: null,
          color: styling.colors.theme.metaSecondaryDeepDark,
        }),
        styling.shadows.utils.boxShadowToString({
          x: 0,
          y: 0,
          blur: 0,
          spread: 10,
          inset: null,
          color: styling.colors.theme.metaSecondary,
        }),
      ].join(', '),
      transition: 'box-shadow 250ms ease-in-out',
      '&:hover,&:focus,&:active': {
        boxShadow: [
          styling.shadows.utils.boxShadowToString({
            x: 0,
            y: 0,
            blur: 0,
            spread: 10,
            inset: null,
            color: styling.colors.theme.metaSecondaryDeepDark,
          }),
          styling.shadows.utils.boxShadowToString({
            x: 0,
            y: 0,
            blur: 0,
            spread: 11,
            inset: null,
            color: styling.colors.theme.metaSecondary,
          }),
        ].join(', '),
      },
    },
    postersCta: {
      width: 220,
      [styling.breakpoints.up('sm')]: {
        width: 'auto',
      },
    },
    disclaimer: {
      maxWidth: 600,
      margin: [56, 'auto', 0],
    },
    divider: {
      width: '100%',
      maxWidth: 800,
      margin: [110, 'auto', 90],
      height: 1,
      backgroundColor: styling.colors.theme.metaSecondary,
    },
    footer: {
      paddingTop: 56,
      paddingBottom: 80,
    },
    image: {
      aspectRatio: '948 / 935',
      margin: [28, 'auto'],
      maxWidth: 474,
    },
    shareCta: {
      marginTop: 28,
    },
    markup: {
      '& p': {
        marginBottom: 12,
      },
      '& a': {
        color: 'inherit',
        textDecoration: 'underline',
        outline: 'none',
        '&:hover,&:focus,&:active': {
          textDecoration: 'none',
        },
      },
    },
  },
  { name: 'metaHeader' },
);
