import { createUseStyles } from 'react-jss';

import styling from '../../../services/styling';
import { variantMapping } from '../../Typography/constants';
import {
  CLIP_PATH_SIZE_LG,
  CLIP_PATH_SIZE_MD,
} from '../InputWrapper/constants';

export default createUseStyles(
  {
    toggleButton: {
      '--selectClipPathSize': `${CLIP_PATH_SIZE_MD}px`,
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      boxSizing: 'border-box',
      height: '100%',
      background: 'none',
      border: 'none',
      padding: [0, 'calc(var(--selectClipPathSize) * 2)'],
      cursor: 'pointer',
      ...variantMapping.inputValue.styles,
      color: 'inherit',
      appearance: 'none',
      display: 'flex',
      alignItems: 'center',
      whiteSpace: 'nowrap',
      textShadow: 'inherit',
      '&:focus': {
        outline: 'none',
      },
    },
    toggleButtonLg: {
      '--selectClipPathSize': `${CLIP_PATH_SIZE_LG}px`,
      ...variantMapping.inputValueLg.styles,
      color: 'inherit',
    },
    caret: {
      position: 'absolute',
      right: 28,
      top: '50%',
      transition: styling.speeds.jog,
      transform: 'translate(0, -50%) rotate(0deg)',
    },
    caretRotated: {
      transform: 'translate(0, -50%) rotate(180deg)',
    },
    listWrapper: {
      '--selectClipPathSize': `${CLIP_PATH_SIZE_MD}px`,
      maxHeight: 0,
      transition: styling.speeds.jog,
      display: 'flex',
      position: 'absolute',
      width: '100%',
      top: 'calc(100% + 1px)',
      zIndex: 5,
      outline: 'none',
      '&:before': {
        content: '""',
        position: 'absolute',
        right: 0,
        top: 'calc(var(--selectClipPathSize) * -1)',
        height: 'var(--selectClipPathSize)',
        width: 'var(--selectClipPathSize)',
        background: styling.colors.theme.transparent,
        borderRight: [1, 'solid', styling.colors.theme.transparent],
        clipPath: `polygon(100% 0, 100% 100%, 1px 100%)`,
      },
    },
    listWrapperLg: {
      '--selectClipPathSize': `${CLIP_PATH_SIZE_LG}px`,
      '&$listWrapperOpen': {
        maxHeight: 400,
      },
      '& $listOpen': {
        padding: [15, 0],
      },
      '& $item': {
        ...variantMapping.inputValueLg.styles,
        height: 72,
        padding: [0, 24],
      },
      '& $itemSelected': {
        color: styling.colors.theme.primaryLight,
      },
    },
    listWrapperOpen: {
      maxHeight: 300,
      '&:before': {
        background: styling.colors.theme.pureBlackFadedMin,
        borderRightColor: styling.colors.theme.primaryLight,
      },
    },
    listWrapperVariantcolored: {
      '& $item': {
        color: styling.colors.theme.primaryLight,
      },
    },
    list: {
      background: styling.colors.theme.pureBlackFadedMin,
      border: [1, 'solid', styling.colors.theme.transparent],
      borderTop: 'none',
      margin: 0,
      width: '100%',
      overflow: 'auto',
      outline: 'none',
    },
    listOpen: {
      padding: [8, 0],
      borderColor: styling.colors.theme.primaryLight,
    },
    item: {
      ...variantMapping.inputValue.styles,
      transition: styling.speeds.jog,
      cursor: 'pointer',
      lineHeight: '2.4rem',
      height: 40,
      display: 'flex',
      alignItems: 'center',
      padding: [0, 19],
      whiteSpace: 'nowrap',
    },
    itemSelected: {
      color: styling.colors.theme.primaryLight,
      background: styling.colors.theme.primaryLightFadedMax,
    },
    itemHighlighted: {
      background: styling.colors.theme.primaryLightFadedMax,
    },
  },
  { name: 'inputSelect' },
);
