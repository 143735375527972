import Backdrop from '../Backdrop';
import usePanelRoot from './hooks/usePanelRoot';

const PanelRoot = () => {
  const { closePanel, openedPanel, panels } = usePanelRoot();

  return (
    <>
      {Object.keys(panels).map((panelKey) => {
        const Panel = panels[panelKey];
        return <Panel key={panelKey} isOpen={openedPanel === panelKey} />;
      })}
      {openedPanel && <Backdrop onClick={closePanel} variant="panel" />}
    </>
  );
};

export default PanelRoot;
