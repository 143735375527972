import { createContext, useMemo } from 'react';
import PropTypes from 'prop-types';

import useMediaQueryManagement from '../hooks/useMediaQueryManagement';

export const MediaQueryContext = createContext({
  isXs: false,
  isSm: false,
  isMd: false,
  isLg: false,
  isXl: false,
  isDesktop: false,
});

export const MediaQueryProvider = ({ children }) => {
  const value = useMediaQueryManagement();

  return (
    <MediaQueryContext.Provider value={value}>
      {children}
    </MediaQueryContext.Provider>
  );
};

MediaQueryProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
