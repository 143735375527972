import Color from 'color';

const primaryLight = '#FCBF69';
const primaryLightFaded = Color(primaryLight).alpha(0.42).string();
const primaryLightFadedMax = Color(primaryLight).alpha(0.15).string();
const primary = '#F0AE51';
const primaryDark = '#FF9500';
const primaryDeepDark = '#FF6E2B';
const primaryDeepDarkFaded = Color(primaryDeepDark).alpha(0.6).string();

const secondaryLight = '#D5F0FC';
const secondary = '#1EBCEC';
const secondaryDark = '#00B3E9';
const secondaryDeepDark = '#224251';
const secondaryDeepDarkFaded = Color(secondaryDeepDark).alpha(0.6).string();

const metaPrimary = '#991C44';
const metaSecondary = '#D2A687';
const metaSecondaryDeepDark = '#0E0C0C';
const metaSecondaryDeepDarkFaded = Color(metaSecondaryDeepDark)
  .alpha(0.1)
  .string();

const error = '#FC6969';
const errorDark = '#F05151';

const softBlack = '#020707';

const pureWhite = '#FFF';
const pureWhiteFaded = Color(pureWhite).alpha(0.14).string();
const pureWhiteFadedMin = Color(pureWhite).alpha(0.2).string();
const pureBlack = '#000';
const pureBlackFaded = Color(pureBlack).alpha(0.7).string();
const pureBlackFadedMin = Color(pureBlack).alpha(0.9).string();
const transparent = 'rgba(0, 0, 0, 0)';

const colors = {
  theme: {
    error,
    errorDark,
    primary,
    primaryDark,
    primaryDeepDark,
    primaryDeepDarkFaded,
    primaryLight,
    primaryLightFaded,
    primaryLightFadedMax,
    pureBlack,
    pureBlackFaded,
    pureBlackFadedMin,
    pureWhite,
    pureWhiteFaded,
    pureWhiteFadedMin,
    secondary,
    secondaryDark,
    secondaryDeepDark,
    secondaryDeepDarkFaded,
    secondaryLight,
    softBlack,
    transparent,
    metaPrimary,
    metaSecondary,
    metaSecondaryDeepDark,
    metaSecondaryDeepDarkFaded,
  },
  context: {
    background: pureBlack,
    link: secondary,
    secondaryNav: secondary,
    subtitle: secondary,
    text: pureWhite,
    title: primaryLight,
  },
};

export default colors;
