import PropTypes from 'prop-types';

import useMissionSelectSection from './hooks/useMissionSelectSection';
import Carousel from './Carousel';

const MissionSelectSection = ({
  missions,
  withBackground,
  canSelect,
  includeMissionHrefPrefix,
}) => {
  const { index } = useMissionSelectSection(missions);

  return (
    <Carousel
      canSelect={canSelect}
      missions={missions}
      initialIndex={index}
      withBackground={withBackground}
      includeMissionHrefPrefix={includeMissionHrefPrefix}
    />
  );
};

MissionSelectSection.propTypes = {
  missions: PropTypes.arrayOf(PropTypes.object).isRequired,
  withBackground: PropTypes.bool,
  canSelect: PropTypes.bool,
  includeMissionHrefPrefix: PropTypes.bool,
};

MissionSelectSection.defaultProps = {
  withBackground: false,
  canSelect: true,
  includeMissionHrefPrefix: false,
};

export default MissionSelectSection;
