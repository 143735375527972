import { createUseStyles } from 'react-jss';

export default createUseStyles(
  {
    wrapper: {
      textAlign: 'center',
      margin: [0, 'auto'],
      maxWidth: 750,
    },
    title: {
      marginBottom: 66,
    },
    button: {
      width: '100%',
      maxWidth: 360,
      marginTop: 20,
    },
  },
  { name: 'errorComponent' },
);
