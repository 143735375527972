import PropTypes from 'prop-types';
import clsx from 'clsx';

import Image from '../../../../Image';
import useStyles from './missionIcon.styles';

const MissionIcon = ({ className, iconAnimation, shouldAnimate }) => {
  const styles = useStyles();

  return (
    <div
      className={clsx(
        styles.wrapper,
        shouldAnimate && styles.wrapperGlow,
        className,
      )}
    >
      {iconAnimation.map((animation, i) => (
        <Image
          key={animation.imgSrc}
          className={clsx(
            styles.icon,
            styles[animation.animationClass],
            shouldAnimate && styles.animating,
          )}
          src={animation.imgSrc}
        />
      ))}
    </div>
  );
};

MissionIcon.propTypes = {
  className: PropTypes.string,
  iconAnimation: PropTypes.arrayOf(PropTypes.object).isRequired,
  shouldAnimate: PropTypes.bool,
};

MissionIcon.defaultProps = {
  className: undefined,
  shouldAnimate: false,
};

export default MissionIcon;
