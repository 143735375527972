import PropTypes from 'prop-types';
import { Markup as InterweaveMarkup } from 'interweave';

const Markup = ({ content, noWrap, ...props }) => (
  <InterweaveMarkup {...props} content={content} noWrap={noWrap} />
);

Markup.propTypes = {
  content: PropTypes.string.isRequired,
  noWrap: PropTypes.bool,
};

Markup.defaultProps = {
  noWrap: true,
};

export default Markup;
