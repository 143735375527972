import useGlobalContent from '../../../../hooks/useGlobalContent';
import styling from '../../../../services/styling';
import { SOTFullIcon, UQCTPFullIcon } from '../../../Icons';
import useStyles from './logoStacks.styles';

const LogoStacks = () => {
  const styles = useStyles();
  const {
    content: { footer },
  } = useGlobalContent();
  const { links } = footer || {};

  return (
    <div className={styles.wrapper}>
      {links?.sot && (
        <a
          className={styles.link}
          href={links.sot}
          rel="noreferrer"
          target="_blank"
          title="School of Thought"
        >
          <SOTFullIcon color={styling.colors.theme.secondaryLight} />
        </a>
      )}
      {links?.sot && links?.uq && <div className={styles.spacingPlus} />}
      {links?.uq && (
        <a
          className={styles.link}
          href={links.uq}
          rel="noreferrer"
          target="_blank"
          title="The University of Queensland Critical Thinking Project"
        >
          <UQCTPFullIcon color={styling.colors.theme.secondaryLight} />
        </a>
      )}
    </div>
  );
};

export default LogoStacks;
