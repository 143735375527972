import { useInView } from 'react-intersection-observer';

const useStatBar = (value, secondaryValue = 0) => {
  const { ref, inView } = useInView({
    fallbackInView: true,
    threshold: 0.3,
    triggerOnce: true,
  });
  const secondaryBarWidth = value + secondaryValue;

  return {
    inView,
    ref,
    secondaryBarWidth,
  };
};

export default useStatBar;
