import { createUseStyles } from 'react-jss';

import styling from '../../../../services/styling';

export default createUseStyles(
  {
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      overflow: 'hidden',
      padding: [15, 35],
      margin: [160, 0, 0],
      [styling.breakpoints.up('md')]: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginBottom: [0, 0, -18],
      },
    },
    menuItem: {
      width: 'calc(100% + 160px)',
      margin: [0, -80],
      [styling.breakpoints.up('md')]: {
        width: 'auto',
        margin: 0,
      },
      '& + $menuItem': {
        marginTop: -1,
        [styling.breakpoints.up('md')]: {
          marginLeft: -1,
        },
      },
    },
  },
  { name: 'footerMenu' },
);
