import styling from '../../services/styling';

const md = {
  styles: {
    minWidth: 150,
    borderWidth: 1,
    fontSize: '2rem',
    lineHeight: '2rem',
    letterSpacing: '-0.013rem',
    fontWeight: styling.fonts.barlowCondensed.weights.regular,
    padding: [30, 0],
    [styling.breakpoints.up('md')]: {
      padding: [10, 0],
    },
  },
};
const mdFixed = {
  ...md,
  styles: {
    ...md.styles,
    padding: [10, 0],
  },
};
const lg = {
  styles: {
    width: 200,
    borderWidth: 2,
    fontSize: '3rem',
    lineHeight: '3rem',
    letterSpacing: '-0.023rem',
    fontWeight: styling.fonts.barlowCondensed.weights.semiBold,
    padding: [12, 0],
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 1,
      opacity: 0.5,
      background: `radial-gradient(circle, ${styling.colors.theme.transparent} 0%, ${styling.colors.theme.pureBlack} 50%)`,
    },
  },
};

export const sizeMapping = {
  md,
  mdFixed,
  lg,
};

export const sizeMappingKeys = Object.keys(sizeMapping);
export const sizeMappingDefaultKey = 'md';
