import { useMemo } from 'react';
import { useParams } from 'react-router';

import { getInitialIndex } from '../logic';

const useMissionSelectSection = (missions) => {
  const { missionSlug } = useParams();

  const index = useMemo(
    () => getInitialIndex(missions, missionSlug),
    [missions, missionSlug],
  );

  return {
    index,
  };
};

export default useMissionSelectSection;
