export const getCloseAlert = (setTopAlerts) => () => {
  setTopAlerts();
  window.document.scrollingElement.classList.remove('lock-scroll-alert');
};

export const getDeregisterAlert = (setAlerts) => (alertId) => {
  setAlerts((alerts) => alerts.filter((alert) => alert.key !== alertId));
};

export const getRegisterAlert = (setAlerts) => (alertId, alertConfig) => {
  setAlerts((alerts) => {
    if (alerts.find((alert) => alert.key === alertId)) {
      return alerts;
    }
    return [...alerts, { config: alertConfig, key: alertId }];
  });
};

export const handleAlertsChange = (alerts, setTopAlert) => {
  if (alerts.length) {
    setTopAlert(alerts[alerts.length - 1].key);
    window.document.scrollingElement.classList.add('lock-scroll-alert');
    return;
  }
  setTopAlert();
};
