import { createUseStyles } from 'react-jss';

export default createUseStyles(
  {
    wrapper: {
      marginTop: -8,
      marginBottom: 55,
      textAlign: 'center',
    },
  },
  { name: 'formSubmitButton' },
);
