export const apiRequestBase =
  (performRequest) =>
  async (requestConfig, registerCancel = () => {}) => {
    const controller = new AbortController();
    registerCancel(() => controller.abort());

    try {
      const response = await performRequest({
        ...requestConfig,
        signal: controller.signal,
      });
      return response;
    } catch (err) {
      if (err?.constructor?.name === 'AbortError') {
        return Promise.reject(err);
      }
      throw err;
    }
  };

const setBaseUrl = (config) => {
  if (window.location?.hostname === 'localhost') {
    // use staging for local development
    return {
      ...config,
      baseURL: `https://api.staging.theconspiracytest.org`,
    };
  }
  return {
    ...config,
    baseURL: `https://api.${window.location.host}`,
  };
};

export const getTctRequestConfig = (initialConfig = {}) =>
  setBaseUrl(initialConfig);

const appendCachebreak = (method, url) => {
  if (method.toLowerCase() !== 'get') {
    return url;
  }
  if (url.indexOf('?') >= 0) {
    return `${url}&v=${process.env.REACT_APP_CACHEBREAK}`;
  }
  return `${url}?v=${process.env.REACT_APP_CACHEBREAK}`;
};

const replaceBaseURLForDataUrls = (config) => {
  if (config.url.indexOf('/data') === 0) {
    return {
      ...config,
      url: appendCachebreak(config.method, config.url),
      baseURL: window.location.origin,
    };
  }
  return {
    ...config,
    url: appendCachebreak(config.method, config.url),
  };
};

export const addInterceptors = (instance) => {
  instance.interceptors.request.use(replaceBaseURLForDataUrls);
};
