import { useCallback, useEffect, useRef, useState } from 'react';
import { isEqual } from 'lodash';

import { handleOnCueChange, handleOnPlayerMount } from '../logic';

const usePlayerCaptions = (playerRef, muted, tracks, trackIndex) => {
  const [tracksCache, setTracksCache] = useState(tracks);
  const [caption, setCaption] = useState('');

  useEffect(() => {
    setCaption('');
  }, [setCaption, trackIndex]);

  const onCueChange = useCallback(
    (e) => handleOnCueChange(e, setCaption),
    [setCaption],
  );

  useEffect(
    () => handleOnPlayerMount(playerRef, onCueChange, trackIndex),
    [playerRef, onCueChange, trackIndex, tracksCache, muted],
  );

  return {
    caption,
  };
};

export default usePlayerCaptions;
