const constructSpanVariable = (span) => ({
  '--formGridItemSpan': `span ${span}`,
});

export const calculateStyles = (widths, isXs, isSm, isMd, isLg, isXl) => {
  if (!widths) return undefined;

  if (isXl && widths?.xl) return constructSpanVariable(widths.xl);
  if (isLg && widths?.lg) return constructSpanVariable(widths.lg);
  if (isMd && widths?.md) return constructSpanVariable(widths.md);
  if (isSm && widths?.sm) return constructSpanVariable(widths.sm);
  if (isXs && widths?.xs) return constructSpanVariable(widths.xs);

  return undefined;
};
