import PropTypes from 'prop-types';

import useStatsDisplay from './hooks/useStatsDisplay';
import StatsDisplayBar from './StatsDisplayBar';
import StatsDisplayWheels from './StatsDisplayWheels';

const StatsDisplay = ({ includeScore, isStacked, isLoading, type, data }) => {
  const { score } = useStatsDisplay(data);

  if (type === 'wheels') {
    return (
      <StatsDisplayWheels
        data={data}
        score={score}
        includeScore={includeScore}
        isStacked={isStacked}
        isLoading={isLoading}
      />
    );
  }
  if (type === 'bar') {
    return <StatsDisplayBar data={data} score={score} />;
  }
  return null;
};

StatsDisplay.propTypes = {
  includeScore: PropTypes.bool,
  isStacked: PropTypes.bool,
  isLoading: PropTypes.bool,
  type: PropTypes.oneOf(['wheels', 'bar']).isRequired,
  data: PropTypes.shape({
    title: PropTypes.string,
    increase: PropTypes.number.isRequired,
    baseline: PropTypes.number.isRequired,
  }),
};

StatsDisplay.defaultProps = {
  includeScore: true,
  isLoading: false,
  isStacked: false,
  data: {
    increase: 0,
    baseline: 0,
  },
};

export default StatsDisplay;
