import PropTypes from 'prop-types';
import {
  ErrorBoundary as RollbarErrorBoundary,
  Provider,
  RollbarContext,
  useRollbar,
} from '@rollbar/react';

import ErrorComponent from '../../components/ErrorBoundary/ErrorComponent';
import { rollbarEnvironment } from './logic';

export const useErrorCapture = useRollbar;

const rollbarConfig = {
  enabled: process.env.NODE_ENV !== 'development',
  accessToken: '059b0d47e3a344db9d4e128a637fee5f',
  environment: rollbarEnvironment(),
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    context: 'root',
    client: {
      javascript: {
        source_map_enabled: true,
      },
    },
  },
};

export const ErrorBoundary = ({ children }) => (
  <RollbarErrorBoundary fallbackUI={ErrorComponent}>
    {children}
  </RollbarErrorBoundary>
);

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export const ErrorSpace = ({ children, space, ...props }) => (
  <RollbarContext {...props} context={space}>
    {children}
  </RollbarContext>
);

ErrorSpace.propTypes = {
  children: PropTypes.node.isRequired,
  space: PropTypes.string.isRequired,
};

export const ErrorCaptureProvider = ({ children }) => (
  <Provider config={rollbarConfig}>
    <ErrorBoundary>{children}</ErrorBoundary>
  </Provider>
);

ErrorCaptureProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
