import { useContext, useRef } from 'react';

import { MediaQueryContext } from '../../../../../contexts/mediaQueryContext';
import useGlobalContent from '../../../../../hooks/useGlobalContent';

const useFooterVideo = () => {
  const initialPlayerState = useRef({
    playing: true,
    muted: true,
  });
  const { isDesktop } = useContext(MediaQueryContext);
  const {
    content: { footer },
  } = useGlobalContent();
  const { video } = footer || {};
  const playerSources = video?.[isDesktop ? 'desktop' : 'mobile'] || [];

  return { isDesktop, initialPlayerState, playerSources };
};

export default useFooterVideo;
