import PropTypes from 'prop-types';
import clsx from 'clsx';

import styling from '../../../services/styling';
import { CaretDownIcon } from '../../Icons';
import Markup from '../../Markup';
import InputWrapper from '../InputWrapper';
import useInputSelect from './hooks/useInputSelect';
import useStyles from './inputSelect.styles';

const InputSelect = ({
  className,
  disabled,
  errors,
  hasSolidBackground,
  isFocused,
  onBlur,
  onChange,
  onFocus,
  options,
  size,
  value,
  variant,
}) => {
  const styles = useStyles();
  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
    selectedItem,
  } = useInputSelect(options, onChange, value);

  return (
    <InputWrapper
      className={className}
      errors={errors}
      hasSolidBackground={hasSolidBackground}
      isDisabled={disabled}
      isFocused={isFocused}
      size={size}
      variant={variant}
    >
      <div
        className={clsx(
          styles.toggleButton,
          size === 'lg' && styles.toggleButtonLg,
        )}
        {...getToggleButtonProps({
          disabled,
          onBlur,
          onFocus: disabled ? undefined : onFocus,
          tabIndex: disabled ? -1 : 0,
        })}
      >
        <Markup content={selectedItem?.title || ''} />
        <CaretDownIcon
          className={clsx(styles.caret, isOpen && styles.caretRotated)}
          color={styling.colors.theme.primaryLight}
        />
      </div>
      <div
        className={clsx(
          styles.listWrapper,
          isOpen && styles.listWrapperOpen,
          size === 'lg' && styles.listWrapperLg,
          variant && styles[`listWrapperVariant${variant}`],
        )}
      >
        <ul
          className={clsx(styles.list, isOpen && styles.listOpen)}
          {...getMenuProps()}
          onBlur={onBlur}
        >
          {isOpen &&
            options.map((option, index) => (
              <li
                key={option.value}
                className={clsx(
                  styles.item,
                  highlightedIndex === index && styles.itemHighlighted,
                  selectedItem?.value === option.value && styles.itemSelected,
                )}
                {...getItemProps({ item: option, index })}
              >
                <Markup content={option.title} />
              </li>
            ))}
        </ul>
      </div>
    </InputWrapper>
  );
};

InputSelect.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.string),
  hasSolidBackground: PropTypes.bool,
  isFocused: PropTypes.bool,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  variant: PropTypes.oneOf(['colored']),
  size: PropTypes.oneOf(['md', 'lg']),
};

InputSelect.defaultProps = {
  className: undefined,
  disabled: false,
  errors: undefined,
  hasSolidBackground: false,
  isFocused: false,
  variant: undefined,
  size: 'md',
  value: undefined,
};

export default InputSelect;
