import PropTypes from 'prop-types';

import LayoutBrochure from '../../components/LayoutBrochure';
import PageTitle from '../../components/PageTitle';
import Typography from '../../components/Typography';
import { ErrorSpace } from '../../contexts/errorCaptureContext';
import { asyncRequestStates } from '../../hooks/useAsyncRequest';
import use404PageContent from './hooks/use404PageContent';

const FourOhFour = ({ title }) => {
  const {
    pageContent: { header },
    pageContentState,
  } = use404PageContent();
  return (
    <LayoutBrochure withPyramid withSpaceship>
      <ErrorSpace space="/404">
        <PageTitle title={title} />
        <Typography variant="title" withShadow>
          404
        </Typography>
      </ErrorSpace>
    </LayoutBrochure>
  );
};

FourOhFour.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
};

export default FourOhFour;
