import { useMemo } from 'react';

import { getVariantComponent, getVariants } from '../logic';

const useCustomComponent = (component, variantKey) => {
  if (component !== undefined) return component;

  const defaultComponent = useMemo(
    () => getVariantComponent(variantKey),
    [variantKey],
  );

  return defaultComponent;
};

export default useCustomComponent;
