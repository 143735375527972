import Color from 'color';

import colors from './colors';

const primaryXs = {
  x: 0,
  y: 0,
  blur: 5,
  spread: 0,
  color: colors.theme.primaryDark,
  inset: null,
};
const primarySm = {
  x: 0,
  y: 0,
  blur: 10,
  spread: 2,
  color: colors.theme.primaryDark,
  inset: null,
};
const primaryMd = {
  x: 0,
  y: 0,
  blur: 14,
  spread: 0,
  color: colors.theme.primary,
  inset: null,
};
const primaryLg = {
  x: 0,
  y: 0,
  blur: 20,
  spread: 0,
  color: colors.theme.primary,
  inset: null,
};
const primaryLgHover = {
  x: 0,
  y: 0,
  blur: 25,
  spread: 0,
  color: Color(colors.theme.primary).lighten(0.4).string(),
  inset: null,
};
const primarySmFaded = {
  x: 0,
  y: 0,
  blur: 10,
  spread: 2,
  color: Color(colors.theme.primaryDark).alpha(0.35).string(),
  inset: null,
};
const primarySmInsetFaded = {
  x: 0,
  y: 0,
  blur: 10,
  spread: 2,
  color: Color(colors.theme.primaryDark).alpha(0.35).string(),
  inset: true,
};
const primaryLgFaded = {
  x: 0,
  y: 0,
  blur: 20,
  spread: 0,
  color: Color(colors.theme.primary).alpha(0.56).string(),
  inset: null,
};
const primaryDeepDarkLgFaded = {
  x: 0,
  y: 0,
  blur: 70,
  spread: 0,
  color: colors.theme.primaryDeepDarkFaded,
  inset: null,
};
const primaryDeepDarkXlFaded = {
  x: 0,
  y: 0,
  blur: 140,
  spread: 0,
  color: colors.theme.primaryDeepDarkFaded,
  inset: null,
};
const primaryLightSm = {
  x: 0,
  y: 0,
  blur: 20,
  spread: 0,
  color: colors.theme.primaryLight,
  inset: null,
};
const primaryLightXxsFaded = {
  x: 0,
  y: 0,
  blur: 2,
  spread: 1,
  color: Color(colors.theme.primaryLight).alpha(0.56).string(),
  inset: null,
};
const primaryLightSmFaded = {
  x: 0,
  y: 0,
  blur: 20,
  spread: 0,
  color: Color(colors.theme.primaryLight).alpha(0.7).string(),
  inset: null,
};
const primaryLightLgFaded = {
  x: 0,
  y: 0,
  blur: 50,
  spread: 0,
  color: Color(colors.theme.primaryLight).alpha(0.7).string(),
  inset: null,
};
const secondaryXs = {
  x: 0,
  y: 0,
  blur: 5,
  spread: 0,
  color: colors.theme.secondary,
  inset: null,
};
const secondarySm = {
  x: 0,
  y: 0,
  blur: 10,
  spread: 2,
  color: colors.theme.secondary,
  inset: null,
};
const secondarySmFaded = {
  x: 0,
  y: 0,
  blur: 10,
  spread: 2,
  color: Color(colors.theme.secondary).alpha(0.35).string(),
  inset: null,
};
const secondarySmInsetFaded = {
  x: 0,
  y: 0,
  blur: 10,
  spread: 2,
  color: Color(colors.theme.secondary).alpha(0.35).string(),
  inset: true,
};
const secondaryLg = {
  x: 0,
  y: 0,
  blur: 20,
  spread: 0,
  color: colors.theme.secondary,
  inset: null,
};
const secondaryLgHover = {
  x: 0,
  y: 0,
  blur: 25,
  spread: 0,
  color: Color(colors.theme.secondary).lighten(0.8).string(),
  inset: null,
};
const secondaryLgFaded = {
  x: 0,
  y: 0,
  blur: 20,
  spread: 0,
  color: Color(colors.theme.secondary).alpha(0.56).string(),
  inset: null,
};
const errorLg = {
  x: 0,
  y: 0,
  blur: 20,
  spread: 0,
  color: colors.theme.error,
  inset: null,
};
const errorDarkLg = {
  x: 0,
  y: 0,
  blur: 16,
  spread: 0,
  color: colors.theme.errorDark,
  inset: null,
};
const whiteLg = {
  x: 0,
  y: 0,
  blur: 71,
  spread: 0,
  color: colors.theme.pureWhite,
  inset: null,
};
const transparent = {
  x: 0,
  y: 0,
  blur: 0,
  spread: 0,
  color: Color(colors.theme.transparent).string(),
  inset: null,
};

const svgFilter = (shadow) =>
  `drop-shadow(${shadow.x}px ${shadow.y}px ${shadow.blur * 0.5}px ${
    shadow.color
  })`;

const boxShadowToString = ({ x, y, blur, spread, color, inset }) =>
  `${inset ? 'inset ' : ''}${x}px ${y}px ${blur}px ${spread}px ${color}`;

const shadows = {
  theme: {
    primaryXs,
    primarySm,
    primaryMd,
    primaryLg,
    primarySmFaded,
    primarySmInsetFaded,
    primaryLgFaded,
    primaryDeepDarkLgFaded,
    primaryDeepDarkXlFaded,
    primaryLightSm,
    primaryLightXxsFaded,
    primaryLightSmFaded,
    primaryLightLgFaded,
    secondaryXs,
    secondarySm,
    secondaryLg,
    secondarySmFaded,
    secondarySmInsetFaded,
    secondaryLgFaded,
    errorLg,
    errorDarkLg,
    whiteLg,
    transparent,
  },
  context: {
    title: primaryLg,
    titleHover: primaryLgHover,
    subtitle: primaryLg,
    subtitleHover: primaryLgHover,
    secondaryNav: secondaryLg,
    secondaryNavHover: secondaryLgHover,
  },
  utils: {
    svgFilter,
    boxShadowToString,
  },
};

export default shadows;
