export const getCloseToast = (setTopToasts) => () => {
  setTopToasts();
};

export const getDeregisterToast = (setToasts) => (toastId) => {
  setToasts((toasts) => toasts.filter((toast) => toast.key !== toastId));
};

export const getRegisterToast = (setToasts) => (toastId, toastConfig) => {
  setToasts((toasts) => {
    if (toasts.find((toast) => toast.key === toastId)) {
      return toasts;
    }
    return [...toasts, { config: toastConfig, key: toastId }];
  });
};

export const handleToastsChange = (toasts, setTopToast) => {
  if (toasts.length) {
    setTopToast(toasts[toasts.length - 1].key);
    return;
  }
  setTopToast();
};
