import PropTypes from 'prop-types';
import clsx from 'clsx';

import styling from '../../../services/styling';
import useSOTNav from './hooks/useSOTNav';
import useStyles from './sotNav.styles';

const SOTNav = ({ color, isResponsive }) => {
  const styles = useStyles();
  const { toggleSOTNav, SOTIcon, isOpen, otherPanelOpen } =
    useSOTNav(isResponsive);

  return (
    <button
      aria-label="School of Thought Project Navigation Toggle"
      type="button"
      className={clsx(
        styles.sot,
        isOpen && styles.sotOpen,
        otherPanelOpen && styles.otherPanelOpen,
      )}
      onClick={toggleSOTNav}
    >
      <SOTIcon isOpen={isOpen} color={color} />
    </button>
  );
};

SOTNav.propTypes = {
  color: PropTypes.string,
  isResponsive: PropTypes.bool,
};

SOTNav.defaultProps = {
  color: styling.colors.theme.pureWhite,
  isResponsive: true,
};

export default SOTNav;
