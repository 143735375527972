import PropTypes from 'prop-types';
import clsx from 'clsx';

import Typography from '../../Typography';
import useStyles from './inputWrapper.styles';

const InputWrapper = ({
  className,
  children,
  hasSolidBackground,
  isDisabled,
  isFocused,
  errors,
  size,
  variant,
}) => {
  const styles = useStyles();

  return (
    <div
      className={clsx(
        className,
        styles.wrapper,
        variant && styles[`variant${variant}`],
        size === 'lg' && styles.wrapperLg,
        isFocused && styles.focused,
        isDisabled && styles.disabled,
        errors?.length && styles.errored,
      )}
    >
      <div
        className={clsx(
          styles.background,
          hasSolidBackground && styles.solidBackground,
        )}
      />
      <div className={styles.input}>{children}</div>
      {errors?.length > 0 && (
        <Typography className={styles.errors} variant="inputErrors">
          {errors[0] || ''}
        </Typography>
      )}
    </div>
  );
};

InputWrapper.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  hasSolidBackground: PropTypes.bool,
  isFocused: PropTypes.bool,
  isDisabled: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.string),
  size: PropTypes.oneOf(['md', 'lg']),
  variant: PropTypes.oneOf(['colored']),
};

InputWrapper.defaultProps = {
  className: undefined,
  hasSolidBackground: false,
  isFocused: false,
  isDisabled: false,
  errors: undefined,
  variant: undefined,
  size: 'md',
};

export default InputWrapper;
