const sprint = '150ms';
const run = '200ms';
const jog = '300ms';
const skip = '600ms';
const walk = '1200ms';
const mosey = '2000ms';
const saunter = '3000ms';
const crawl = '4000ms';

export default {
  sprint,
  run,
  jog,
  skip,
  walk,
  mosey,
  saunter,
  crawl,
};
