import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import useCustomComponent from './hooks/useCustomComponent';
import { variantMappingDefaultKey, variantMappingKeys } from './constants';
import useStyles from './typography.styles';

const Typography = forwardRef(
  (
    {
      children,
      className,
      component,
      variant,
      withShadow,
      withHoverShadow,
      ...rest
    },
    ref,
  ) => {
    const styles = useStyles();
    const Component = useCustomComponent(component, variant);

    return (
      <Component
        {...rest}
        ref={ref}
        className={clsx(
          className,
          styles[`variant-${variant}`],
          withShadow && styles.withShadow,
          withHoverShadow && styles.withHoverShadow,
        )}
      >
        {children}
      </Component>
    );
  },
);

Typography.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  variant: PropTypes.oneOf(variantMappingKeys),
  withHoverShadow: PropTypes.bool,
  withShadow: PropTypes.bool,
};

Typography.defaultProps = {
  className: undefined,
  component: undefined,
  variant: variantMappingDefaultKey,
  withHoverShadow: false,
  withShadow: false,
};

export default Typography;
