import { useCallback, useContext, useRef, useState } from 'react';

import { PLAYER_STATE_EVENTS } from '../../../../../components/MediaPlayer/constants';
import { CONTROLS_MINIMAL } from '../../../../../components/MediaPlayer/PlayerControls/constants';
import { MediaQueryContext } from '../../../../../contexts/mediaQueryContext';
import { getPlayerSources } from '../logic';

const useWhyVideo = (sources, loopVideo) => {
  const initialPlayerState = useRef({
    playing: false,
    muted: false,
  });
  const [isInnerReady, setIsInnerReady] = useState(!loopVideo);
  const [hasYetToPlay, setHasYetToPlay] = useState(true);

  const { isDesktop } = useContext(MediaQueryContext);
  const playerSources = getPlayerSources(sources, isDesktop);

  const onVideoStateChange = useCallback(
    (event, value) => {
      if (event === PLAYER_STATE_EVENTS.PLAYING && value) {
        setHasYetToPlay(false);
      }
    },
    [setHasYetToPlay],
  );

  const onInnerReady = useCallback(() => {
    setIsInnerReady(true);
  }, [setIsInnerReady]);

  return {
    initialPlayerState: initialPlayerState.current,
    playerSources,
    onVideoStateChange,
    isInnerReady,
    onInnerReady,
    hasYetToPlay,
  };
};

export default useWhyVideo;
