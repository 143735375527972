import PropTypes from 'prop-types';

import useCaptcha from './hooks/useCaptcha';
import useStyles from './captcha.styles';
import { defaultCaptchaConfig } from './constants';

const Captcha = ({ onVerify, registerExecuteCaptcha }) => {
  useStyles();
  const { elementID, elementRef } = useCaptcha(
    registerExecuteCaptcha,
    onVerify,
  );

  return (
    <div
      ref={elementRef}
      id={elementID}
      data-onloadcallbackname={defaultCaptchaConfig.onloadCallbackName}
      data-verifycallbackname={defaultCaptchaConfig.verifyCallbackName}
    />
  );
};

Captcha.propTypes = {
  onVerify: PropTypes.func.isRequired,
  registerExecuteCaptcha: PropTypes.func.isRequired,
};

export default Captcha;
