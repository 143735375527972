import React from 'react';
import PropTypes from 'prop-types';

import SlantedButton from '../../SlantedButton';
import Typography from '../../Typography';
import useErrorComponent from './hooks/useErrorComponent';
import useStyles from './errorComponent.styles';

const ErrorComponent = ({ tryAgain, resetError }) => {
  useErrorComponent();
  const styles = useStyles();

  return (
    <div className={styles.wrapper}>
      <Typography className={styles.title} variant="title" withShadow>
        Oops!
      </Typography>
      <Typography variant="subtitle">
        It looks like something escaped the simulation.
      </Typography>
      <Typography variant="body">
        Rest assured, our agent&rsquo;s are on the case.
        {(tryAgain || resetError) && (
          <span>
            If you reckon this was just a glitch in the matrix, and wanna have
            another go, click below.
          </span>
        )}
      </Typography>
      {(tryAgain || resetError) && (
        <SlantedButton
          className={styles.button}
          size="mdFixed"
          onClick={tryAgain || resetError}
        >
          Try again
        </SlantedButton>
      )}
    </div>
  );
};

ErrorComponent.propTypes = {
  tryAgain: PropTypes.func,
  resetError: PropTypes.func,
};

ErrorComponent.defaultProps = {
  tryAgain: undefined,
  resetError: undefined,
};

export default ErrorComponent;
