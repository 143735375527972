import PropTypes from 'prop-types';
import clsx from 'clsx';

import styling from '../../../../../services/styling';
import CaretLeft from '../../../../Icons/CaretLeft';
import CaretRight from '../../../../Icons/CaretRight';
import Typography from '../../../../Typography';
import useCaretDimension from './hooks/useCaretDimension';
import useNavigationText from './hooks/useNavigationText';
import useStyles from './navigationButton.styles';

const NavigationButton = ({ isNext, className, handleClick }) => {
  const styles = useStyles();
  const buttonText = useNavigationText(isNext);
  const caretDimension = useCaretDimension();

  return (
    <div className={clsx(className, styles.moveable)}>
      <div className={styles.buttonWrapper} onClick={() => handleClick()}>
        {!isNext && (
          <CaretLeft
            className={styles.caretLeft}
            color={styling.colors.theme.secondary}
            height={caretDimension.height}
            width={caretDimension.width}
          />
        )}
        <Typography
          component="span"
          variant={isNext ? 'carouselPrimaryNav' : 'carouselSecondaryNav'}
          withShadow
        >
          {buttonText}
        </Typography>
        {isNext && (
          <CaretRight
            className={styles.caretRight}
            color={styling.colors.theme.primaryDark}
            height={caretDimension.height}
            width={caretDimension.width}
          />
        )}
      </div>
    </div>
  );
};

NavigationButton.propTypes = {
  isNext: PropTypes.bool,
  showText: PropTypes.bool,
  className: PropTypes.string,
  handleClick: PropTypes.func.isRequired,
};

NavigationButton.defaultProps = {
  isNext: false,
  showText: true,
  className: '',
};

export default NavigationButton;
