import { createUseStyles } from 'react-jss';

import { variantMapping } from '../../../components/Typography/constants';
import styling from '../../../services/styling';

export default createUseStyles(
  {
    title: {
      textAlign: 'center',
      margin: [140, 'auto', 40],
      maxWidth: styling.sizes.landingSectionMaxWidth,
    },
    subtitle: {
      margin: [0, 'auto', 45],
      maxWidth: styling.sizes.landingSectionMaxWidth,
      '& > h2': {
        marginBottom: 57,
      },
      '& a': {
        ...variantMapping.link.styles,
        fontSize: 'inherit',
        lineHeight: 'inherit',
        fontWeight: 'inherit',
        textDecoration: 'underline',
      },
    },
    body: {
      margin: [0, 'auto', 63],
      maxWidth: styling.sizes.landingSectionMaxWidth,
      '& p + p': {
        marginTop: 10,
      },
      '& a': {
        ...variantMapping.link.styles,
        fontWeight: 'inherit',
        textDecoration: 'underline',
      },
    },
    advisorsTitle: {
      textAlign: 'center',
      fontWeight: styling.fonts.barlowCondensed.weights.semiBold,
    },
    peopleGrid: {
      padding: [52, 0, 0],
      display: 'flex',
      flexWrap: 'wrap',
      maxWidth: 600,
      margin: [0, 'auto', 192],
      rowGap: 65,
      columnGap: 20,
      justifyContent: 'center',
      alignItems: 'center',
      [styling.breakpoints.up('md')]: {
        justifyContent: 'space-between',
        maxWidth: 822,
      },
    },
    person: {
      flexGrow: 1,
      width: '100%',
      maxWidth: 260,
      position: 'relative',
      paddingBottom: `calc(2.2rem * 2)`,
      transform: 'translateX(50px)',
    },
    personImg: {
      height: 141,
      width: 160,
      marginBottom: 30,
      borderRadius: 5,
      overflow: 'hidden',
    },
    personTitle: {
      marginBottom: 5,
      fontSize: '2.5rem',
      lineHeight: '2.2rem',
    },
    personPosition: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      lineHeight: '2.2rem',
      height: `calc(2.2rem * 2)`,
      whiteSpace: 'pre-wrap',
    },
  },
  { name: 'landingWho' },
);
