import { createUseStyles } from 'react-jss';

import styling from '../../services/styling';

export default createUseStyles(
  {
    panel: {
      position: 'fixed',
      width: '100%',
      maxWidth: styling.sizes.panelMaxWidth,
      zIndex: styling.zIndex.panel,
      backgroundColor: styling.colors.context.background,
      transform: 'translate3d(0, 0, 0)',
      overflow: 'auto',
    },
    docked: {
      top: 0,
      bottom: 0,
      transition: [['transform', styling.speeds.jog, 'ease-in-out']],
      '&$isOpen': {
        transform: 'translate3d(0, 0, 0)',
        transition: [['transform', styling.speeds.run, 'ease-in-out']],
      },
    },
    'docked-left': {
      left: 0,
      right: 'auto',
      transform: 'translate3d(-100%, 0, 0)',
    },
    'docked-right': {
      right: 0,
      left: 'auto',
      transform: 'translate3d(100%, 0, 0)',
    },
    isOpen: {},
  },
  { name: 'panel' },
);
