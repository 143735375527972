import { memo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import Form from '../../../../components/Form';
import { TimesIcon } from '../../../../components/Icons';
import LoadingOverlay from '../../../../components/LoadingOverlay';
import Markup from '../../../../components/Markup';
import Typography from '../../../../components/Typography';
import QuestionSubtitle from '../../../mission/scenes/missionSelected/ActiveMission/SteppedQuestion/QuestionSubtitle';
import QuestionTitle from '../../../mission/scenes/missionSelected/ActiveMission/SteppedQuestion/QuestionTitle';
import useSendPostersModal from './hooks/useSendPostersModal';
import { dataSchema, layoutSchema } from './constants';
import useStyles from './sendPostersModal.styles';

const SendPostersModal = ({ onClose, content }) => {
  const styles = useStyles();
  const { onSubmit, isSubmitting } = useSendPostersModal(onClose);
  const { title, subtitle, disclaimer } = content;

  return (
    <div className={styles.container}>
      <div className={styles.button} onClick={onClose}>
        <TimesIcon />
      </div>
      <div className={styles.content}>
        <div
          className={clsx(styles.formWrapper, isSubmitting && styles.fadeOut)}
        >
          <QuestionTitle title={title} />
          <QuestionSubtitle className={styles.subtitle} subTitle={subtitle} />
          <Form
            autoComplete="on"
            className={styles.form}
            data={dataSchema}
            layout={layoutSchema}
            onSubmit={onSubmit}
          />
          <Typography
            className={styles.disclaimer}
            component="div"
            variant="bodySmall"
          >
            <Markup content={disclaimer} />
          </Typography>
        </div>
        <LoadingOverlay
          className={clsx(styles.loading, isSubmitting && styles.fadeIn)}
        />
      </div>
    </div>
  );
};

SendPostersModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  content: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    disclaimer: PropTypes.string.isRequired,
  }).isRequired,
};

export default memo(SendPostersModal);
