import { createUseStyles } from 'react-jss';

import styling from '../../../services/styling';
import { variantMapping } from '../../Typography/constants';

export default createUseStyles(
  {
    input: {
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
      boxSizing: 'border-box',
      border: 'none',
      padding: [10, 20, 10, 0],
      textIndent: 20,
      ...variantMapping.inputValue.styles,
      background: [['none'], '!important'],
      color: [[variantMapping.inputValue.styles.color], '!important'],
      backgroundClip: [['content-box'], '!important'],
      appearance: 'none',
      textShadow: 'inherit',
      MozAppearance: 'textfield',
      '&:focus': {
        outline: 'none',
      },
      '&::-webkit-outer-spin-button,&::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
        margin: 0,
      },
      '&:-webkit-autofill,&:-webkit-autofill:focus': {
        transition: 'background-color 600000s 0s, color 600000s 0s',
      },
    },
  },
  { name: 'inputText' },
);
