import { createUseStyles } from 'react-jss';

import styling from '../../../../services/styling';
import brand from '../../../../static/images/brand.webp';

export default createUseStyles(
  {
    brand: {
      overflow: 'hidden',
      maxWidth: 611,
      width: '100%',
      backgroundImage: `url(${brand})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center top',
      margin: [0, 'auto'],
      transform: 'translateY(calc(-30% + 30px))',
      [styling.breakpoints.up('md')]: {
        transform: 'translateY(calc(-30% + 40px))',
      },
      '& > span': {
        height: 0,
        paddingBottom: '21.77%',
        display: 'block',
        transform: 'translateX(-200%)',
      },
    },
  },
  { name: 'brand' },
);
