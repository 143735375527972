import PropTypes from 'prop-types';
import clsx from 'clsx';

import styling from '../../services/styling';
import { TimesIcon } from '../Icons';
import Markup from '../Markup';
import SlideInOut from '../SlideInOut';
import Typography from '../Typography';
import useToast from './hooks/useToast';
import useStyles from './toast.styles';

const Toast = ({ variant, id, isIn, duration, title, body, hasTopToast }) => {
  const { onClose, onMouseEnter, onMouseLeave, onExited, isHovered } = useToast(
    id,
    isIn,
    duration,
    hasTopToast,
  );
  const styles = useStyles();

  return (
    <SlideInOut className={styles.wrapper} in={isIn} onExited={onExited}>
      <div
        className={clsx(styles.toast, styles[`variant-${variant}`])}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <button className={styles.closeButton} onClick={onClose} type="button">
          <TimesIcon
            color={styling.colors.theme.softBlack}
            height={19}
            width={19}
          />
        </button>
        <Typography className={styles.title} variant="toastTitle">
          {title}
        </Typography>
        {body && (
          <Typography variant="toastBody">
            <Markup content={body} />
          </Typography>
        )}
        <div
          className={clsx(
            styles.timerBar,
            duration > 0 && !isHovered && styles.timerBarAnimating,
          )}
          style={{
            '--toastTimerBarDuration': `${isHovered ? 0 : duration}ms`,
          }}
        />
      </div>
    </SlideInOut>
  );
};

Toast.propTypes = {
  variant: PropTypes.oneOf(['error', 'info']),
  id: PropTypes.string.isRequired,
  isIn: PropTypes.bool,
  duration: PropTypes.number,
  title: PropTypes.node.isRequired,
  body: PropTypes.node,
  hasTopToast: PropTypes.bool.isRequired,
};

Toast.defaultProps = {
  variant: 'error',
  duration: -1,
  isIn: false,
  body: undefined,
};

export default Toast;
