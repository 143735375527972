import { createUseStyles } from 'react-jss';

export default createUseStyles(
  {
    arrowHead: {
      marginLeft: 18,
      marginTop: 6,
      transform: 'rotate(0deg)',
      transition: 'transform .5s ease-in-out, color 200ms ease-in-out',
    },
    arrowHeadOpen: {
      transform: 'rotate(180deg)',
    },
  },
  { name: 'sOTFullWithArrowHeadDownIcon' },
);
