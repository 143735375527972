import PropTypes from 'prop-types';

import { asyncRequestStates } from '../../hooks/useAsyncRequest';
import useGlobalContent from '../../hooks/useGlobalContent';
import LoadingOverlay from '../LoadingOverlay';
import useStyles from './globalFetcher.styles';

const GlobalFetcher = ({ children }) => {
  const styles = useStyles();
  const { contentState } = useGlobalContent();

  if (contentState !== asyncRequestStates.SUCCESS) {
    return <LoadingOverlay hasBackground />;
  }

  return <div className={styles.routeWrapper}>{children}</div>;
};

GlobalFetcher.propTypes = {
  children: PropTypes.node.isRequired,
};

export default GlobalFetcher;
