import { createUseStyles } from 'react-jss';

import styling from '../../services/styling';

export default createUseStyles(
  {
    wrapper: {
      position: 'relative',
      '&:before': {
        content: '""',
        position: 'absolute',
        top: '50%',
        left: '50%',
        right: 0,
        bottom: 0,
        opacity: 0.5,
        background: `radial-gradient(circle, ${styling.colors.theme.pureBlack} 0%, transparent 60%)`,
        width: '200%',
        height: '200%',
        transform: 'translate(-50%, -50%)',
        zIndex: -1,
      },
    },
  },
  { name: 'loadingSpinner' },
);
