import PropTypes from 'prop-types';

import useFormSubmitButton from '../../../../../components/Form/FormSubmitButton/hooks/useFormSubmitButton';
import SlantedButton from '../../../../../components/SlantedButton';
import useStyles from './submitButton.styles';

const SubmitButton = ({ formState, getFormData, setFormState, onSubmit }) => {
  const { isDisabled, onClick } = useFormSubmitButton({
    formState,
    setFormState,
    onSubmit,
    getFormData,
  });
  const styles = useStyles();

  return (
    <div className={styles.wrapper}>
      <SlantedButton
        isDisabled={isDisabled}
        onClick={onClick}
        size="mdFixed"
        type="button"
      >
        Submit
      </SlantedButton>
    </div>
  );
};

SubmitButton.propTypes = {
  formState: PropTypes.oneOf(['CLEAN', 'SUBMITTING', 'ERROR']).isRequired,
  getFormData: PropTypes.func.isRequired,
  setFormState: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default SubmitButton;
