import breakpoints from './breakpoints';
import colors from './colors';
import fonts from './fonts';
import shadows from './shadows';
import sizes from './sizes';
import speeds from './speeds';
import zIndex from './zIndex';

export default {
  breakpoints,
  fonts,
  colors,
  shadows,
  sizes,
  speeds,
  zIndex,
};
