import { createUseStyles } from 'react-jss';

import styling from '../../services/styling';

export default createUseStyles(
  {
    noData: {
      color: styling.colors.theme.pureWhite,
    },
  },
  { name: 'animatedNumber' },
);
