import { createUseStyles } from 'react-jss';

import styling from '../../../../services/styling';

const ICON_SCALE = 0.75;

export default createUseStyles(
  {
    wrapper: {
      width: 'var(--missionTileWidth)',
      height: 'var(--missionTileHeight)',
      position: 'absolute',
    },
    iconWrapper: {
      position: 'relative',
      zIndex: 1,
      height: 100,
      pointerEvents: 'none',
      [styling.breakpoints.up('md')]: {
        height: 145,
      },
    },
    icon3DViewport: {
      width: '100%',
      height: '46%',
      position: 'absolute',
      top: 0,
      left: 0,
      transform: 'translate(0, -50%)',
      '& canvas': {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        height: [['100%'], '!important'],
        width: [['100%'], '!important'],
      },
    },
    titleWrapper: {
      position: 'relative',
      zIndex: 1,
      padding: [0, styling.sizes.gutteringSm],
      textTransform: 'uppercase',
      [styling.breakpoints.up('md')]: {
        padding: [0, styling.sizes.gutteringLg],
        marginTop: 25,
      },
    },
    aboutWrapper: {
      position: 'relative',
      zIndex: 1,
      padding: [0, styling.sizes.gutteringSm],
      [styling.breakpoints.up('md')]: {
        padding: [0, 60],
      },
    },
  },
  { name: 'missionTile' },
);
