export const rollbarEnvironment = () => {
  const { hostname } = window.location;
  const subdomain = hostname.split('.')[0];
  const releaseSpace = process.env.REACT_APP_RELEASE_SPACE;

  if (subdomain === 'theconspiracytest') {
    if (releaseSpace) {
      return `production-${releaseSpace}`;
    }
    return 'production';
  }
  if (subdomain === 'localhost') {
    return 'development';
  }
  return subdomain;
};
