import { createUseStyles } from 'react-jss';

import styling from '../../services/styling';

export default createUseStyles(
  {
    image: {
      opacity: 0,
      transition: [['opacity', '500ms']],
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
    },
    imageLoaded: {
      opacity: 1,
    },
  },
  { name: 'image' },
);
