export const generateHandleOnChange = (type, onChange, value) => {
  if (type === 'number') {
    const parsedNumber = parseInt(value, 10);
    if (Number.isNaN(parsedNumber)) {
      onChange('');
    } else {
      onChange(parsedNumber);
    }
    return;
  }
  if (type === 'email') {
    onChange(value.toLowerCase());
    return;
  }
  onChange(value);
};
