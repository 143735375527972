import PropTypes from 'prop-types';
import clsx from 'clsx';

import Typography from '../../Typography';
import useStyles from './inputLabel.styles';

const InputLabel = ({ isErrored, label, htmlFor }) => {
  const styles = useStyles();

  if (!label) return null;

  return (
    <Typography
      component="label"
      className={clsx(styles.label, isErrored && styles.error)}
      variant="inputLabel"
      htmlFor={htmlFor}
      withShadow
    >
      {label}
    </Typography>
  );
};

InputLabel.propTypes = {
  isErrored: PropTypes.bool.isRequired,
  label: PropTypes.string,
  htmlFor: PropTypes.string,
};

InputLabel.defaultProps = {
  label: undefined,
  htmlFor: undefined,
};

export default InputLabel;
