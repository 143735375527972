import { useContext, useMemo } from 'react';

import { MediaQueryContext } from '../../../../../../contexts/mediaQueryContext';

const useCaretDimension = () => {
  const { isDesktop } = useContext(MediaQueryContext);

  const dimensions = useMemo(
    () => ({
      width: isDesktop ? 13 : 20,
      height: isDesktop ? 22 : 33,
    }),
    [isDesktop],
  );

  return dimensions;
};

export default useCaretDimension;
