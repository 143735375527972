import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Interweave } from 'interweave';

import useMarkupWithMerging from './hooks/useMarkupWithMerging';

const renderFragmentElement = (props, children) =>
  React.createElement(Fragment, props, children);

const MarkupWithMerging = ({ content, noWrap, matcherNames, ...rest }) => {
  const { matchers } = useMarkupWithMerging(
    renderFragmentElement,
    matcherNames,
  );

  return (
    <Interweave
      {...rest}
      content={content}
      matchers={matchers}
      noWrap={noWrap}
    />
  );
};

MarkupWithMerging.propTypes = {
  content: PropTypes.string.isRequired,
  noWrap: PropTypes.bool,
  matcherNames: PropTypes.arrayOf(PropTypes.string),
};

MarkupWithMerging.defaultProps = {
  noWrap: true,
  matcherNames: [],
};

export default MarkupWithMerging;
