import { memo } from 'react';
import PropTypes from 'prop-types';

import Markup from '../../../components/Markup';
import MissionSelectSection from '../../../components/MissionSelectSection';
import TextSwish from '../../../components/TextSwish';
import Typography from '../../../components/Typography';
import useStyles from './metaMissionSelector.styles';

const MetaMissionSelector = ({ subtitle, missions }) => {
  const styles = useStyles();

  return (
    <>
      <Typography variant="title" withShadow>
        <TextSwish isCentered />
      </Typography>
      <Typography
        className={styles.subtitle}
        component="div"
        variant="subtitle"
        withShadow
      >
        <Markup content={subtitle} />
      </Typography>
      <MissionSelectSection missions={missions} includeMissionHrefPrefix />
    </>
  );
};

MetaMissionSelector.propTypes = {
  subtitle: PropTypes.string.isRequired,
  missions: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default memo(MetaMissionSelector);
