import PropTypes from 'prop-types';
import clsx from 'clsx';

import { TimesIcon } from '../../../Icons';
import ArrowHeadDown from '../../../Icons/ArrowHeadDown';
import useStyles from './arrowHeadWithTimesIcon.styles';

const ArrowHeadWithTimesIcon = ({ isOpen, color }) => {
  const styles = useStyles();

  if (isOpen) {
    return <TimesIcon />;
  }

  return (
    <ArrowHeadDown height={18} width={20} color={isOpen ? undefined : color} />
  );
};

ArrowHeadWithTimesIcon.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  color: PropTypes.string.isRequired,
};

export default ArrowHeadWithTimesIcon;
