import PropTypes from 'prop-types';

import Input from '../../Input';
import useField from './hooks/useField';

const Field = ({ formState, label, validation, ...rest }) => {
  const { enhancedLabel, inputProps } = useField({
    label,
    validation,
    formState,
  });

  return <Input {...rest} {...inputProps} label={enhancedLabel} />;
};

Field.propTypes = {
  formState: PropTypes.oneOf(['CLEAN', 'SUBMITTING', 'ERROR']).isRequired,
  label: PropTypes.string,
  validation: PropTypes.arrayOf(PropTypes.func),
};

Field.defaultProps = {
  label: '',
  validation: [],
};

export default Field;
