import { createUseStyles } from 'react-jss';

import styling from '../../../../services/styling';

export default createUseStyles(
  {
    wrapper: {
      position: 'absolute',
      bottom: 0,
      lineHeight: 0,
      width: '100%',
      maxHeight: 1200,
      [styling.breakpoints.up('md')]: {
        width: 2500,
        height: 723,
        left: '50%',
        transform: 'translate(-50%, 0)',
      },
    },
    video: {
      maxWidth: '100%',
      [styling.breakpoints.up('md')]: {
        maxHeight: 723,
      },
      '& video': {
        maxWidth: '100%',
        width: [['auto'], '!important'],
        height: [['unset'], '!important'],
        [styling.breakpoints.up('md')]: {
          height: [['100%'], '!important'],
        },
      },
    },
  },
  { name: 'footerVideo' },
);
