export const handleOnCueChange = (e, setCaption) => {
  const cues = e.currentTarget?.activeCues;

  if (cues.length > 0) {
    setCaption(cues[0].text);
  } else {
    setCaption('');
  }
};

export const handleOnPlayerMount = (playerRef, onCueChange, trackIndex) => {
  if (!playerRef) return undefined;
  const videoEl = playerRef?.getInternalPlayer();
  if (!videoEl) return undefined;

  const track = videoEl.textTracks?.[trackIndex];
  if (!track) return undefined;

  track.mode = 'hidden';
  track.addEventListener('cuechange', onCueChange);

  return () => {
    track.removeEventListener('cuechange', onCueChange);
  };
};
