import { getRandomIntBetween } from '../../services/helpers/number';

export const getInitialIndex = (missions, missionSlug) => {
  const foundMissionIndex = missions?.findIndex(
    (mission) => mission.slug === missionSlug,
  );

  if (foundMissionIndex >= 0) return foundMissionIndex;

  const foundStopTheStealIndex = missions?.findIndex(
    (mission) => mission.slug === 'stop-the-steal',
  );

  if (foundStopTheStealIndex >= 0) return foundStopTheStealIndex;

  return getRandomIntBetween(0, missions.length);
};
