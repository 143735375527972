import { useContext } from 'react';

import { PanelContext } from '../../../contexts/panelContext';

const usePanelRoot = () => {
  const { closePanel, openedPanel, panels } = useContext(PanelContext);

  return { closePanel, openedPanel, panels };
};

export default usePanelRoot;
