import { createUseStyles } from 'react-jss';

import styling from '../../../../services/styling';

export default createUseStyles(
  {
    wrapper: {
      position: 'relative',
    },
    formWrapper: {
      margin: [0, 'auto', 140],
      transition: [
        ['opacity', styling.speeds.skip, 'ease-in-out', styling.speeds.skip],
      ],
    },
    fadeOut: {
      opacity: 0,
      pointerEvents: 'none',
      transition: [['opacity', styling.speeds.skip, 'ease-in-out']],
    },
    loading: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      opacity: 0,
      pointerEvents: 'none',
      transition: [['opacity', styling.speeds.skip, 'ease-in-out']],
      zIndex: 2,
    },
    fadeIn: {
      opacity: 1,
      transition: [
        ['opacity', styling.speeds.skip, 'ease-in-out', styling.speeds.skip],
      ],
    },
  },
  { name: 'betaSignupForm' },
);
