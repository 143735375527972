import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { formStates } from '../constants';
import FormSubmitButton from '../FormSubmitButton';
import {
  generateFieldsFromDataAndLayout,
  generateHandleOnSubmit,
  getSubmitButton,
  getWidthsByFieldKey,
  handleClearFormData,
  handleFieldChange,
  handleFormErrorsChange,
  handleOnCaptchaVerified,
  validateFields,
} from '../logic';

const useForm = (data, layout, initialValues, onSubmit) => {
  const formResetRef = useRef(0);
  const [executeCaptchaRef, setExecuteCaptchaRef] = useState();
  const [formData, setFormData] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [formState, setFormState] = useState(formStates.CLEAN);
  const onSubmitArgsRef = useRef();

  const bumpFormResetRef = useCallback(() => {
    formResetRef.current += 1;
  }, []);

  const getFormData = useCallback(
    (additionalData = {}) => ({ ...additionalData, ...formData }),
    [formData],
  );

  const clearFormData = useCallback(
    () =>
      handleClearFormData(
        setFormData,
        initialValues,
        setFormState,
        bumpFormResetRef,
      ),
    [setFormData, initialValues, setFormState, bumpFormResetRef],
  );

  const onFieldChange = useCallback(
    (fieldKey) => handleFieldChange(fieldKey, setFormData),
    [setFormData],
  );

  const getFieldErrors = useCallback(
    (fieldKey) => formErrors?.[fieldKey],
    [formErrors],
  );

  const getFieldValue = useCallback(
    (fieldKey) => formData?.[fieldKey],
    [formData],
  );

  const getWidths = useCallback(
    (fieldKey) => getWidthsByFieldKey(layout.fields, fieldKey),
    [layout.fields],
  );

  const fields = useMemo(
    () => generateFieldsFromDataAndLayout(data.fields, layout.fields),
    [data.fields, layout.fields],
  );

  const SubmitButton = useMemo(
    () => getSubmitButton(layout?.form?.submitButton, FormSubmitButton),
    [layout?.form?.submitButton],
  );

  useEffect(() => {
    validateFields(fields, formData, setFormErrors);
  }, [fields, formData, setFormErrors]);

  useEffect(
    () => handleFormErrorsChange(formErrors, setFormState),
    [formErrors],
  );

  const registerExecuteCaptcha = useCallback(
    (execute) => {
      setExecuteCaptchaRef({ execute });
    },
    [setExecuteCaptchaRef],
  );

  const setOnSubmitArgsRef = useCallback((ref) => {
    onSubmitArgsRef.current = ref;
  }, []);

  const onCaptchaVerified = useCallback(
    (captchaResponse) =>
      handleOnCaptchaVerified(
        captchaResponse,
        onSubmit,
        onSubmitArgsRef.current,
      ),
    [onSubmit],
  );

  const handleOnSubmit = useCallback(
    (submittedFormData, callback) =>
      generateHandleOnSubmit({
        submittedFormData,
        callback,
        onSubmit,
        hasCaptcha: data?.form?.hasCaptcha,
        executeCaptchaRef: executeCaptchaRef?.execute,
        setOnSubmitArgsRef,
      }),
    [onSubmit, data?.form?.hasCaptcha, setOnSubmitArgsRef, executeCaptchaRef],
  );

  return {
    fields,
    formState,
    getFormData,
    setFormState,
    onFieldChange,
    getFieldErrors,
    getFieldValue,
    getWidths,
    registerExecuteCaptcha,
    handleOnSubmit,
    onCaptchaVerified,
    SubmitButton,
    clearFormData,
    formResetRef: formResetRef.current,
  };
};

export default useForm;
