import { createUseStyles } from 'react-jss';

import styling from '../../../../../services/styling';

export default createUseStyles(
  {
    wrapper: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      padding: 2,
      display: 'flex',
      background: styling.colors.theme.primaryLight,
      clipPath: `polygon(
        var(--tileBackgroundCorner) 0, 100% 0,
        100% calc(100% - var(--tileBackgroundCorner)), calc(100% - var(--tileBackgroundCorner)) 100%,
        0 100%, 0 var(--tileBackgroundCorner)
      )`,
      '&:before': {
        content: '""',
        height: '100%',
        flexGrow: 1,
        background: styling.colors.theme.pureBlack,
        clipPath: `polygon(
         var(--tileBackgroundInnerCorner) 0, 100% 0,
          100% calc(100% - var(--tileBackgroundInnerCorner)), calc(100% - var(--tileBackgroundInnerCorner)) 100%,
          0 100%, 0 var(--tileBackgroundInnerCorner)
        )`,
      },
    },
  },
  { name: 'tileBackground' },
);
