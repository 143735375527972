import PropTypes from 'prop-types';

import { asyncRequestStates } from '../../../hooks/useAsyncRequest';
import useGlobalContent from '../../../hooks/useGlobalContent';
import Markup from '../../Markup';
import Typography from '../../Typography';
import useStyles from './footer.styles';
import FooterMenu from './FooterMenu';
import FooterVideo from './FooterVideo';
import LogoStacks from './LogoStacks';

const Footer = ({ noFooterNav, includeHrefPrefix }) => {
  const styles = useStyles();
  const {
    content: { footer },
    contentState,
  } = useGlobalContent();
  const { caption } = footer || {};

  return (
    <footer className={styles.footer}>
      <div className={styles.footerMenu}>
        <FooterVideo />
        {!noFooterNav && <FooterMenu includeHrefPrefix={includeHrefPrefix} />}
      </div>
      <div className={styles.footerBase}>
        <LogoStacks />
        {contentState === asyncRequestStates.SUCCESS && (
          <Typography component="div" variant="caption">
            <Markup content={caption} />
          </Typography>
        )}
      </div>
    </footer>
  );
};

Footer.propTypes = {
  noFooterNav: PropTypes.bool,
  includeHrefPrefix: PropTypes.bool.isRequired,
};

Footer.defaultProps = {
  noFooterNav: false,
};

export default Footer;
