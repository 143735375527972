import { createUseStyles } from 'react-jss';

export default createUseStyles(
  {
    subtitle: {
      maxWidth: 822,
      margin: [0, 'auto'],
      textAlign: 'center',
    },
  },
  { name: 'metaMissionSelector' },
);
