import PropTypes from 'prop-types';

import styling from '../../../services/styling';
import IconSvg from '../../IconSvg';

const Download = ({ color, height, width, ...rest }) => (
  <IconSvg
    {...rest}
    color={color}
    height={height}
    viewBox="0 0 26 26"
    width={width}
  >
    <g fill="currentColor">
      <path d="M24.7305 11.7305C24.0297 11.7305 23.4609 12.2992 23.4609 13C23.4609 18.7688 18.7687 23.4609 13 23.4609C7.23125 23.4609 2.53906 18.7688 2.53906 13C2.53906 12.2992 1.97031 11.7305 1.26953 11.7305C0.56875 11.7305 0 12.2992 0 13C0 16.4734 1.35078 19.7387 3.80859 22.1914C6.26641 24.6492 9.52656 26 13 26C16.4734 26 19.7387 24.6492 22.1914 22.1914C24.6492 19.7336 26 16.4734 26 13C26 12.2992 25.4312 11.7305 24.7305 11.7305Z" />
      <path d="M11.26 17.4484C11.7271 17.9156 12.3467 18.1695 13.0018 18.1695C13.6619 18.1695 14.2814 17.9105 14.7436 17.4484L17.9377 14.2543C18.4354 13.7566 18.4354 12.9543 17.9377 12.4566C17.44 11.959 16.6377 11.959 16.14 12.4566L14.2713 14.3305V1.26953C14.2713 0.56875 13.7025 0 13.0018 0C12.301 0 11.7322 0.56875 11.7322 1.26953V14.3305L9.8584 12.4566C9.36074 11.959 8.5584 11.959 8.06074 12.4566C7.56309 12.9543 7.56309 13.7566 8.06074 14.2543L11.26 17.4484Z" />
    </g>
  </IconSvg>
);

Download.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};

Download.defaultProps = {
  color: styling.colors.theme.pureWhite,
  height: 26,
  width: 26,
};

export default Download;
