import { createUseStyles } from 'react-jss';

import styling from '../../services/styling';
import americanCaptainRegularWoff from '../../static/fonts/american_captain.woff';
import americanCaptainRegularWoff2 from '../../static/fonts/american_captain.woff2';

export default createUseStyles(
  {
    '@font-face': [
      {
        fontFamily: 'american_captainregular',
        src: `url(${americanCaptainRegularWoff2}) format('woff2'),
             url(${americanCaptainRegularWoff}) format('woff')`,
        fontWeight: 400,
        fontStyle: 'normal',
      },
    ],
    '@global': {
      '.lock-scroll-panel,.lock-scroll-mission,.lock-scroll-alert': {
        overflow: 'hidden',
      },
      html: {
        fontSize: '62.5%',
        height: '-webkit-fill-available',
      },
      body: {
        lineHeight: '1.6em',
        fontFamily: styling.fonts.barlow.fontFamily,
        backgroundColor: styling.colors.context.background,
        color: styling.colors.context.text,
        overflowX: 'hidden',
        minHeight: '-webkit-fill-available',
        display: 'flex',
        flexDirection: 'column',
        fallbacks: {
          minHeight: '100vh',
        },
      },
      '#root': {
        flexGrow: 1,
        flexShrink: 1,
        display: 'flex',
        flexDirection: 'column',
      },
      'h1,h2,h3,h4,h5,h6,p': {
        margin: 0,
      },
      a: {
        textDecoration: 'none',
      },
      '*': {
        // Firefox
        '&::-moz-placeholder': {
          color: styling.colors.theme.pureWhiteFadedMin,
          opacity: 1, // Override Firefox's unusual default opacity, see https://github.com/twbs/bootstrap/pull/11526
          textShadow: 'none',
        },
        '&:-moz-placeholder': {
          color: styling.colors.theme.pureWhiteFadedMin,
          opacity: 1, // Override Firefox's unusual default opacity, see https://github.com/twbs/bootstrap/pull/11526
          textShadow: 'none',
        },
        '&:-ms-input-placeholder': {
          color: styling.colors.theme.pureWhiteFadedMin,
          textShadow: 'none',
        }, // Internet Explorer 10+
        '&::-webkit-input-placeholder': {
          color: styling.colors.theme.pureWhiteFadedMin,
          textShadow: 'none',
        }, // Safari and Chrome
        '&::placeholder': {
          color: styling.colors.theme.pureWhiteFadedMin,
          textShadow: 'none',
        },
      },
    },
  },
  { name: 'global' },
);
