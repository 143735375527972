import { createUseStyles } from 'react-jss';

import styling from '../../../services/styling';
import { CLIP_PATH_SIZE_LG, CLIP_PATH_SIZE_MD } from './constants';

export default createUseStyles(
  {
    wrapper: {
      '--wrapperClipPathSize': `${CLIP_PATH_SIZE_MD}px`,
      position: 'relative',
      minHeight: 70,
      transition: styling.speeds.jog,
      boxShadow: styling.shadows.theme.transparent,
      color: styling.colors.context.text,
      padding: [1, 0],
      marginBottom: 63,
      display: 'flex',
      flexDirection: 'column',
      borderRadius: `calc(var(--wrapperClipPathSize) * 2) 0 calc(var(--wrapperClipPathSize) * 2) 0`,
      textShadow: styling.shadows.theme.primaryMd,
    },
    input: {
      position: 'relative',
      flexGrow: 1,
      lineHeight: 0,
      width: '100%',
    },
    variantcolored: {
      color: styling.colors.theme.primaryLight,
      '& > $background': {
        '--inputBorderColor': styling.colors.theme.primaryLight,
      },
    },
    wrapperLg: {
      '--wrapperClipPathSize': `${CLIP_PATH_SIZE_LG}px`,
    },
    focused: {
      boxShadow: styling.shadows.theme.primaryLg,
      color: styling.colors.theme.primaryLight,
      '& > $background': {
        // keep a radial gradient so we dont "flash"
        background: `radial-gradient(circle, ${styling.colors.theme.pureBlack} 0%, ${styling.colors.theme.pureBlack} 100%)`,
        '--inputBorderColor': styling.colors.theme.primaryLight,
      },
    },
    errored: {
      boxShadow: styling.shadows.theme.errorLg,
      '& > $background': {
        '--inputBorderColor': styling.colors.theme.error,
      },
    },
    disabled: {
      opacity: 0.33,
    },
    background: {
      '--inputBorderColor': styling.colors.theme.pureWhite,
      transition: styling.speeds.jog,
      boxSizing: 'border-box',
      position: 'absolute',
      left: 0,
      top: 0,
      zIndex: 0,
      pointerEvents: 'none',
      height: '100%',
      width: '100%',
      background: `radial-gradient(100% 100% at 50% 50%, transparent 0%, ${styling.colors.theme.pureBlackFadedMin} 100%),
			radial-gradient(100% 100% at 50% 50%, transparent 0%, ${styling.colors.theme.pureBlackFadedMin} 100%)`,
      border: [1, 'solid', 'var(--inputBorderColor)'],
      clipPath: `polygon(var(--wrapperClipPathSize) 0, 100% 0, 100% calc(100% - var(--wrapperClipPathSize)), calc(100% - var(--wrapperClipPathSize)) 100%, 0 100%, 0 var(--wrapperClipPathSize))`,
      '&:before,&:after': {
        content: '""',
        background: 'var(--inputBorderColor)',
        width: 1.5,
        height: 'calc(var(--wrapperClipPathSize) * 2)',
        position: 'absolute',
        transform: 'rotate(45deg)',
        transition: styling.speeds.jog,
      },
      '&:before': {
        top: -1,
        left: 'calc(var(--wrapperClipPathSize) - 1px)',
        transformOrigin: '50% 0%',
      },
      '&:after': {
        bottom: -1,
        right: 'calc(var(--wrapperClipPathSize) - 1px)',
        transformOrigin: '50% 100%',
      },
    },
    solidBackground: {
      background: `radial-gradient(circle, ${styling.colors.theme.pureBlack} 0%, ${styling.colors.theme.pureBlack} 100%)`,
    },
    errors: {
      position: 'absolute',
      top: 'calc(100% + 15px)',
      left: 0,
      height: 0,
    },
  },
  { name: 'inputWrapper' },
);
