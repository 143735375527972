import { useMemo } from 'react';

import { appendParamsIfTheyExist } from '../logic';

const useInternalLink = (to) => {
  const appendedTo = useMemo(() => appendParamsIfTheyExist(to), [to]);

  return appendedTo;
};

export default useInternalLink;
