import { useCallback, useMemo, useState } from 'react';
import { useGesture } from '@use-gesture/react';

import useTileDimensions from '../MissionTile/hooks/useTileDimensions';

const useCarouselSelection = (missions, initialIndex) => {
  const [dragListeners, setDragListeners] = useState([]);

  const tileDimensions = useTileDimensions();

  const [selection, setSelection] = useState(initialIndex);
  const next = useCallback(() => setSelection((i) => i + 1));
  const prev = useCallback(() => setSelection((i) => i - 1));

  const handleTapEvent = useCallback((pageX) => {
    const pageWidth = window.document.documentElement.clientWidth;
    const pageCenter = pageWidth / 2;
    const halfTileWithSpacing =
      tileDimensions.width / 2 + tileDimensions.spacing;
    if (pageX > pageCenter + halfTileWithSpacing) {
      next();
    } else if (pageX < pageCenter - halfTileWithSpacing) {
      prev();
    }
  }, []);

  const bindDrag = useGesture(
    {
      onDrag: ({ event, down, movement: [x, y], tap }) => {
        dragListeners.forEach((l) => l(down, [x, y]));

        if (tap) {
          handleTapEvent(event.pageX);
        }
      },
      onDragEnd: ({ event, movement: [x], tap }) => {
        dragListeners.forEach((l) => l(false, [x]));

        if (tap) {
          return;
        }

        const dist = Math.abs(x) || 1;
        const dir = x / dist;

        // If we are inside the main tile, move one space
        if (dist <= (tileDimensions.width + tileDimensions.spacing) / 2) {
          setSelection((i) => i - dir);
          return;
        }

        const distPastFirst =
          dist - (tileDimensions.width + tileDimensions.spacing) / 2;
        const numExtraTiles =
          distPastFirst /
          (tileDimensions.width * tileDimensions.scale +
            tileDimensions.spacing);

        const numJump = Math.floor(1 + numExtraTiles) * dir;

        setSelection((i) => i - numJump);
      },
    },
    {
      drag: {
        filterTaps: true,
        tapsThreshold: 50,
      },
    },
  );

  const dragApi = useMemo(
    () => ({
      register: (listner) => setDragListeners((dls) => [...dls, listner]),
      unregister: (listner) =>
        setDragListeners((dls) => dls.filter((l) => l !== listner)),
    }),
    [],
  );

  const total = missions.length;
  const { slug } = missions[((selection % total) + total) % total];

  return {
    selection,
    next,
    prev,
    setSelection,
    slug,
    bindDrag,
    dragApi,
  };
};

export default useCarouselSelection;
