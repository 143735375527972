import { useCallback, useContext, useReducer, useRef } from 'react';

import { useErrorCapture } from '../../contexts/errorCaptureContext';
import { ToastContext } from '../../contexts/toastContext';
import cacheFactory from '../../services/caching';
import { apiRequest } from '../../services/requests';
import { constructCacheKey, getPrettyErrorMessage } from './logic';

class RequestError extends Error {
  constructor(message) {
    super(message);
    this.name = 'RequestError';
  }
}

const useRequestsManagement = () => {
  const { registerToast } = useContext(ToastContext);
  const requestsCache = useRef(cacheFactory());
  const inflightRequests = useRef({});
  const errorCapture = useErrorCapture();

  const registerCancel = (requestIdempotencyKey) => (cancelFn) => {
    inflightRequests.current[requestIdempotencyKey] = cancelFn;
  };

  const wrapRequest = async (requestIdempotencyKey, config) => {
    try {
      const request = apiRequest(config, registerCancel(requestIdempotencyKey));
      const response = await request;

      delete inflightRequests.current[requestIdempotencyKey];

      if (config.method === 'GET' && response.status === 200) {
        requestsCache.current.setValueForKey(
          requestIdempotencyKey,
          response.data,
        );
      }
      return response.data;
    } catch (e) {
      delete inflightRequests.current[requestIdempotencyKey];
      if (
        e?.constructor?.name === 'CanceledError' ||
        e.message === 'canceled' ||
        e.message === 'Request aborted'
      ) {
        return Promise.reject(e);
      }

      registerToast(`requestError-${requestIdempotencyKey}`, {
        variant: 'error',
        ...getPrettyErrorMessage(e),
      });
      try {
        throw new RequestError(e.message);
      } catch (error) {
        errorCapture.error(`Request Error - ${e.message}`, error, {
          message: e.message,
          body: e?.response?.data?.errorText || e.message,
          url: e.request.responseURL,
          config,
        });
      }
      throw e;
    }
  };

  const performRequest = (config, useCache = true) => {
    const requestIdempotencyKey = constructCacheKey(config);
    const cacheValue = requestsCache.current.getValueForKey(
      requestIdempotencyKey,
    );
    // if we get a cache hit, return it
    if (useCache && cacheValue !== undefined) {
      return Promise.resolve(cacheValue);
    }

    // cancel previous requests for the same key
    inflightRequests.current[requestIdempotencyKey]?.();

    return wrapRequest(requestIdempotencyKey, config);
  };

  return [performRequest];
};

export default useRequestsManagement;
