export const createYoutubeEmbedUrl = (embedId) =>
  `https://www.youtube-nocookie.com/watch?v=${embedId}`;

export const createVimeoEmbedUrl = (embedId) => `https://vimeo.com/${embedId}`;

export const getPlayerSourceAndTracks = (mission, question, step) => {
  const missionSlug = mission?.slug;
  const questionStep = question?.questionSteps?.[step];
  const youtubeEmbedId = questionStep?.meta?.youtubeEmbedIds?.[missionSlug];
  const vimeoEmbedId = questionStep?.meta?.vimeoEmbedIds?.[missionSlug];
  const video = questionStep?.meta?.video?.[missionSlug]?.desktop;

  if (vimeoEmbedId) {
    return [createVimeoEmbedUrl(vimeoEmbedId)];
  }
  if (youtubeEmbedId) {
    return [createYoutubeEmbedUrl(youtubeEmbedId)];
  }
  if (video) {
    return [video, questionStep?.meta?.video?.[missionSlug]?.tracks];
  }
  return [null];
};
