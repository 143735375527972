import React from 'react';

import LoadingOverlay from '../../../components/LoadingOverlay';
import { ErrorBoundary } from '../../../contexts/errorCaptureContext';
import FourOhFour from '../../404';
import Meta from '..';
import routes from './metaRoutes';

const routeElementMap = {
  meta: Meta,
  fourOhFour: FourOhFour,
};

const NoView = () => null;

const getComponentForRouteKey = (routeKey, title) => {
  if (routeElementMap[routeKey]) {
    const Component = routeElementMap[routeKey];
    return (
      <React.Suspense fallback={<LoadingOverlay />}>
        <Component title={title} />
      </React.Suspense>
    );
  }
  return <NoView />;
};

const processRoutes = (routesObject) =>
  Object.keys(routesObject).map((routeKey) => {
    const { childRoutes, title } = routesObject[routeKey];

    return {
      ...routesObject[routeKey],
      children: childRoutes ? processRoutes(childRoutes) : undefined,
      element: getComponentForRouteKey(routeKey, title),
      ErrorBoundary,
    };
  });

const routesWithViews = processRoutes(routes);

export default routesWithViews;
