import { useCallback, useMemo } from 'react';

import { formStates } from '../../constants';
import { getIsDisabled, handleOnClick, handleOnClickCallback } from '../logic';

const useFormSubmitButton = ({
  formState,
  setFormState,
  onSubmit,
  getFormData,
  clearFormData,
}) => {
  const isDisabled = useMemo(() => getIsDisabled(formState), [formState]);

  const onClickCallback = useCallback(
    (shouldReset) =>
      handleOnClickCallback(shouldReset, setFormState, clearFormData),
    [clearFormData, setFormState],
  );

  const onClick = useCallback(
    () =>
      handleOnClick({
        onSubmit,
        formState,
        setFormState,
        getFormData,
        onClickCallback,
      }),
    [onSubmit, formState, setFormState, getFormData, onClickCallback],
  );

  return {
    isDisabled,
    onClick,
  };
};

export default useFormSubmitButton;
