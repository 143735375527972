export const constructCacheKey = ({ method = 'GET', url, data = {} }) => {
  const { 'g-recaptcha-response': deleted, ...rest } = data;
  return `${method}|${url}|${JSON.stringify(rest)}`;
};

const removeStoryErrorText = (message = '') =>
  message.replace(/^story.+\[(.+)\]/, '$1');

export const getPrettyErrorMessage = (errorObject) => {
  const titleText =
    errorObject?.response?.data?.errorType || errorObject.request?.statusText;
  const bodyMessage =
    removeStoryErrorText(errorObject?.response?.data?.errorText) ||
    errorObject.message;

  return {
    title: `Error - ${errorObject.request.status} ${titleText}`,
    body: `${bodyMessage}<br /><small>${errorObject.request.responseURL}</small>`,
  };
};
