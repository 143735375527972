import { createUseStyles } from 'react-jss';

import styling from '../../../../services/styling';

export default createUseStyles(
  {
    wrapper: {
      position: 'absolute',
      top: 117,
      left: 0,
      right: 0,
      bottom: 0,
      overflow: 'auto',
      display: 'flex',
      flexDirection: 'column',
      padding: [10, 40, 43],
    },
    sotNav: {
      display: 'flex',
      flexDirection: 'column',
      gap: '29px',
    },
  },
  { name: 'sOTNavPanel' },
);
