import PropTypes from 'prop-types';

import styling from '../../../services/styling';
import IconSvg from '../../IconSvg';

const VolumeOn = ({ color, height, width, ...rest }) => (
  <IconSvg
    {...rest}
    color={color}
    height={height}
    viewBox="0 0 32 32"
    width={width}
  >
    <g fill="none" stroke="currentColor">
      <path d="M19.75005,3 L19.75005,29 L7.75005,22 L7.75005,21.9999 L2.75005,21.9999 L2.75005,10.9999 L7.75005,10.9999 L7.75005,11 L19.75005,3 Z M25.79695,8.4532 C27.90895,10.2862 29.24995,12.9842 29.24995,16.0002 C29.24995,19.0162 27.90895,21.7142 25.79695,23.5472 L25.79695,23.5472 Z M22.95955,11.2908 C24.35255,12.3888 25.25055,14.0878 25.25055,15.9998 C25.25055,17.9118 24.35255,19.6108 22.95955,20.7098 L22.95955,20.7098 Z" />
    </g>
  </IconSvg>
);

VolumeOn.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};

VolumeOn.defaultProps = {
  color: styling.colors.theme.secondaryDark,
  height: 32,
  width: 32,
};

export default VolumeOn;
