import PropTypes from 'prop-types';
import clsx from 'clsx';

import LoadWhenInView from '../LoadWhenInView';
import useImage from './hooks/useImage';
import useStyles from './image.styles';

const Image = ({ src, className, forceInView }) => {
  const { isLoaded, isErrored, renderableSrc } = useImage(src);
  const styles = useStyles();

  return (
    <LoadWhenInView forceInView={forceInView}>
      <div
        className={clsx(
          className,
          styles.image,
          isLoaded && styles.imageLoaded,
        )}
        style={{
          backgroundImage: `url(${renderableSrc})`,
        }}
      />
    </LoadWhenInView>
  );
};

Image.propTypes = {
  src: PropTypes.string.isRequired,
  className: PropTypes.string,
  forceInView: PropTypes.bool,
};

Image.defaultProps = {
  className: undefined,
  forceInView: false,
};

export default Image;
