import { createUseStyles } from 'react-jss';

export default createUseStyles(
  {
    color: {
      color: 'var(--iconSvgColor)',
      transition: 'color 200ms ease-in-out',
    },
  },
  { name: 'iconSvg' },
);
