import { useMemo } from 'react';

import { calculateStyle } from '../logic';

const useIconSvg = (color) => {
  const style = useMemo(() => calculateStyle(color), [color]);

  return {
    style,
  };
};

export default useIconSvg;
