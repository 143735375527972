import { useEffect, useRef, useState } from 'react';

import { getRandomIntBetween } from '../../../../../../services/helpers/number';
import { posterImages } from '../../constants';

const useMetaCarousel = () => {
  const intervalRef = useRef();
  const [selectedIndex, setSelectedIndex] = useState(
    getRandomIntBetween(0, posterImages.length),
  );

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setSelectedIndex(
        (currentIndex) => (currentIndex + 1) % posterImages.length,
      );
    }, 6000);
    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  return {
    selectedIndex,
  };
};

export default useMetaCarousel;
