import { useCallback, useContext, useEffect, useRef, useState } from 'react';

import { AlertContext } from '../../../contexts/alertContext';
import { handleOnClose, handleOnExited } from '../logic';

const useAlert = (id, isIn, hasTopAlert) => {
  const { closeAlert, deregisterAlert } = useContext(AlertContext);

  const onClose = useCallback(
    () => handleOnClose(id, closeAlert),
    [id, closeAlert],
  );

  const onExited = useCallback(
    () => handleOnExited(id, deregisterAlert, hasTopAlert),
    [id, deregisterAlert, hasTopAlert],
  );

  return {
    onClose,
    onExited,
  };
};

export default useAlert;
