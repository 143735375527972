import PropTypes from 'prop-types';
import clsx from 'clsx';

import InputWrapper from '../InputWrapper';
import useInputTextarea from './hooks/useInputTextarea';
import useStyles from './inputTextarea.styles';

const InputTextarea = ({
  className,
  required,
  disabled,
  errors,
  isFocused,
  inputRef,
  onBlur,
  onChange,
  onFocus,
  value,
  meta,
  name,
  size,
}) => {
  const styles = useStyles();
  const { handleOnChange } = useInputTextarea(onChange);

  return (
    <InputWrapper
      className={className}
      errors={errors}
      isDisabled={disabled}
      isFocused={isFocused}
      size={size}
    >
      <textarea
        ref={inputRef}
        autoFocus={meta?.autoFocus}
        className={clsx(
          styles.input,
          size === 'lg' && styles.inputLg,
          meta?.canResize && styles.inputResize,
        )}
        disabled={disabled}
        onBlur={onBlur}
        onChange={handleOnChange}
        onFocus={onFocus}
        placeholder={meta?.placeholder}
        required={required}
        rows={meta?.rows || '4'}
        value={value || ''}
        name={name}
      />
    </InputWrapper>
  );
};

InputTextarea.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.string),
  isFocused: PropTypes.bool,
  inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  meta: PropTypes.shape({
    rows: PropTypes.number,
    canResize: PropTypes.bool,
    autoFocus: PropTypes.bool,
    placeholder: PropTypes.string,
  }),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  size: PropTypes.oneOf(['md', 'lg']),
};

InputTextarea.defaultProps = {
  className: undefined,
  disabled: false,
  required: false,
  errors: undefined,
  isFocused: false,
  value: undefined,
  meta: undefined,
  name: undefined,
  size: 'md',
  inputRef: null,
};

export default InputTextarea;
