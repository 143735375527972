import PropTypes from 'prop-types';

import styling from '../../../services/styling';
import IconSvg from '../../IconSvg';

const ArrowHeadDown = ({ color, height, width, ...rest }) => (
  <IconSvg
    {...rest}
    color={color}
    height={height}
    viewBox="0 0 13 12"
    width={width}
  >
    <polygon
      fill="currentColor"
      points="6.23372155 11.8478043 3.10108748 6.36919496 -0.0322682363 0.891314202 6.23372155 3.64227547 12.4997113 0.891314202 9.36707727 6.36919496"
    />
  </IconSvg>
);

ArrowHeadDown.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};

ArrowHeadDown.defaultProps = {
  color: styling.colors.theme.pureWhite,
  height: 12,
  width: 13,
};

export default ArrowHeadDown;
