import poster01 from '../../../../static/images/poster_01.webp';
import poster02 from '../../../../static/images/poster_02.webp';
import poster03 from '../../../../static/images/poster_03.webp';
import poster04 from '../../../../static/images/poster_04.webp';
import poster05 from '../../../../static/images/poster_05.webp';
import poster06 from '../../../../static/images/poster_06.webp';

export const posterImages = [
  poster02,
  poster01,
  poster03,
  poster04,
  poster05,
  poster06,
];
