export const handleOnClose = (toastId, onClose) => {
  onClose(toastId);
};

export const handleMouseEnterLeave = (setIsHovered, hoverState) => {
  setIsHovered(hoverState);
};

export const handleAutoClose = ({
  isIn,
  isHovered,
  duration,
  onClose,
  setCloseTimerRef,
  closeTimerRef,
}) => {
  if (isHovered || !isIn || duration < 0) {
    // cancel close timer
    if (closeTimerRef) {
      window.clearTimeout(closeTimerRef);
    }
    return;
  }
  // start close timer
  const closeTimer = window.setTimeout(onClose, duration);
  setCloseTimerRef(closeTimer);
};

export const handleOnMount = (setIsHovered, closeTimerRef) => {
  window.setTimeout(() => {
    setIsHovered(false);
  }, 0);

  return () => {
    if (closeTimerRef) {
      window.clearTimeout(closeTimerRef);
    }
  };
};

export const handleOnExited = (id, deregisterToast, hasTopToast) => {
  if (hasTopToast) return;
  deregisterToast(id);
};
