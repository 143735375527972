import { createUseStyles } from 'react-jss';

import styling from '../../services/styling';

export default createUseStyles(
  {
    swish: {
      background: `linear-gradient(90deg, ${styling.colors.theme.primary} 0%, transparent 100%)`,
      height: 1,
      width: 70,
      margin: [22, 0],
      [styling.breakpoints.up('md')]: {
        height: 2,
        width: 100,
        margin: [30, 0],
      },
    },
    centered: {
      margin: [22, 'auto'],
      [styling.breakpoints.up('md')]: {
        margin: [30, 'auto'],
      },
    },
  },
  { name: 'textSwish' },
);
