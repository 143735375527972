import { createContext, useMemo } from 'react';
import PropTypes from 'prop-types';

import LoadingOverlay from '../components/LoadingOverlay';
import { asyncRequestStates } from '../hooks/useAsyncRequest';
import useInviteManagement from '../hooks/useInviteManagement';

export const InviteContext = createContext({
  contentState: asyncRequestStates.DEFAULT,
  invitedUser: undefined,
});

export const InviteProvider = ({ children }) => {
  const value = useInviteManagement();
  const { contentState } = value;

  if (
    contentState !== asyncRequestStates.SUCCESS &&
    process.env.REACT_APP_RELEASE_SPACE === 'beta'
  ) {
    return <LoadingOverlay hasBackground />;
  }

  return (
    <InviteContext.Provider value={value}>{children}</InviteContext.Provider>
  );
};

InviteProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
