import { createUseStyles } from 'react-jss';

export default createUseStyles(
  {
    wrapper: {
      maxWidth: 748,
      width: '100%',
      margin: [0, 'auto'],
    },
  },
  { name: 'form' },
);
