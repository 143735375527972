const customGlow = {
  uniforms: {
    tDiffuse: { value: null },
    sampleDirections: { value: 32.0 },
    samples: { value: 20.0 },
    uvRadius: { value: 0.1 },
    brightness: { value: 1.0 },
  },

  vertexShader: `
		varying vec2 vUv;
		void main() {
			vUv = uv;
			gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
		}`,

  fragmentShader: `
        uniform sampler2D tDiffuse;
        uniform float uvRadius;
        uniform float sampleDirections;
        uniform float samples;
        uniform float brightness;
        varying vec2 vUv;
        void main() {
            float twoPi = 6.2832;

            // Pixel colour
            vec4 mainPoint = texture(tDiffuse, vUv);
            if (mainPoint.a >= 0.5) {
                gl_FragColor = mainPoint;
            } else {
                float alphaSample = 0.0;

                // Blur
                for (float d = 0.0; d < twoPi; d += twoPi / sampleDirections)
                {
                    for(float i=1.0/samples; i<=1.0; i+=1.0/samples)
                    {
                        alphaSample += texture( tDiffuse, vUv + vec2(cos(d), sin(d)) * uvRadius * i).a;
                    }
                }

                vec3 glowColor = vec3(255.0, 110.0, 43.0) / 255.0;
                float normalAlpha = alphaSample * brightness / (samples * sampleDirections);
                gl_FragColor = vec4(glowColor*normalAlpha, normalAlpha);
            }            
        }`,
};

export default customGlow;
