import { createUseStyles } from 'react-jss';

import styling from '../../../../services/styling';
import {
  desktopTileDimensions,
  mobileTileDimensions,
} from '../MissionTile/constants';

export default createUseStyles(
  {
    wrapper: {
      position: 'relative',
      textAlign: 'center',
      marginBottom: 104,
      [styling.breakpoints.up('md')]: {
        marginBottom: 180,
      },
    },
    centerPoint: {
      position: 'relative',
      display: 'inline-block',
      textAlign: 'center',
      width: '100%',
      maxWidth: mobileTileDimensions.width + 2 * desktopTileDimensions.spacing,
      [styling.breakpoints.up('md')]: {
        maxWidth:
          desktopTileDimensions.width + 2 * desktopTileDimensions.spacing,
      },
    },
    prevButton: {
      position: 'absolute',
      zIndex: 1,
      top: '50%',
      left: 0,
      transform: 'translate(-100%, -50%)',

      [styling.breakpoints.up('md')]: {
        left: -90,
        top: -95,
        transform: 'translate(-100%, 0)',
      },
    },
    nextButton: {
      position: 'absolute',
      top: '50%',
      right: 0,
      transform: 'translate(100%, -50%)',

      [styling.breakpoints.up('md')]: {
        right: -90,
        top: -95,
        transform: 'translate(100%, 0)',
      },
    },
    button: {
      display: 'inline-block',
    },
  },
  { name: 'carousel-navigation-bar' },
);
