import PropTypes from 'prop-types';

import styling from '../../../services/styling';
import IconSvg from '../../IconSvg';

const Burger = ({ color, height, width, ...rest }) => (
  <IconSvg
    {...rest}
    color={color}
    height={height}
    viewBox="0 0 36 26"
    width={width}
  >
    <g
      fill="none"
      fillRule="evenodd"
      stroke="none"
      strokeLinecap="round"
      strokeWidth="1"
    >
      <g stroke="currentColor" transform="translate(1.000000, -0.000100)">
        <line x1="0" x2="33.542" y1="0.5" y2="0.5" />
        <line x1="0" x2="33.542" y1="25.4752" y2="25.4752" />
        <line x1="0" x2="33.542" y1="12.9877" y2="12.9877" />
      </g>
    </g>
  </IconSvg>
);

Burger.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};

Burger.defaultProps = {
  color: styling.colors.theme.secondaryLight,
  height: 26,
  width: 36,
};

export default Burger;
