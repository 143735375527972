import { createUseStyles } from 'react-jss';

import styling from '../../../services/styling';
import footerFade from '../../../static/images/footer_fade.webp';
import footerTrees from '../../../static/images/footer_trees.webp';

export default createUseStyles(
  {
    footer: {
      maxWidth: styling.breakpoints.values.xl,
      margin: [0, 'auto'],
      position: 'relative',
      zIndex: 1,
    },
    footerMenu: {
      position: 'relative',
      minHeight: 327,
      display: 'flex',
      alignItems: 'end',
      backgroundColor: styling.colors.theme.pureBlack,
    },
    footerBase: {
      position: 'relative',
      zIndex: 2,
      textAlign: 'center',
      background: styling.colors.theme.pureBlack,
      padding: [112, 35, 67],
      [styling.breakpoints.up('md')]: {
        padding: [100, 35, 35],
      },
      '& a': {
        fontWeight: styling.fonts.barlow.weights.semiBold,
        color: styling.colors.context.link,
        textDecoration: 'underline',
      },
    },
  },
  { name: 'footer' },
);
