import styling from '../../../../services/styling';

export const CIRCLE_RADIUS = 95;
export const CIRCLE_THICKNESS = 5;
export const CIRCLE_PADDING = 30;
export const SECONDARY_VALUE_OFFSET = 1.5;
export const TYPE_COLOR_MAPPING = {
  score: styling.colors.theme.primaryLight,
  baseline: styling.colors.theme.secondary,
  increase: styling.colors.theme.secondary,
};
