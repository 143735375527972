import PropTypes from 'prop-types';

import styling from '../../../services/styling';
import IconSvg from '../../IconSvg';

const Pause = ({ color, height, width, ...rest }) => (
  <IconSvg
    {...rest}
    color={color}
    height={height}
    viewBox="0 0 32 32"
    width={width}
  >
    <g fill="none" stroke="currentColor">
      <rect height="22" width="7" x="7" y="5" />
      <rect height="22" width="7" x="18" y="5" />
    </g>
  </IconSvg>
);

Pause.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};

Pause.defaultProps = {
  color: styling.colors.theme.secondaryDark,
  height: 32,
  width: 32,
};

export default Pause;
