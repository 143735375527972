import { useCallback, useState } from 'react';

import {
  getClosePanel,
  getDeregisterPanels,
  getOpenPanel,
  getRegisterPanel,
} from './logic';

const usePanelManagement = () => {
  const [openedPanel, setOpenedPanel] = useState();
  const [panels, setPanels] = useState({});

  const openPanel = useCallback(getOpenPanel(setOpenedPanel), [setOpenedPanel]);

  const closePanel = useCallback(getClosePanel(setOpenedPanel), [
    setOpenedPanel,
  ]);

  const registerPanel = useCallback(getRegisterPanel(setPanels), [
    panels,
    setPanels,
  ]);

  const deregisterPanel = useCallback(getDeregisterPanels(setPanels), [
    panels,
    setPanels,
  ]);

  return {
    registerPanel,
    deregisterPanel,
    openPanel,
    closePanel,
    openedPanel,
    panels,
  };
};

export default usePanelManagement;
