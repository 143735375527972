export const defaultCaptchaConfig = {
  onloadCallback: undefined,
  onloadCallbackName: 'onloadCallback',
  verifyCallback: undefined,
  verifyCallbackName: 'verifyCallback',
  expiredCallback: undefined,
  // theme: 'light',
  type: 'image',
  size: 'invisible',
  tabindex: '0',
  hl: 'en',
  badge: 'bottomright',
};
