import { useCallback, useContext, useState } from 'react';

import { ToastContext } from '../../../../../contexts/toastContext';

const useMetaHeader = () => {
  const { registerToast } = useContext(ToastContext);
  const [openSendPosters, setOpenSendPosters] = useState(false);

  const onOpenSendPosters = useCallback(() => {
    setOpenSendPosters(true);
  }, []);

  const onCloseSendPosters = useCallback(() => {
    setOpenSendPosters(false);
  }, []);

  const onCopyLink = useCallback(async () => {
    try {
      await navigator.clipboard.writeText(window.location.origin);
      registerToast('copy-link', {
        variant: 'info',
        duration: 2000,
        title: 'Link copied to clipboard',
      });
    } catch (e) {
      registerToast('copy-link', {
        variant: 'error',
        title: 'Permission denied',
        body: 'We do not have permission to write to your clipboard',
      });
    }
  }, [registerToast]);

  return {
    onCopyLink,
    openSendPosters,
    onOpenSendPosters,
    onCloseSendPosters,
  };
};

export default useMetaHeader;
