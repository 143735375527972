import { createContext, useMemo } from 'react';
import PropTypes from 'prop-types';

import useAnalyticsManagement from '../hooks/useAnalyticsManagement';

export const AnalyticsContext = createContext({
  trackEvent: () => {},
});

export const AnalyticsProvider = ({ children }) => {
  const value = useAnalyticsManagement();

  return (
    <AnalyticsContext.Provider value={value}>
      {children}
    </AnalyticsContext.Provider>
  );
};

AnalyticsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
