import queryString from 'query-string';

export const getInviteGuid = (searchString) => {
  const { invite } = queryString.parse(searchString);
  return invite;
};

export const handleOnBadInviteDetails = () => {
  window.location.replace('https://theconspiracytest.org/?bad-invite=1');
};

export const handleCheckForBadInviteParams = (
  searchString,
  registerAlert,
  closeAlert,
) => {
  const { 'bad-invite': badInvite } = queryString.parse(searchString);
  if (badInvite === '1') {
    registerAlert('bad-invite', {
      title: 'The Conspiracy Test is in private beta',
      body: 'If you&rsquo;d like to apply for beta testing, please use the form at the bottom of this page.',
    });
  }
};

export const handleOnGoodInviteDetails = ({ name }, registerAlert) => {
  registerAlert('invite', {
    title: 'Welcome',
    body: `Greetings ${name}, thanks for helping us with beta testing. You&rsquo;re welcome to take your own notes as you go through the website, or you can open this <a href="https://forms.gle/TbPSRjVUysajeadP6" target="_blank">Google Form</a> in a new tab to fill out as you go.`,
  });
};
