import { createUseStyles } from 'react-jss';

import styling from '../../services/styling';

export default createUseStyles(
  {
    wrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      width: '100%',
      flexGrow: 1,
    },
    hasBackground: {
      background: styling.colors.theme.pureBlackFaded,
    },
  },
  { name: 'loadingOverlay' },
);
