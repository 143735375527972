import PropTypes from 'prop-types';
import clsx from 'clsx';

import useForm from './hooks/useForm';
import Captcha from './Captcha';
import Field from './Field';
import useStyles from './form.styles';
import FormGrid from './FormGrid';
import FormSubmitButton from './FormSubmitButton';

const Form = ({
  autoComplete,
  className,
  onSubmit,
  data,
  layout,
  initialValues,
}) => {
  const styles = useStyles();
  const {
    formState,
    setFormState,
    fields,
    getFormData,
    onFieldChange,
    getFieldValue,
    getFieldErrors,
    getWidths,
    SubmitButton,
    registerExecuteCaptcha,
    handleOnSubmit,
    onCaptchaVerified,
    clearFormData,
    formResetRef,
  } = useForm(data, layout, initialValues, onSubmit);

  return (
    <form
      autoComplete={autoComplete}
      className={clsx(className, styles.wrapper)}
    >
      <FormGrid>
        {fields.map(({ key, ...rest }) => (
          <FormGrid key={key} item widths={getWidths(key)}>
            <Field
              key={formResetRef}
              errors={getFieldErrors(key)}
              formState={formState}
              onChange={onFieldChange(key)}
              value={getFieldValue(key)}
              name={key}
              {...rest}
            />
          </FormGrid>
        ))}
      </FormGrid>
      {data?.form?.hasCaptcha && (
        <Captcha
          registerExecuteCaptcha={registerExecuteCaptcha}
          onVerify={onCaptchaVerified}
        />
      )}
      <SubmitButton
        clearFormData={clearFormData}
        formState={formState}
        getFormData={getFormData}
        onSubmit={handleOnSubmit}
        setFormState={setFormState}
      />
    </form>
  );
};

Form.propTypes = {
  autoComplete: PropTypes.string,
  className: PropTypes.string,
  data: PropTypes.shape({
    fields: PropTypes.object,
    form: PropTypes.object,
  }).isRequired,
  layout: PropTypes.shape({
    fields: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
};

Form.defaultProps = {
  autoComplete: 'off',
  className: undefined,
  initialValues: undefined,
};

export default Form;
