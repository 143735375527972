import { createUseStyles } from 'react-jss';

import styling from '../../../../services/styling';

export default createUseStyles(
  {
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: 46,
      [styling.breakpoints.up('md')]: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginBottom: 21,
      },
    },
    spacingPlus: {
      position: 'relative',
      margin: [13, 0, 24],
      height: 27,
      width: 27,
      [styling.breakpoints.up('md')]: {
        margin: [0, 29, 0, 37],
      },
      '&:before': {
        content: '""',
        height: 1,
        width: '100%',
        position: 'absolute',
        left: 0,
        top: '50%',
        marginTop: -1,
        backgroundColor: styling.colors.theme.secondaryDark,
      },
      '&:after': {
        content: '""',
        width: 1,
        height: '100%',
        position: 'absolute',
        top: 0,
        marginLeft: -1,
        left: '50%',
        backgroundColor: styling.colors.theme.secondaryDark,
      },
    },
    link: {
      '& > svg': {
        transition: styling.speeds.jog,
      },
      '&:hover > svg': {
        color: styling.colors.theme.pureWhite,
      },
    },
  },
  { name: 'logoStacks' },
);
