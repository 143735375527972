import PropTypes from 'prop-types';
import clsx from 'clsx';

import styling from '../../../services/styling';
import { CaretDownIcon } from '../../Icons';
import Markup from '../../Markup';
import InputWrapper from '../InputWrapper';
import useInputTypeahead from './hooks/useInputTypeahead';
import useStyles from './inputTypeahead.styles';

const InputTypeahead = ({
  className,
  disabled,
  errors,
  hasSolidBackground,
  isFocused,
  onBlur,
  onChange,
  onFocus,
  options,
  size,
  value,
  variant,
  meta,
  name,
}) => {
  const styles = useStyles();
  const {
    dropdownListRef,
    isOpen,
    getToggleButtonProps,
    getFilterInputProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
    selectedItem,
    filteredOptions,
    getInputProps,
  } = useInputTypeahead({ options, onChange, value, meta, isFocused, onFocus });

  return (
    <InputWrapper
      className={className}
      errors={errors}
      hasSolidBackground={hasSolidBackground}
      isDisabled={disabled}
      isFocused={isFocused}
      size={size}
      variant={variant}
    >
      <div
        className={clsx(
          styles.toggleButton,
          size === 'lg' && styles.toggleButtonLg,
        )}
        {...getToggleButtonProps({ disabled })}
      >
        <Markup content={selectedItem?.title || ''} />
        <CaretDownIcon
          className={clsx(styles.caret, isOpen && styles.caretRotated)}
          color={styling.colors.theme.primaryLight}
        />
      </div>
      <div
        className={clsx(
          styles.dropdownWrapper,
          isOpen && styles.dropdownWrapperOpen,
          size === 'lg' && styles.dropdownWrapperLg,
          variant && styles[`dropdownWrapperVariant${variant}`],
        )}
      >
        <div className={clsx(styles.dropdown, isOpen && styles.dropdownOpen)}>
          <div className={styles.filterInputWrapper}>
            <input
              className={styles.filterInput}
              {...getFilterInputProps()}
              {...getInputProps({
                disabled,
                onBlur,
                onFocus,
                name,
              })}
            />
          </div>
          <ul
            {...getMenuProps({ ref: dropdownListRef })}
            className={styles.list}
          >
            {filteredOptions.map((option, index) => (
              <li
                key={option.value}
                className={clsx(
                  styles.item,
                  highlightedIndex === index && styles.itemHighlighted,
                  selectedItem?.value === option.value && styles.itemSelected,
                )}
                {...getItemProps({ item: option, index })}
              >
                <Markup content={option.title} />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </InputWrapper>
  );
};

InputTypeahead.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.string),
  hasSolidBackground: PropTypes.bool,
  isFocused: PropTypes.bool,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  variant: PropTypes.oneOf(['colored']),
  size: PropTypes.oneOf(['md', 'lg']),
  meta: PropTypes.shape({
    filterPlaceholder: PropTypes.string,
  }),
  name: PropTypes.string,
};

InputTypeahead.defaultProps = {
  className: undefined,
  disabled: false,
  errors: undefined,
  hasSolidBackground: false,
  isFocused: false,
  variant: undefined,
  size: 'md',
  value: undefined,
  meta: undefined,
  name: undefined,
};

export default InputTypeahead;
