import { useCallback, useMemo, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import {
  generateMainSize,
  generateMainStroke,
  generateSecondarySize,
  generateSecondaryStroke,
  getCircumference,
} from '../logic';

const useStatWheel = (type, value, secondaryValue = 0) => {
  const { ref, inView } = useInView({
    fallbackInView: true,
    threshold: 0.3,
    triggerOnce: true,
  });
  const circum = useRef(getCircumference());

  const mainStrokeProps = useMemo(
    () => generateMainStroke(type, circum.current, value),
    [type, value],
  );
  const mainStrokeSize = useMemo(
    () => generateMainSize(type, circum.current, value),
    [type, value],
  );
  const secondaryStrokeProps = useMemo(
    () => generateSecondaryStroke(circum.current, value),
    [value],
  );
  const secondaryStrokeSize = useMemo(
    () => generateSecondarySize(circum.current, secondaryValue),
    [secondaryValue],
  );

  return {
    mainStrokeProps,
    mainStrokeSize,
    secondaryStrokeProps,
    secondaryStrokeSize,
    inView,
    ref,
  };
};

export default useStatWheel;
