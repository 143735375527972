import { useCallback } from 'react';

const useInputTextarea = (onChange) => {
  const handleOnChange = useCallback((e) => onChange(e?.target?.value));

  return {
    handleOnChange,
  };
};

export default useInputTextarea;
