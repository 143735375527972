import PropTypes from 'prop-types';
import clsx from 'clsx';

import useStyles from './backdrop.styles';

const Backdrop = ({ variant, onClick }) => {
  const styles = useStyles();

  return (
    <div
      className={clsx(
        styles.backdrop,
        styles[`variant-${variant}`],
        !!onClick && styles.backdropOnClick,
      )}
      onClick={onClick}
    />
  );
};

Backdrop.propTypes = {
  onClick: PropTypes.func,
  variant: PropTypes.oneOf(['panel', 'alert']),
};

Backdrop.defaultProps = {
  variant: 'panel',
  onClick: undefined,
};

export default Backdrop;
