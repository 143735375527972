import PropTypes from 'prop-types';

import styling from '../../services/styling';
import Fade from '../Fade';
import { TimesIcon } from '../Icons';
import Markup from '../Markup';
import TileBackground from '../MissionSelectSection/Carousel/MissionTile/TileBackground';
import Typography from '../Typography';
import useAlert from './hooks/useAlert';
import useStyles from './alert.styles';
import AlertButtons from './AlertButtons';

const Alert = ({ id, isIn, title, body, isRequired, buttons, hasTopAlert }) => {
  const { onClose, onExited } = useAlert(id, isIn, hasTopAlert);
  const styles = useStyles();

  return (
    <Fade
      className={styles.wrapper}
      durationIn={500}
      durationOut={500}
      in={isIn}
      onExited={onExited}
    >
      <div className={styles.alert}>
        <TileBackground corner={70} />
        {!isRequired && (
          <button
            className={styles.closeButton}
            onClick={onClose}
            type="button"
          >
            <TimesIcon
              color={styling.colors.theme.pureWhite}
              height={21}
              width={21}
            />
          </button>
        )}
        <div className={styles.content}>
          <Typography className={styles.title} variant="alertTitle" withShadow>
            {title}
          </Typography>
          {body && (
            <Typography variant="alertBody">
              <Markup content={body} />
            </Typography>
          )}
          <AlertButtons buttons={buttons} onClose={onClose} />
        </div>
      </div>
    </Fade>
  );
};

Alert.propTypes = {
  id: PropTypes.string.isRequired,
  isIn: PropTypes.bool,
  title: PropTypes.node.isRequired,
  body: PropTypes.node,
  isRequired: PropTypes.bool,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    }).isRequired,
  ),
  hasTopAlert: PropTypes.bool.isRequired,
};

Alert.defaultProps = {
  isIn: false,
  body: undefined,
  isRequired: false,
  buttons: [],
};

export default Alert;
