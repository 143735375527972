import PropTypes from 'prop-types';
import clsx from 'clsx';

import useStyles from './panel.styles';

const Panel = ({ children, isOpen, docked }) => {
  const styles = useStyles();

  return (
    <div
      className={clsx(
        styles.panel,
        styles.docked,
        styles[`docked-${docked}`],
        isOpen && styles.isOpen,
      )}
    >
      {children}
    </div>
  );
};

Panel.propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  docked: PropTypes.oneOf(['left', 'right']),
};

Panel.defaultProps = {
  docked: 'right',
};

export default Panel;
