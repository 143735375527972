import { useMemo } from 'react';
import { useSelect } from 'downshift';

import { getItemToString, getSanitizedItem, handleOnChange } from '../logic';

const useInputSelect = (options, onChange, value) => {
  const selectedItem = useMemo(
    () => getSanitizedItem(options, value),
    [options, value],
  );

  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect({
    items: options,
    itemToString: getItemToString,
    selectedItem: selectedItem || null,
    onSelectedItemChange: handleOnChange(onChange),
  });

  return {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
    selectedItem,
  };
};

export default useInputSelect;
