import { at, mergeWith } from 'lodash';

import { replaceAtIndex } from '../../services/helpers/pureArray';

export const handleBeginAttempt = (
  attemptData,
  setAttemptData,
  startTime,
  missionSlug,
) => {
  // if starting a different mission from any restoring data, clear previous data
  if (missionSlug !== attemptData.missionSlug) {
    setAttemptData({
      missionSlug,
      startTime,
    });
    return;
  }
  setAttemptData({
    ...attemptData,
    missionSlug,
    startTime,
  });
};

export const handleEndAttempt = (attemptData, setAttemptData, endTime) => {
  const { startTime, ...rest } = attemptData;
  setAttemptData({
    ...rest,
    answerSpeedSecs: Math.floor((endTime - startTime) / 1000),
  });
};

const handleBaselineAndIncrease = (
  attemptData,
  existingAnswerIndex,
  key,
  value,
) => {
  if (key !== 'skepticism') return {};
  if (
    !attemptData.answers ||
    attemptData.answers.length === 0 ||
    existingAnswerIndex === 0
  ) {
    return {
      skepticismBaseline: value,
    };
  }
  return {
    skepticismIncrease: value - attemptData.skepticismBaseline,
  };
};

export const handleSetAttemptAnswer = (
  setAttemptData,
  attemptData,
  questionSlug,
  key,
  value,
) => {
  const currentAnswers = attemptData.answers || [];
  const existingAnswerIndex = currentAnswers.findIndex(
    (answer) => answer.questionSlug === questionSlug,
  );

  if (existingAnswerIndex >= 0) {
    setAttemptData({
      ...attemptData,
      ...handleBaselineAndIncrease(
        attemptData,
        existingAnswerIndex,
        key,
        value,
      ),
      answers: [
        ...replaceAtIndex(
          currentAnswers,
          {
            ...currentAnswers[existingAnswerIndex],
            [key]: value,
          },
          existingAnswerIndex,
        ),
      ],
    });
  } else {
    setAttemptData({
      ...attemptData,
      ...handleBaselineAndIncrease(
        attemptData,
        existingAnswerIndex,
        key,
        value,
      ),
      answers: [
        ...currentAnswers,
        {
          questionSlug,
          [key]: value,
        },
      ],
    });
  }
};

export const handleClearAttempt = (setAttemptData, defaultData) =>
  setAttemptData(defaultData);
