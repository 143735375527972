import clsx from 'clsx';

import useMainNav from './hooks/useMainNav';
import useStyles from './mainNav.styles';

const MainNav = () => {
  const styles = useStyles();
  const { toggleMainNav, isOpen, otherPanelOpen } = useMainNav();

  return (
    <button
      type="button"
      className={clsx(
        styles.hamburger,
        isOpen && styles.hamburgerOpen,
        otherPanelOpen && styles.otherPanelOpen,
      )}
      onClick={toggleMainNav}
    >
      <span />
      <span />
      <span />
      <span />
    </button>
  );
};

export default MainNav;
