import { memo, Suspense } from 'react';
import PropTypes from 'prop-types';
import { Canvas } from '@react-three/fiber';

import Outerglow from './Outerglow';
import SceneWithModel from './SceneWithModel';

const Model = ({ url }) => (
  <Suspense fallback={<div>Loading...</div>}>
    <Canvas camera={{ position: [0, 0, 3.5] }}>
      <Outerglow>
        <SceneWithModel model={url} />
      </Outerglow>
    </Canvas>
  </Suspense>
);

Model.propTypes = {
  url: PropTypes.string.isRequired,
};

export default memo(Model);
