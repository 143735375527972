import { createUseStyles } from 'react-jss';

import styling from '../../../services/styling';

export default createUseStyles(
  {
    hamburger: {
      position: 'absolute',
      top: 0,
      right: styling.sizes.gutteringSm,
      zIndex: styling.zIndex.panelToggle,
      marginTop: '6.4%',
      background: 'transparent',
      border: 'none',
      padding: 0,
      width: 27,
      height: 36,
      transform: 'rotate(0deg)',
      transition: '.5s ease-in-out',
      cursor: 'pointer',
      [styling.breakpoints.up('sm')]: {
        marginTop: styling.sizes.gutteringLg - 10,
        right: styling.sizes.gutteringLg,
      },
      [styling.breakpoints.up('xl')]: {
        position: 'fixed',
      },
      '& span': {
        display: 'block',
        position: 'absolute',
        height: 1,
        width: '100%',
        background: styling.colors.theme.pureWhite,
        opacity: 1,
        left: 0,
        transform: 'rotate(0deg)',
        transition: '.25s ease-in-out',
      },
      '& span:nth-child(1)': {
        top: 11,
      },
      '& span:nth-child(2), & span:nth-child(3)': {
        top: 21,
      },
      '& span:nth-child(4)': {
        top: 32,
      },
      '&$hamburgerOpen span:nth-child(1)': {
        top: 24,
        width: '0%',
        left: '50%',
      },
      '&$hamburgerOpen span:nth-child(2)': {
        transform: 'rotate(45deg)',
      },
      '&$hamburgerOpen span:nth-child(3)': {
        transform: 'rotate(-45deg)',
      },
      '&$hamburgerOpen span:nth-child(4)': {
        top: 24,
        width: '0%',
        left: '50%',
      },
    },
    hamburgerOpen: {},
    otherPanelOpen: {
      zIndex: 4,
    },
  },
  { name: 'mainNav' },
);
