import { useContext } from 'react';

import { ToastContext } from '../../../contexts/toastContext';

const useToastRoot = () => {
  const { closeToast, topToast, toasts } = useContext(ToastContext);

  return {
    toasts,
    topToast,
    closeToast,
  };
};

export default useToastRoot;
