/* eslint-disable react/no-unknown-property */
import PropTypes from 'prop-types';

import CustomModel from '../CustomModel';

function SceneWithModel({ model }) {
  // explore https://codesandbox.io/s/7mfqw?file=/src/index.js:1945-1960
  // https://github.com/pmndrs/react-postprocessing
  // http://stemkoski.github.io/Three.js/Shader-Halo.html
  // http://stemkoski.github.io/Three.js/Shader-Glow.html
  return (
    <>
      <pointLight args={[0xffffff, 10, 100]} position={[3, 2, 0]} />
      <pointLight args={[0xffffff, 1, 3]} position={[0, 2, 0]} />
      <CustomModel model={model} />
    </>
  );
}

SceneWithModel.propTypes = {
  model: PropTypes.string.isRequired,
};

export default SceneWithModel;
